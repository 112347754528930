const transformToSearchInputObject = (
  object,
  labelProperty,
  valueProperty,
  additionalLabel1Property
) => {
  if (!object) {
    return null;
  }
  return {
    id: object[valueProperty] !== undefined ? object[valueProperty] : null,
    title: [
      object[labelProperty] !== undefined ? object[labelProperty] : null,
      object[additionalLabel1Property] !== undefined
        ? object[additionalLabel1Property]
        : null,
    ]
      .filter(Boolean)
      .join(' - '),
  };
};

export default transformToSearchInputObject;
