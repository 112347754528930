import React from 'react';
import {
  Checkbox,
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CrawlKeywordFormBlock from '../components/CrawlKeywordFormBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import LoadingBlock from '../components/LoadingBlock';
import OverlayBlock from '../components/OverlayBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const CrawlerKeywordListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [crawlerKeyword, setCrawlerKeyword] = React.useState(false);
  const [crawlerKeywordList, setCrawlerKeywordList] = React.useState([]);
  const [editingItem, setEditingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupUpdateCrawlerKeywordPATCH =
    AdminGroupApi.useUpdateCrawlerKeywordPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNextPage(1);
      setCrawlerKeywordList([]);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        transparent={false}
        animationType={'fade'}
        transparent={StyleSheet.getWidthValue(
          [{ minWidth: Breakpoints.Laptop, value: true }],
          dimensions.width
        )}
        visible={editingItem !== null}
      >
        <OverlayBlock />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            },
            dimensions.width
          )}
        >
          {/* Card */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.White,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 12 },
                padding: { minWidth: Breakpoints.Laptop, value: 20 },
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* ✅ Crawl Keyword Form */}
            <CrawlKeywordFormBlock
              onCancel={() => {
                try {
                  setEditingItem(null);
                } catch (err) {
                  console.error(err);
                }
              }}
              onSubmit={(string, language, search_pdfs) => {
                const handler = async () => {
                  try {
                    setIsSubmitting(true);
                    const resultUpdated = (
                      await adminGroupUpdateCrawlerKeywordPATCH.mutateAsync({
                        crawler_keyword_id: editingItem?.id,
                        language: language,
                        search_pdfs: search_pdfs,
                        string_: string,
                      })
                    )?.json;
                    setIsSubmitting(false);
                    if (resultUpdated?.code) {
                      toastMessage(resultUpdated?.message, 'error');
                    } else {
                      toastMessage(
                        'Update crawler keyword successfully',
                        'success'
                      );
                      setEditingItem(null);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              initialData={editingItem}
              isSubmitting={isSubmitting}
            />
          </View>
        </View>
      </Modal>

      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('CFSNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          showAddButton={false}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.4 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'EDIT'}
                  </Text>
                </View>
                {/* Created */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.5, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created'}
                      </Text>
                    </View>
                  )}
                </>
                {/* String */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'String'}
                  </Text>
                </View>
                {/* Language */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.5, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Language'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Search PDFs */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Search PDFs'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetCrawlerKeywordGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== crawlerKeywordList) {
                          setCrawlerKeywordList(fetchData?.json?.items);
                        } else {
                        }

                        console.log(crawlerKeywordList);
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword_search={keywordSearch}
                  page={1}
                >
                  {({ loading, error, data, refetchGetCrawlerKeyword }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <SimpleStyleFlatList
                          data={crawlerKeywordList}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'2Nn3NfIV'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(
                                  'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:2 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getCrawlerKeywordGET(
                                    Constants,
                                    {
                                      keyword_search: keywordSearch,
                                      page: nextPage,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:3 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setLastPage(newData?.pageTotal);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                                );
                                if (
                                  fetchData?.items ===
                                  (0 || crawlerKeywordList !== fetchData?.items)
                                ) {
                                  return console.log(
                                    'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setCrawlerKeywordList(
                                  crawlerKeywordList.concat(newData?.items)
                                );
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    width: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 1200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Actions */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.4,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      flexDirection: 'row',
                                      height: '100%',
                                      justifyContent: 'center',
                                      maxWidth: 150,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <TableActionsBlock
                                    onPressEdit={() => {
                                      try {
                                        setEditingItem(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    border={false}
                                    showDelete={false}
                                    showEdit={true}
                                  />
                                </View>
                                {/* Created */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.created_at
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* String */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.5,
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.string}
                                  </Text>
                                </View>
                                {/* Language */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.language}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* Search PDFs */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          marginBottom: 1,
                                          marginRight: 1,
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Checkbox
                                        color={StyleSheet.getWidthValue(
                                          [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: theme.colors.text.strong,
                                            },
                                          ],
                                          dimensions.width
                                        )}
                                        defaultValue={listData?.search_pdfs}
                                        disabled={true}
                                        size={StyleSheet.getWidthValue(
                                          [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 22,
                                            },
                                          ],
                                          dimensions.width
                                        )}
                                        uncheckedColor={StyleSheet.getWidthValue(
                                          [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: theme.colors.text.strong,
                                            },
                                          ],
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  )}
                                </>
                              </View>
                            );
                          }}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height - 280,
                                },
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <>
                          {!(crawlerKeywordList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetCrawlerKeywordGET>
              </View>
            </View>
          </View>
        </View>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CrawlerKeywordListingScreen);
