import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CrawlerSourceFormBlock from '../components/CrawlerSourceFormBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id: null };

const CrawlerSourceEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupUpdateCrawlerSourcePATCH =
    AdminGroupApi.useUpdateCrawlerSourcePATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock showAddButton={false} />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <AdminGroupApi.FetchGetCrawlerSourceDetailGET
            crawler_source_id={props.route?.params?.id ?? defaultProps.id}
          >
            {({ loading, error, data, refetchGetCrawlerSourceDetail }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <CurrentEditingSectionBlock
                    editingLabel={fetchData?.source_name}
                  />
                  <SimpleStyleKeyboardAwareScrollView
                    enableAutomaticScroll={false}
                    enableOnAndroid={false}
                    enableResetScrollToCoords={false}
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={true}
                    viewIsInsideTabBar={false}
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Laptop, value: 1 },
                        paddingBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 200,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <CrawlerSourceFormBlock
                      onSubmit={(
                        in_production,
                        source_name,
                        base_url,
                        user_email,
                        user_password,
                        language,
                        crawl_attachments
                      ) => {
                        const handler = async () => {
                          try {
                            setIsSubmitting(true);
                            const resultUpdated = (
                              await adminGroupUpdateCrawlerSourcePATCH.mutateAsync(
                                {
                                  base_url: base_url,
                                  crawl_attachments: crawl_attachments,
                                  crawler_source_id:
                                    props.route?.params?.id ?? defaultProps.id,
                                  in_production: in_production,
                                  language: language,
                                  source_name: source_name,
                                  user_email: user_email,
                                  user_password: user_password,
                                }
                              )
                            )?.json;
                            setIsSubmitting(false);
                            if (resultUpdated?.code) {
                              toastMessage(resultUpdated?.message, 'error');
                            } else {
                              toastMessage(
                                'Update crawler source successfully',
                                'success'
                              );
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      initialData={fetchData}
                      isSubmitting={isSubmitting}
                    />
                  </SimpleStyleKeyboardAwareScrollView>
                </>
              );
            }}
          </AdminGroupApi.FetchGetCrawlerSourceDetailGET>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CrawlerSourceEditScreen);
