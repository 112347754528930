import React from 'react';
import {
  DatePicker,
  IconButton,
  Picker,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import MonthYearPickerBlock from '../components/MonthYearPickerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: null,
  isSubmitting: false,
  onSubmit: () => {},
};

const PEPFFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Company, setCompany] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.Company
  );
  const [acquired_date, setAcquired_date] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.acquired_date
  );
  const [buyside_cf_entry, setBuyside_cf_entry] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._buyside_cf_entry,
      'name',
      'id',
      undefined
    )
  );
  const [buyside_legal_entry, setBuyside_legal_entry] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._buyside_legal_entry,
      'name',
      'id',
      undefined
    )
  );
  const [company_description, setCompany_description] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.company_description
  );
  const [company_id, setCompany_id] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.company_id
  );
  const [country, setCountry] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.country
  );
  const [currency_financials, setCurrency_financials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.currency_financials
  );
  const [ebit_local, setEbit_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebit_local
  );
  const [ebitda, setEbitda] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebitda
  );
  const [ebitda_local, setEbitda_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.ebitda_local
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [exit_date, setExit_date] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.exit_date
  );
  const [financial_year, setFinancial_year] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.financial_year
  );
  const [financials_comment, setFinancials_comment] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.financials_comment
  );
  const [fund_entity, setFund_entity] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._fund_entity,
      'name',
      'id',
      undefined
    )
  );
  const [gics, setGics] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)
        ?._gics_sub_industry_single,
      'GICS_Sector',
      'id',
      'GICS_Sub_Industry'
    )
  );
  const [gross_profit_local, setGross_profit_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.gross_profit_local
  );
  const [growth, setGrowth] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.growth
  );
  const [investment_responsible, setInvestment_responsible] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.investment_responsible
  );
  const [pe_firm, setPe_firm] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._pe_firm,
      'name',
      'id',
      undefined
    )
  );
  const [revenue_local, setRevenue_local] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.revenue_local
  );
  const [stake_type, setStake_type] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.stake_type
  );
  const [suggestionBuySideCFAdvisors, setSuggestionBuySideCFAdvisors] =
    React.useState([]);
  const [suggestionBuySideLegalAdvisors, setSuggestionBuySideLegalAdvisors] =
    React.useState([]);
  const [suggestionFunds, setSuggestionFunds] = React.useState([]);
  const [suggestionGics, setSuggestionGics] = React.useState([]);
  const [suggestionInvestors, setSuggestionInvestors] = React.useState([]);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [transacted, setTransacted] = React.useState('');
  const [website, setWebsite] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.website
  );
  const validateForm = () => {
    setErrorMessage('');
    const requiredFields = {
      acquired_date: 'Acquired Date',
      pe_firm: 'PE Firm',
      Company: 'Company',
      fund_entity: 'Fund Entity',
      country: 'Country',
      company_description: 'Company Description',
      gics: 'GICS Sub Industry',
      stake_type: 'Stake Type',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flex: 0.3,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App.Outline,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 1 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' } },
              dimensions.width
            )}
          >
            {/* Column 1 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Acquired date* */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Acquired date *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <DatePicker
                    autoDismissKeyboard={true}
                    disabled={false}
                    hideLabel={false}
                    inline={false}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newDatePickerValue => {
                      try {
                        setAcquired_date(newDatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    {...GlobalStyles.DatePickerStyles(theme)[
                      'defaultDatePicker'
                    ].props}
                    date={customFormatDate(acquired_date)}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                        .style,
                      dimensions.width
                    )}
                  />
                </View>
              </View>
              {/* Company */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Company *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setCompany(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={Company}
                  />
                </View>
              </View>
              {/* Organisation * */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Organisation # (CVR) '}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setCompany_id(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={company_id}
                  />
                </View>
              </View>
            </View>
            {/* Column 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* PE firm* */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'PE firm *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultInvestors = (
                            await AdminGroupApi.getInvestorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const investorsOptions = transformToSearchInputFormat(
                            resultInvestors?.items,
                            'name',
                            'id',
                            undefined
                          );
                          setSuggestionInvestors(investorsOptions);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setPe_firm(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={pe_firm}
                    multiSelect={false}
                    selectedItem={pe_firm}
                    suggestionsList={suggestionInvestors}
                  />
                </View>
              </View>
              {/* Fund entity* */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Fund entity*'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultFunds = (
                            await AdminGroupApi.getFundGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_funds =
                            transformToSearchInputFormat(
                              resultFunds?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionFunds(new_suggestion_funds);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setFund_entity(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={fund_entity}
                    multiSelect={false}
                    selectedItem={fund_entity}
                    suggestionsList={suggestionFunds}
                  />
                </View>
              </View>
              {/* Investment Responsible */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: flexOnlySmallDevice(dimensions, 0.3),
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Investment Responsible'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setInvestment_responsible(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={investment_responsible}
                  />
                </View>
              </View>
            </View>
            {/* Column 3 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Website */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Website '}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setWebsite(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a value...'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      dimensions.width
                    )}
                    value={website}
                  />
                </View>
              </View>
              {/* Buy-side CF advisor */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Buy-side CF advisor'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_advisors =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionBuySideCFAdvisors(
                            new_suggestion_advisors
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setBuyside_cf_entry(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={buyside_cf_entry}
                    multiSelect={false}
                    selectedItem={buyside_cf_entry}
                    suggestionsList={suggestionBuySideCFAdvisors}
                  />
                </View>
              </View>
              {/* Buy-side legal advisor */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Buy-side legal advisor'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultAdvisors = (
                            await AdminGroupApi.getAdvisorsGET(Constants, {
                              keyword: searchKeyword,
                              page: 1,
                              per_page: 25,
                            })
                          )?.json;
                          const new_suggestion_advisors =
                            transformToSearchInputFormat(
                              resultAdvisors?.items,
                              'name',
                              'id',
                              undefined
                            );
                          setSuggestionBuySideLegalAdvisors(
                            new_suggestion_advisors
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setBuyside_legal_entry(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={buyside_legal_entry}
                    multiSelect={false}
                    selectedItem={buyside_legal_entry}
                    suggestionsList={suggestionBuySideLegalAdvisors}
                  />
                </View>
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Country * */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Country *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setCountry(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    mode={'native'}
                    options={Constants['COUNTRY_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={country}
                  />
                </View>
              </View>
              {/* GICS Sub Ind.* */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'GICS Sub Industry *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <FuzzyInputWithDataBlock
                    getSuggestions={searchKeyword => {
                      const handler = async () => {
                        try {
                          const resultGics = (
                            await AdminGroupApi.getGicsSubIndustryGET(
                              Constants,
                              { keyword: searchKeyword }
                            )
                          )?.json;
                          const gicsOptions = transformToSearchInputFormat(
                            resultGics,
                            'GICS_Sector',
                            'id',
                            'GICS_Sub_Industry'
                          );
                          setSuggestionGics(gicsOptions);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onSelect={selectedResult => {
                      try {
                        setGics(selectedResult);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    defaultValue={gics}
                    multiSelect={false}
                    selectedItem={gics}
                    suggestionsList={suggestionGics}
                  />
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Company description */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Company description *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.7 },
                        { minWidth: Breakpoints.Laptop, value: 1 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCorrect={true}
                    changeTextDelay={500}
                    multiline={true}
                    onChangeText={newTextAreaValue => {
                      try {
                        setCompany_description(newTextAreaValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    textAlignVertical={'top'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .props}
                    numberOfLines={6}
                    placeholder={'Enter a value ...'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Common TextArea']
                          .style,
                        { flex: { minWidth: Breakpoints.Laptop, value: 1 } }
                      ),
                      dimensions.width
                    )}
                    value={company_description}
                  />
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Exit date */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Exit date'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <DatePicker
                    autoDismissKeyboard={true}
                    disabled={false}
                    hideLabel={false}
                    inline={false}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newDatePickerValue => {
                      try {
                        setExit_date(newDatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    {...GlobalStyles.DatePickerStyles(theme)[
                      'defaultDatePicker'
                    ].props}
                    date={customFormatDate(exit_date)}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                        .style,
                      dimensions.width
                    )}
                  />
                  <>
                    {!exit_date ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Laptop, value: 'center' },
                            ],
                            height: [
                              { minWidth: Breakpoints.Desktop, value: 60 },
                              { minWidth: Breakpoints.Laptop, value: 60 },
                            ],
                            justifyContent: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Laptop, value: 'center' },
                            ],
                            position: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'absolute',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'absolute',
                              },
                            ],
                            right: [
                              { minWidth: Breakpoints.Desktop, value: 10 },
                              { minWidth: Breakpoints.Laptop, value: 5 },
                            ],
                            width: [
                              { minWidth: Breakpoints.Desktop, value: 60 },
                              { minWidth: Breakpoints.Laptop, value: 60 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          onPress={() => {
                            try {
                              setExit_date(null);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                          color={StyleSheet.getWidthValue(
                            [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: theme.colors.text.strong,
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors.text.strong,
                              },
                            ],
                            dimensions.width
                          )}
                          icon={'Ionicons/close-circle'}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
              {/* Stake type * */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: rowOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Field */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderWidth: 1,
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.3 },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: flexOnlySmallDevice(dimensions, 0.3),
                        },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Stake type *'}
                  </Text>
                </View>
                {/* Value */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App.Outline,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: borderColorSmallDevice(dimensions),
                        },
                      ],
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 0.7,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setStake_type(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                    mode={'native'}
                    options={Constants['PEPF_STAKE_TYPE_OPTIONS']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['app picker'].style,
                      dimensions.width
                    )}
                    value={stake_type}
                  />
                </View>
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 3 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Revenue */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Revenue (m)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setRevenue_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={revenue_local}
                    />
                  </View>
                </View>
                {/* Gross Profit */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Gross Profit (m)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setGross_profit_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={gross_profit_local}
                    />
                  </View>
                </View>
              </View>
              {/* Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Growth* */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Growth\n'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setGrowth(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={growth}
                    />
                  </View>
                </View>
                {/* Currency* */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Currency'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.brand}
                      dropDownBorderColor={theme.colors.border.brand}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      onValueChange={newPickerValue => {
                        try {
                          setCurrency_financials(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                      mode={'native'}
                      options={Constants['CURRENCY_OPTIONS']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['app picker'].style,
                        dimensions.width
                      )}
                      value={currency_financials}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Column */}
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            >
              {/* Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* EBITDA* */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'EBITDA (m)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setEbitda_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={ebitda_local}
                    />
                  </View>
                </View>
                {/* EBIT* */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'EBIT (m) '}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTextInputValue => {
                        try {
                          setEbit_local(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'Common TextInput'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Common TextInput']
                          .style,
                        dimensions.width
                      )}
                      value={ebit_local}
                    />
                  </View>
                </View>
              </View>
              {/* Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: columnOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* End of fiscal year* */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'End of fiscal year '}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Outline,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: borderColorSmallDevice(dimensions),
                          },
                        ],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <MonthYearPickerBlock
                      onChange={newMonthYearValue => {
                        try {
                          setFinancial_year(newMonthYearValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      value={financial_year}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Row 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Note for financials */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Note for financials'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setFinancials_comment(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={financials_comment}
                />
              </View>
            </View>
            <View
              style={StyleSheet.applyWidth(
                { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                dimensions.width
              )}
            />
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                acquired_date,
                Company,
                company_id,
                country,
                gics,
                pe_firm,
                fund_entity,
                investment_responsible,
                company_description,
                website,
                buyside_cf_entry,
                buyside_legal_entry,
                exit_date,
                revenue_local,
                gross_profit_local,
                ebitda_local,
                ebit_local,
                growth,
                currency_financials,
                financial_year,
                financials_comment,
                stake_type
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(PEPFFormBlock);
