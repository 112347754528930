import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as FuzzySearchInput from '../custom-files/FuzzySearchInput';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  getSuggestions: () => {},
  multiSelect: true,
  onSelect: () => {},
  selectedItem: null,
  selectedItems: [],
  suggestionsList: [],
};

const FuzzyInputWithDataBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  React.useEffect(() => {
    try {
      props.getSuggestions?.(undefined);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <View>
      <Utils.CustomCodeErrorBoundary>
        <FuzzySearchInput.Index
          {...{
            options: props?.suggestionsList ?? [],
            onSelect: props?.onSelect,
            getSuggestions: props?.getSuggestions,
            multiSelect: props?.multiSelect ?? true,
            selectedItem: props?.selectedItem ?? null,
            selectedItems: props?.selectedItems ?? [],
          }}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(FuzzyInputWithDataBlock);
