import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as RangeDatePicker from '../custom-files/RangeDatePicker';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  endDate: null,
  maxWidth: 300,
  minWidth: 300,
  onChange: () => {},
  options: null,
  placeholder: null,
  startDate: null,
};

const CustomRangeDatePickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View>
      <Utils.CustomCodeErrorBoundary>
        <RangeDatePicker.Index
          options={props?.options ?? []}
          onChange={props?.onChange}
          placeholder={props?.placeholder}
          minWidth={props?.minWidth}
          maxWidth={props?.maxWidth}
          startDate={props?.startDate}
          endDate={props?.endDate}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(CustomRangeDatePickerBlock);
