import React from 'react';
import { withTheme } from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const EmptyListStateBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 1200,
            paddingBottom: 35,
            paddingTop: 35,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Table Row'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Table Row'].style,
              {
                fontFamily: {
                  minWidth: Breakpoints.Laptop,
                  value: 'Quicksand_600SemiBold',
                },
                fontSize: { minWidth: Breakpoints.Laptop, value: 19 },
              }
            ),
            dimensions.width
          )}
        >
          {'No data'}
        </Text>
      </View>
    </View>
  );
};

export default withTheme(EmptyListStateBlock);
