import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import NewsletterFormBlock from '../components/NewsletterFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getIds from '../global-functions/getIds';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const NewsletterCreateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [dach, setDach] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [newsletter, setNewsletter] = React.useState(null);
  const [newslettersList, setNewslettersList] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(0);
  const [nordic, setNordic] = React.useState(false);
  const [screenCode, setScreenCode] = React.useState('');
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const adminGroupCreateNewsletterPOST =
    AdminGroupApi.useCreateNewsletterPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <CurrentEditingSectionBlock />
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              { paddingBottom: { minWidth: Breakpoints.Laptop, value: 200 } },
              dimensions.width
            )}
          >
            <NewsletterFormBlock
              onSubmit={(
                version,
                title,
                date,
                teaser,
                status,
                potd,
                events_dk_ids,
                events_se_ids,
                events_no_ids,
                events_fi_ids,
                events_dach_large_cap_ids,
                events_dach_mid_ids,
                events_dach_small_ids
              ) => {
                const handler = async () => {
                  try {
                    setIsSubmitting(true);
                    const resultCreateNewsletter = (
                      await adminGroupCreateNewsletterPOST.mutateAsync({
                        date: date,
                        events_dach_large_cap_ids: getIds(
                          events_dach_large_cap_ids
                        ),
                        events_dach_mid_ids: getIds(events_dach_mid_ids),
                        events_dach_small_ids: getIds(events_dach_small_ids),
                        events_dk_ids: getIds(events_dk_ids),
                        events_fi_ids: getIds(events_fi_ids),
                        events_no_ids: getIds(events_no_ids),
                        events_se_ids: getIds(events_se_ids),
                        potd: potd?.id,
                        status: status,
                        teaser: teaser,
                        title: title,
                        version: version,
                      })
                    )?.json;
                    setIsSubmitting(false);
                    if (resultCreateNewsletter?.error) {
                      toastMessage(resultCreateNewsletter?.message, 'error');
                    } else {
                      toastMessage('Create newsletter successfully', 'success');
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              initialData={{}}
              isSubmitting={isSubmitting}
            />
          </SimpleStyleKeyboardAwareScrollView>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(NewsletterCreateScreen);
