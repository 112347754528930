import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CrawlerSourceFormBlock from '../components/CrawlerSourceFormBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const CrawlerSourceCreateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupCreateCrawlerSourcePOST =
    AdminGroupApi.useCreateCrawlerSourcePOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <CurrentEditingSectionBlock />
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
              dimensions.width
            )}
          >
            <CrawlerSourceFormBlock
              onSubmit={(
                in_production,
                source_name,
                base_url,
                user_email,
                user_password,
                language,
                crawl_attachments
              ) => {
                const handler = async () => {
                  try {
                    setIsSubmitting(true);
                    const resultCreated = (
                      await adminGroupCreateCrawlerSourcePOST.mutateAsync({
                        base_url: base_url,
                        crawl_attachments: crawl_attachments,
                        in_production: in_production,
                        language: language,
                        source_name: source_name,
                        user_email: user_email,
                        user_password: user_password,
                      })
                    )?.json;
                    setIsSubmitting(false);
                    if (resultCreated?.code) {
                      toastMessage(resultCreated?.message, 'error');
                    } else {
                      toastMessage(
                        'Create crawler source successfully',
                        'success'
                      );
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              initialData={null}
              isSubmitting={isSubmitting}
            />
          </SimpleStyleKeyboardAwareScrollView>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CrawlerSourceCreateScreen);
