import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import PEPFFormBlock from '../components/PEPFFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const PEPFCreateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupCreatePEPFPOST = AdminGroupApi.useCreatePEPFPOST();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <CurrentEditingSectionBlock />
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              { paddingBottom: { minWidth: Breakpoints.Laptop, value: 200 } },
              dimensions.width
            )}
          >
            <PEPFFormBlock
              onSubmit={(
                acquired_date,
                Company,
                company_id,
                country,
                gics,
                pe_firm,
                fund_entity,
                investment_responsible,
                company_description,
                website,
                buyside_cf_entry,
                buyside_legal_entry,
                exit_date,
                revenue_local,
                gross_profit_local,
                ebitda_local,
                ebit_local,
                growth,
                currency_financials,
                financial_year,
                financials_comment,
                stake_type
              ) => {
                const handler = async () => {
                  try {
                    setIsSubmitting(true);
                    const resultCreatedPEPF = (
                      await adminGroupCreatePEPFPOST.mutateAsync({
                        Company: Company,
                        acquired_date: acquired_date,
                        buyside_cf_entry: buyside_cf_entry?.id,
                        buyside_legal_entry: buyside_legal_entry?.id,
                        company_description: company_description,
                        company_id: company_id,
                        country: country,
                        currency_financials: currency_financials,
                        ebit_local: ebit_local,
                        ebitda_local: ebitda_local,
                        exit_date: exit_date,
                        financial_year: financial_year,
                        financials_comment: financials_comment,
                        fund_entity: fund_entity?.id,
                        gics: gics?.id,
                        gross_profit_local: gross_profit_local,
                        growth: growth,
                        investment_responsible: investment_responsible,
                        pe_firm: pe_firm?.id,
                        revenue_local: revenue_local,
                        stake_type: stake_type,
                        website: website,
                      })
                    )?.json;
                    setIsSubmitting(false);
                    if (resultCreatedPEPF?.code) {
                      toastMessage(resultCreatedPEPF?.message, 'error');
                    } else {
                      toastMessage('Create PEPF successfully', 'success');
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              initialData={null}
              isSubmitting={isSubmitting}
            />
          </SimpleStyleKeyboardAwareScrollView>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(PEPFCreateScreen);
