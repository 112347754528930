import React from 'react';
import { DatePicker, TextInput, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: null,
  isSubmitting: false,
  onCancel: () => {},
  onSubmit: () => {},
};

const NotePropCaseFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [note, setNote] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.note
  );
  const [suggestionUserAdminIds, setSuggestionUserAdminIds] = React.useState(
    []
  );
  const [timestamp, setTimestamp] = React.useState(new Date());
  const [user_admin_id, setUser_admin_id] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._user_admin,
      'name',
      'id',
      undefined
    )
  );
  const [date, setDate] = React.useState(new Date());
  const [textInputValue, setTextInputValue] = React.useState('');
  const validateForm = () => {
    const requiredFields = {
      note: 'Note',
      timestamp: 'Timestamp',
      user_admin_id: 'User admin id',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => !eval(key))
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) return true;
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        <>
          {!(props.initialData ?? defaultProps.initialData) ? null : (
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Table Row'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Table Row'].style,
                  {
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Quicksand_600SemiBold',
                    },
                    fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                    padding: { minWidth: Breakpoints.Laptop, value: 10 },
                  }
                ),
                dimensions.width
              )}
            >
              {'Adding new note for '}
              {(props.initialData ?? defaultProps.initialData)?.target}
            </Text>
          )}
        </>
        {/* Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: columnOnlySmallDevice(dimensions) },
            dimensions.width
          )}
        >
          {/* String */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderWidth: 1,
                  flex: flexOnlySmallDevice(dimensions, 0.3),
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Timestamp*:'}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <DatePicker
                autoDismissKeyboard={true}
                disabled={false}
                hideLabel={false}
                inline={false}
                label={'Date'}
                leftIconMode={'inset'}
                mode={'date'}
                onDateChange={newDatePickerValue => {
                  const date = newDatePickerValue;
                  try {
                    setTimestamp(newDatePickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                type={'solid'}
                {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                  .props}
                date={customFormatDate(timestamp)}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .style,
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
        {/* Row 2 */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: columnOnlySmallDevice(dimensions) },
            dimensions.width
          )}
        >
          {/* Note */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderWidth: 1,
                  flex: flexOnlySmallDevice(dimensions, 0.3),
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Note*'}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                multiline={true}
                numberOfLines={4}
                onChangeText={newTextAreaValue => {
                  const textInputValue = newTextAreaValue;
                  try {
                    setNote(newTextAreaValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                textAlignVertical={'top'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                  .props}
                placeholder={'Add note here'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Common TextArea'].style,
                  dimensions.width
                )}
                value={note}
              />
            </View>
          </View>
        </View>
        {/* Row 4 */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: columnOnlySmallDevice(dimensions) },
            dimensions.width
          )}
        >
          {/* Note */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Field */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderWidth: 1,
                  flex: flexOnlySmallDevice(dimensions, 0.3),
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Table Title'].style,
                  dimensions.width
                )}
              >
                {'Admin User Id *'}
              </Text>
            </View>
            {/* Value */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Outline,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: borderColorSmallDevice(dimensions),
                    },
                  ],
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  padding: 10,
                },
                dimensions.width
              )}
            >
              <FuzzyInputWithDataBlock
                getSuggestions={searchKeyword => {
                  const handler = async () => {
                    try {
                      const resultUserAdmins = (
                        await AdminGroupApi.getUserAdminGET(Constants)
                      )?.json;
                      setSuggestionUserAdminIds(
                        transformToSearchInputFormat(
                          resultUserAdmins?.items,
                          'name',
                          'id',
                          undefined
                        )
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                onSelect={selectedResult => {
                  try {
                    setUser_admin_id(selectedResult);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                multiSelect={false}
                selectedItem={user_admin_id}
                selectedItems={[]}
                suggestionsList={suggestionUserAdminIds}
              />
            </View>
          </View>
        </View>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            if (props.onCancel ?? defaultProps.onCancel) {
              props.onCancel?.();
            } else {
            }

            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(note, timestamp, user_admin_id?.id);
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(NotePropCaseFormBlock);
