import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import moment from 'moment';
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
} from 'react-native';
import { withTheme } from '@draftbit/ui';
import * as GlobalStyles from '../GlobalStyles.js';
import useWindowDimensions from '../utils/useWindowDimensions';
import * as StyleSheet from '../utils/StyleSheet';

const Index = withTheme(
  ({ onChange, value, placeholder = 'Select Month/Year', theme }) => {
    const dimensions = useWindowDimensions();
    const containerRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(
      value ? moment(value) : moment()
    );
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [dropdownLayout, setDropdownLayout] = useState(null);

    useEffect(() => {
      if (value) {
        setSelectedDate(moment(value));
      }
    }, [value]);

    const togglePicker = useCallback(() => {
      if (!isPickerOpen) {
        containerRef.current.measure((x, y, width, height, pageX, pageY) => {
          const screenHeight = dimensions.height;
          const spaceBelow = screenHeight - (pageY + height);
          const requiredHeight = 300;

          const showAbove = spaceBelow < requiredHeight;

          setDropdownLayout({
            width,
            height,
            pageX,
            pageY: showAbove ? pageY - requiredHeight : pageY + height,
            showAbove,
          });
        });
      }
      setIsPickerOpen(prev => !prev);
    }, [isPickerOpen, dimensions.height]);

    const formatDisplayValue = useCallback(() => {
      return value ? selectedDate.format('MMMM YYYY') : placeholder;
    }, [value, selectedDate, placeholder]);

    const handleOutsidePress = useCallback(() => {
      setIsPickerOpen(false);
    }, []);

    return (
      <View ref={containerRef} style={styles.container}>
        <TouchableOpacity
          onPress={togglePicker}
          style={StyleSheet.applyWidth(
            {
              ...GlobalStyles.TextInputStyles(theme)['Common TextInput'].style,
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          <Text
            style={[
              styles.inputText,
              { color: value ? 'black' : theme.colors.foreground.brand },
            ]}
          >
            {formatDisplayValue()}
          </Text>
        </TouchableOpacity>
        <Modal
          visible={isPickerOpen}
          transparent={true}
          animationType="none"
          onRequestClose={() => setIsPickerOpen(false)}
        >
          <TouchableWithoutFeedback onPress={handleOutsidePress}>
            <View style={styles.modalOverlay}>
              {dropdownLayout && (
                <TouchableWithoutFeedback>
                  <View
                    style={[
                      styles.pickerContainer,
                      {
                        top: dropdownLayout.pageY,
                        left: dropdownLayout.pageX,
                        width: dropdownLayout.width,
                      },
                      dropdownLayout.showAbove && {
                        transformOrigin: 'bottom',
                      },
                    ]}
                  >
                    <PickerOverlay
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      onChange={onChange}
                      closeOverlay={() => setIsPickerOpen(false)}
                    />
                  </View>
                </TouchableWithoutFeedback>
              )}
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    );
  }
);

const PickerOverlay = ({
  selectedDate,
  setSelectedDate,
  onChange,
  closeOverlay,
}) => {
  const months = useMemo(() => moment.monthsShort(), []);

  const handleYearChange = useCallback(
    increment => {
      const newDate = selectedDate.clone().add(increment, 'year');
      setSelectedDate(newDate);
      onChange(newDate.toISOString());
    },
    [onChange, setSelectedDate]
  );

  const handleMonthChange = useCallback(
    monthIndex => {
      const newDate = selectedDate.clone().month(monthIndex);
      setSelectedDate(newDate);
      onChange(newDate.toISOString());
      closeOverlay();
    },
    [onChange, setSelectedDate, closeOverlay]
  );

  return (
    <View>
      <View style={styles.yearSelector}>
        <TouchableOpacity
          onPress={() => handleYearChange(-1)}
          style={styles.yearArrow}
        >
          <Text style={styles.yearArrowText}>←</Text>
        </TouchableOpacity>
        <Text style={styles.yearLabel}>{selectedDate.year()}</Text>
        <TouchableOpacity
          onPress={() => handleYearChange(1)}
          style={styles.yearArrow}
        >
          <Text style={styles.yearArrowText}>→</Text>
        </TouchableOpacity>
      </View>
      <Text style={styles.sectionTitle}>Month</Text>
      <View style={styles.gridContainer}>
        {months.map((month, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.gridItem,
              { width: `${100 / 3}%` },
              index === selectedDate.month() && styles.selectedGridItem,
            ]}
            onPress={() => handleMonthChange(index)}
          >
            <Text
              style={[
                styles.gridItemText,
                index === selectedDate.month() && styles.selectedGridItemText,
              ]}
            >
              {month}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1000,
  },
  inputText: {
    fontSize: 15,
    fontFamily: 'Quicksand_500Medium',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  pickerContainer: {
    position: 'absolute',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    backgroundColor: 'white',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 10,
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 5,
  },
  gridItem: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItemText: {
    fontSize: 16,
  },
  selectedGridItem: {
    backgroundColor: '#2d4664',
    borderRadius: 5,
  },
  selectedGridItemText: {
    color: 'white',
  },
  yearSelector: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  yearArrow: {
    padding: 5,
  },
  yearArrowText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  yearLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export { Index };
