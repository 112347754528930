import React from 'react';
import { Pressable, withTheme } from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { label: 'Label', onPress: () => {} };

const UnderlineButtonBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View>
      <Pressable
        onPress={() => {
          try {
            props.onPress?.();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <View>
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  fontFamily: {
                    minWidth: Breakpoints.Laptop,
                    value: 'Quicksand_600SemiBold',
                  },
                  fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                  textDecorationLine: {
                    minWidth: Breakpoints.Laptop,
                    value: 'underline',
                  },
                }
              ),
              dimensions.width
            )}
          >
            {props.label ?? defaultProps.label}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default withTheme(UnderlineButtonBlock);
