const transformToSearchInputFormat = (
  array,
  labelProperty,
  valueProperty,
  additionalLabel1Property
) => {
  if (array === null || array === undefined || array?.length === 0) {
    return [];
  }
  if (array?.length === 1 && array[0]?.length === 0) {
    return [];
  }
  return array
    .filter(item => item != null)
    .map(item => ({
      id: item[valueProperty] ?? '',
      title: [item[labelProperty] ?? '', item[additionalLabel1Property] ?? '']
        .filter(Boolean)
        .join(' - '),
    }));
};

export default transformToSearchInputFormat;
