import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { Hoverable } from 'react-native-hoverable';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Index = ({ onMouseEnter, onMouseLeave, children }) => {
  const debouncedOnMouseEnter = useCallback(debounce(onMouseEnter, 120), [
    onMouseEnter,
  ]);
  const debouncedOnMouseLeave = useCallback(debounce(onMouseLeave, 120), [
    onMouseLeave,
  ]);

  return (
    <Hoverable
      onMouseEnter={debouncedOnMouseEnter}
      onMouseLeave={debouncedOnMouseLeave}
    >
      {children}
    </Hoverable>
  );
};

export { Index };
