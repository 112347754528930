import React from 'react';
import {
  Button,
  Checkbox,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import OverlayBlock from '../components/OverlayBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const CrawlerResultListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [confirmationText, setConfirmationText] = React.useState('');
  const [crawlerKeyword, setCrawlerKeyword] = React.useState(false);
  const [crawlerKeywordList, setCrawlerKeywordList] = React.useState([]);
  const [crawlerResult, setCrawlerResult] = React.useState(false);
  const [crawlerResultList, setCrawlerResultList] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [detailItem, setDetailItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupDeleteCFSDELETE = AdminGroupApi.useDeleteCFSDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNextPage(1);
      setCrawlerResultList([]);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('CFSNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          showAddButton={false}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Details */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.4 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Details'}
                  </Text>
                </View>
                {/* Published */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.5, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Published'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Available */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Available'}
                  </Text>
                </View>
                {/* Source */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.5, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Source'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Original Headline */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Original Headline'}
                  </Text>
                </View>
                {/* Keyword Matches */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Keyword Matches\n'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetCrawlerResultGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== crawlerResultList) {
                          setCrawlerResultList(fetchData?.json?.items);
                        } else {
                        }

                        console.log(crawlerResultList);
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword_search={keywordSearch}
                  page={1}
                >
                  {({ loading, error, data, refetchGetCrawlerResult }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <SimpleStyleFlatList
                          data={crawlerResultList}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'pg6y6FxZ'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              console.log('List ON_END_REACHED Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                );
                                console.log(
                                  'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                                if (nextPage === null) {
                                  return console.log(
                                    'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:2 FETCH_REQUEST'
                                );
                                const newData = (
                                  await AdminGroupApi.getCrawlerResultGET(
                                    Constants,
                                    {
                                      keyword_search: keywordSearch,
                                      page: nextPage,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                  { newData }
                                );
                                console.log(
                                  'Start ON_END_REACHED:3 SET_VARIABLE'
                                );
                                setNextPage(newData?.nextPage);
                                console.log(
                                  'Complete ON_END_REACHED:3 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:4 SET_VARIABLE'
                                );
                                setLastPage(newData?.pageTotal);
                                console.log(
                                  'Complete ON_END_REACHED:4 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                                );
                                if (
                                  fetchData?.items ===
                                  (0 || crawlerResultList !== fetchData?.items)
                                ) {
                                  return console.log(
                                    'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                  );
                                }
                                console.log(
                                  'Start ON_END_REACHED:6 SET_VARIABLE'
                                );
                                setCrawlerResultList(
                                  crawlerResultList.concat(newData?.items)
                                );
                                console.log(
                                  'Complete ON_END_REACHED:6 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'List ON_END_REACHED Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'auto',
                                    },
                                    backgroundColor: getColorByIndex(index),
                                    borderColor: palettes.App.Outline,
                                    borderLeftWidth: 1,
                                    flexDirection: 'row',
                                    width: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 1200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Actions */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.4,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      flexDirection: 'row',
                                      height: '100%',
                                      justifyContent: 'center',
                                      maxWidth: 150,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setDetailItem(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            {
                                              textDecorationLine: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'underline',
                                              },
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'VIEW'}
                                      </Text>
                                    </View>
                                  </Pressable>
                                </View>
                                {/* published_acc_to_source */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {formatTimestampToCopenhagen(
                                          listData?.published_acc_to_source
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* pushed_to_xano */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.Outline,
                                      borderRightWidth: 1,
                                      flex: 0.5,
                                      height: '100%',
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {formatTimestampToCopenhagen(
                                      listData?.pushed_to_xano
                                    )}
                                  </Text>
                                </View>
                                {/* crawler_source_text */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.crawler_source_text}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* headline_original */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.headline_original}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* keyword_matches */}
                                <>
                                  {!(
                                    dimensions.width >= Breakpoints.Laptop
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.25,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.5,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.keyword_matches}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            );
                          }}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value:
                                  dimensions.width >= Breakpoints.Laptop
                                    ? 0
                                    : undefined,
                              },
                              maxHeight: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: dimensions.height - 280,
                                },
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: dimensions.height - 250,
                                },
                              ],
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        <>
                          {!(crawlerResultList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetCrawlerResultGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteCFS = (
                  await adminGroupDeleteCFSDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteCFS?.error) {
                  toastMessage(resultDeleteCFS?.message, 'error');
                } else {
                  toastMessage('Delete CFS successfully', 'success');
                  setDeletingItem(null);
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'CFS'}
          visible={deletingItem !== null}
        />
        {/* Modal View */}
        <Modal
          supportedOrientations={['portrait', 'landscape']}
          transparent={false}
          animationType={'fade'}
          transparent={StyleSheet.getWidthValue(
            [{ minWidth: Breakpoints.Laptop, value: true }],
            dimensions.width
          )}
          visible={detailItem}
        >
          <OverlayBlock />
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
              },
              dimensions.width
            )}
          >
            {/* Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.White,
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  maxHeight: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height - 60,
                  },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 30 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                  width: { minWidth: Breakpoints.Laptop, value: '60%' },
                },
                dimensions.width
              )}
            >
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  {
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: 30 },
                    paddingRight: { minWidth: Breakpoints.Laptop, value: 30 },
                  },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetCrawlerResultDetailGET
                  crawler_result_id={detailItem?.id}
                >
                  {({
                    loading,
                    error,
                    data,
                    refetchGetCrawlerResultDetail,
                  }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: { minWidth: Breakpoints.Laptop, value: 20 } },
                          dimensions.width
                        )}
                      >
                        {/* Row 1 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Crawler Source:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.crawler_source}
                          </Text>
                        </View>
                        {/* Row 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Crawler source text:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.crawler_source_text}
                          </Text>
                        </View>
                        {/* Row 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Published acc to source:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {formatTimestampToCopenhagen(
                              fetchData?.published_acc_to_source
                            )}
                          </Text>
                        </View>
                        {/* Row 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Retrieved acc to dataox:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {formatTimestampToCopenhagen(
                              fetchData?.retrieved_acc_to_dataox
                            )}
                          </Text>
                        </View>
                        {/* Row 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Headline original:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.headline_original}
                          </Text>
                        </View>
                        {/* Row 6 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Story original:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.story_original}
                          </Text>
                        </View>
                        {/* Row 7 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'url:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.url}
                          </Text>
                        </View>
                        {/* Row 8 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Keyword matches:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.keyword_matches}
                          </Text>
                        </View>
                        {/* Row 9 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Pdf crawled:'}
                          </Text>
                          <Checkbox
                            color={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                            defaultValue={fetchData?.pdf_crawled}
                            disabled={true}
                            uncheckedColor={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Row 10 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Keyword matches pdf:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.keyword_matches_pdf}
                          </Text>
                        </View>
                        {/* Row 11 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Linkedin account name:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.linkedin_account_name}
                          </Text>
                        </View>
                        {/* Row 12 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Linkedin account url:'}
                          </Text>
                          {/* Value */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.linkedin_account_url}
                          </Text>
                        </View>
                        {/* Row 13 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Keyword relevance:'}
                          </Text>
                          <Checkbox
                            color={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                            defaultValue={fetchData?.keyword_relevance}
                            disabled={true}
                            uncheckedColor={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Row 14 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: { minWidth: Breakpoints.Laptop, value: 6 },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Key */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Table Row']
                              .props}
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Table Row']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Quicksand_600SemiBold',
                                  },
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 16,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Sent to spider: '}
                          </Text>
                          <Checkbox
                            color={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                            defaultValue={fetchData?.sent_to_spider}
                            disabled={true}
                            uncheckedColor={StyleSheet.getWidthValue(
                              [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                    );
                  }}
                </AdminGroupApi.FetchGetCrawlerResultDetailGET>
              </SimpleStyleScrollView>
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setDetailItem(null);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
                    {
                      marginLeft: { minWidth: Breakpoints.Laptop, value: 30 },
                      marginRight: { minWidth: Breakpoints.Laptop, value: 30 },
                      marginTop: { minWidth: Breakpoints.Laptop, value: 30 },
                    }
                  ),
                  dimensions.width
                )}
                title={'Close'}
              />
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CrawlerResultListingScreen);
