import React, { memo } from 'react';
import { StyleSheet, Text } from 'react-native';
import ReadMore from 'react-native-read-more-text';

const Index = ({ children, numberOfLines = 5 }) => {
  const renderTruncatedFooter = handlePress => (
    <Text style={styles.seeMoreLessStyle} onPress={handlePress}>
      See more
    </Text>
  );

  const renderRevealedFooter = handlePress => (
    <Text style={styles.seeMoreLessStyle} onPress={handlePress}>
      Hide
    </Text>
  );

  return (
    <ReadMore
      numberOfLines={numberOfLines}
      renderTruncatedFooter={renderTruncatedFooter}
      renderRevealedFooter={renderRevealedFooter}
      style={styles.textStyle}
    >
      {children}
    </ReadMore>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    fontSize: 14,
    lineHeight: 20,
  },
  seeMoreLessStyle: {
    color: '#007AFF',
    fontWeight: 'bold',
  },
});

export { Index };
