import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as ReadMoreText from '../custom-files/ReadMoreText';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const POTDListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [potd, setPotd] = React.useState(false);
  const [potdList, setPotdList] = React.useState([]);
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupDeletePOTDDELETE = AdminGroupApi.useDeletePOTDDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNextPage(1);
      setPotdList([]);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('POTDNavigator', {
                screen: 'POTDCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD POTD'}
          localSelectedPage={Constants['selectedPage']}
          setLocalSelectedPage={localSelectedPage =>
            setGlobalVariableValue({
              key: 'selectedPage',
              value: localSelectedPage,
            })
          }
          showAddButton={true}
        />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  maxWidth: 1200,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 0.25,
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Created */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Target */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Target'}
                  </Text>
                </View>
                {/* Headline */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 1 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Headline'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Stage */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Stage'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Headquarter */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.5, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Headquarter'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetPOTDGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== potdList) {
                          setPotdList(fetchData?.json?.items);
                        } else {
                        }

                        console.log(potdList);
                        setNextPage(fetchData?.json?.nextPage);
                        /* hidden 'Set Variable' action */
                        /* hidden 'If/Else' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  page={1}
                >
                  {({ loading, error, data, refetchGetPOTD }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <>
                          {!(potdList?.length > 0) ? null : (
                            <SimpleStyleFlatList
                              data={potdList}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={'AK9TyWgE'}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReached={() => {
                                const handler = async () => {
                                  console.log('List ON_END_REACHED Start');
                                  let error = null;
                                  try {
                                    console.log(
                                      'Start ON_END_REACHED:0 CONSOLE_LOG'
                                    );
                                    console.log(nextPage);
                                    console.log(
                                      'Complete ON_END_REACHED:0 CONSOLE_LOG'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                                    );
                                    if (nextPage === null) {
                                      return console.log(
                                        'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                      );
                                    } else {
                                      console.log(
                                        'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                      );
                                    }
                                    console.log(
                                      'Start ON_END_REACHED:2 FETCH_REQUEST'
                                    );
                                    const newData = (
                                      await AdminGroupApi.getPOTDGET(
                                        Constants,
                                        {
                                          keyword: keywordSearch,
                                          page: nextPage,
                                        }
                                      )
                                    )?.json;
                                    console.log(
                                      'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                      { newData }
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                    setNextPage(newData?.nextPage);
                                    console.log(
                                      'Complete ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:4 SET_VARIABLE'
                                    );
                                    setLastPage(newData?.pageTotal);
                                    console.log(
                                      'Complete ON_END_REACHED:4 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                                    );
                                    if (
                                      fetchData?.items ===
                                      (0 || potdList !== fetchData?.items)
                                    ) {
                                      return console.log(
                                        'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                      );
                                    } else {
                                      console.log(
                                        'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                      );
                                    }
                                    console.log(
                                      'Start ON_END_REACHED:6 SET_VARIABLE'
                                    );
                                    setPotdList(
                                      potdList.concat(newData?.items)
                                    );
                                    console.log(
                                      'Complete ON_END_REACHED:6 SET_VARIABLE'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                    error = err.message ?? err;
                                  }
                                  console.log(
                                    'List ON_END_REACHED Complete',
                                    error ? { error } : 'no error'
                                  );
                                };
                                handler();
                              }}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        alignSelf: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'auto',
                                        },
                                        backgroundColor: getColorByIndex(index),
                                        borderColor: palettes.App.Outline,
                                        borderLeftWidth: 1,
                                        flexDirection: 'row',
                                        width: {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 1200,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Actions */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.25,
                                          flexDirection: 'row',
                                          height: '100%',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <TableActionsBlock
                                        onPressDelete={() => {
                                          try {
                                            setDeletingItem(listData);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPressEdit={() => {
                                          try {
                                            navigation.navigate(
                                              'POTDNavigator',
                                              {
                                                screen: 'POTDEditScreen',
                                                params: { id: listData?.id },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      />
                                    </View>
                                    {/* Created (newest to oldest) */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: 0.25,
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Table Row'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {formatTimestampToCopenhagen(
                                              listData?.created_at
                                            )}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Target */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.target}
                                      </Text>
                                    </View>
                                    {/* Headline */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 0.25,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 1,
                                                },
                                              ],
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Utils.CustomCodeErrorBoundary>
                                            <ReadMoreText.Index>
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Table Row'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Table Row'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.headline}
                                              </Text>
                                            </ReadMoreText.Index>
                                          </Utils.CustomCodeErrorBoundary>
                                        </View>
                                      )}
                                    </>
                                    {/* Stage */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: palettes.App.Outline,
                                              borderRightWidth: 1,
                                              flex: 0.25,
                                              height: '100%',
                                              padding: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Table Row'
                                                ].style,
                                                {
                                                  color:
                                                    listData?.stage === 'Live'
                                                      ? palettes.App
                                                          .Peoplebit_Salmon_Red
                                                      : theme.colors.text
                                                          .strong,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.stage}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Headquarter */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: 0.5,
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.hq}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop
                                        ? 0
                                        : undefined,
                                  },
                                  maxHeight: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: dimensions.height - 280,
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: dimensions.height - 250,
                                    },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(potdList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                        <ModalDeleteConfirmationBlock
                          onCancel={() => {
                            try {
                              setDeletingItem(null);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          onConfirmDelete={() => {
                            const handler = async () => {
                              try {
                                setIsLoadingConfirmDelete(true);
                                const resultDelete = (
                                  await adminGroupDeletePOTDDELETE.mutateAsync({
                                    id: deletingItem?.id,
                                  })
                                )?.json;
                                setIsLoadingConfirmDelete(false);
                                if (resultDelete?.code) {
                                  toastMessage(resultDelete?.message, 'error');
                                } else {
                                  toastMessage(
                                    'Delete POTD successfully',
                                    'success'
                                  );
                                  setDeletingItem(null);
                                  await refetchGetPOTD();
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          isLoadingConfirm={isLoadingConfirmDelete}
                          targetName={'POTD'}
                          visible={deletingItem !== null}
                        />
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetPOTDGET>
              </View>
            </View>
          </View>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(POTDListingScreen);
