const convertToOptions = (array, labelKey, valueKey, additionalLabel1Key) => {
  return array.map(item => ({
    value: item[valueKey],
    label: [item[labelKey] ?? '', item[additionalLabel1Key] ?? '']
      .filter(Boolean)
      .join(' - '),
  }));
};

export default convertToOptions;
