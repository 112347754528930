import * as CommonPackages from '../custom-files/CommonPackages';

const borderColorSmallDevice = dimensions => {
  const palettes = CommonPackages?.palettes;
  return dimensions?.width >= CommonPackages?.Breakpoints.Laptop
    ? 'transparent'
    : palettes.App.Outline;
};

export default borderColorSmallDevice;
