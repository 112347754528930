import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  View,
  TextInput,
  FlatList,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from 'react-native';
import { useOverlay } from './OverlayContext';
import { debounce } from 'lodash';

const Index = ({
  options = [],
  onSelect = () => {},
  placeholder = 'Search...',
  selectedItem = null,
  selectedItems: initialSelectedItems = [],
  getSuggestions = () => {},
  multiSelect = true,
}) => {
  const [query, setQuery] = useState(() => {
    if (!multiSelect && selectedItem) {
      return selectedItem.title;
    }
    return '';
  });
  const [filteredData, setFilteredData] = useState(options);
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const { setDropdown, closeDropdown } = useOverlay();
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputContainerRef = useRef(null);
  const [inputContainerWidth, setInputContainerWidth] = useState(0);

  React.useEffect(() => {
    setFilteredData(options);
  }, [options]);

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.measure((x, y, width) => {
        setInputContainerWidth(width);
      });
    }
  }, []);

  const debouncedGetSuggestions = useCallback(
    debounce(text => {
      setIsLoading(true);
      getSuggestions(text);
    }, 300),
    [getSuggestions]
  );

  const handleSearch = useCallback(
    text => {
      setQuery(text);
      setIsLoading(true);
      debouncedGetSuggestions(text);
    },
    [debouncedGetSuggestions]
  );

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [filteredData]);

  const handleSelect = useCallback(
    item => {
      if (multiSelect) {
        setSelectedItems(prev => {
          const isAlreadySelected = prev.some(
            selected => selected.id === item.id
          );
          let updatedItems;
          if (isAlreadySelected) {
            updatedItems = prev.filter(selected => selected.id !== item.id);
          } else {
            updatedItems = [...prev, item];
          }
          onSelect(updatedItems);
          return updatedItems;
        });
        setIsFocused(true);
        showDropdown();
      } else {
        onSelect(item);
        setQuery(item.title);
        setSelectedItems([item]);
        setIsFocused(false);
        closeDropdown();
      }
    },
    [onSelect, showDropdown, closeDropdown, multiSelect]
  );

  const handleRemoveItem = useCallback(
    itemToRemove => {
      setIsFocused(false);
      if (multiSelect) {
        setSelectedItems(prev => {
          const updatedItems = prev.filter(item => item.id !== itemToRemove.id);
          onSelect(updatedItems);
          return updatedItems;
        });
      } else {
        setSelectedItems([]);
        onSelect(null);
        setQuery('');
      }
    },
    [onSelect, multiSelect]
  );

  const showDropdown = useCallback(() => {
    if (inputContainerRef.current && inputContainerWidth > 0) {
      inputContainerRef.current.measureInWindow((x, y, width, height) => {
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - (y + height);
        const spaceAbove = y;
        const dropdownHeight = Math.min(300, filteredData.length * 44 + 20);
        const showAbove =
          spaceBelow < dropdownHeight && spaceAbove > spaceBelow;

        setDropdown(
          <TouchableWithoutFeedback
            onPress={() => {
              setIsFocused(false);
              closeDropdown();
            }}
          >
            <View style={StyleSheet.absoluteFill}>
              <View style={styles.blackOverlay} />
              <TouchableWithoutFeedback>
                <View
                  style={[
                    styles.dropdown,
                    {
                      // If showing above, position from bottom of input
                      ...(showAbove
                        ? { bottom: windowHeight - y }
                        : { top: y + height }),
                      left: x,
                      width: inputContainerWidth,
                      maxHeight: Math.min(
                        showAbove ? spaceAbove : spaceBelow,
                        300
                      ),
                    },
                  ]}
                >
                  {isLoading ? (
                    <ActivityIndicator style={styles.loadingIndicator} />
                  ) : filteredData.length > 0 ? (
                    <FlatList
                      data={filteredData}
                      keyExtractor={item => item.id.toString()}
                      renderItem={({ item }) => (
                        <TouchableOpacity
                          style={[
                            styles.itemContainer,
                            selectedItems.some(
                              selected => selected.id === item.id
                            ) && styles.selectedItemContainer,
                          ]}
                          onPress={() => handleSelect(item)}
                        >
                          <Text style={styles.itemTitle}>{item.title}</Text>
                          {selectedItems.some(
                            selected => selected.id === item.id
                          ) && <Text style={styles.selectedLabel}>✓</Text>}
                        </TouchableOpacity>
                      )}
                      extraData={selectedItems} // Add this line
                    />
                  ) : (
                    <Text style={styles.noResults}>No results found</Text>
                  )}
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        );
      });
    }
  }, [
    filteredData,
    setDropdown,
    handleSelect,
    selectedItems,
    isLoading,
    inputContainerWidth,
    closeDropdown,
  ]);

  useEffect(() => {
    if (isFocused) {
      showDropdown();
    }
  }, [isFocused, showDropdown, closeDropdown]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    showDropdown();
  }, [showDropdown]);

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer} ref={inputContainerRef}>
        {multiSelect &&
          selectedItems.map(item => (
            <TouchableOpacity
              key={item.id}
              style={styles.tag}
              onPress={() => handleRemoveItem(item)}
            >
              <Text style={styles.tagText}>{item.title}</Text>
              <Text style={styles.tagRemove}>×</Text>
            </TouchableOpacity>
          ))}
        <TextInput
          ref={inputRef}
          style={[
            styles.input,
            !multiSelect && selectedItems.length > 0 && styles.selectedInput,
          ]}
          value={query}
          onChangeText={handleSearch}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={() => setIsFocused(false)}
          placeholderTextColor="#A5ADB7FF"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: 60,
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderColor: '#D7DEE1FF',
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: 'white',
  },
  tag: {
    backgroundColor: '#1B62F8',
    borderRadius: 16,
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginRight: 4,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  tagText: {
    color: 'white',
    fontSize: 14,
    flexShrink: 1,
    marginRight: 4,
    flex: 1,
  },
  tagRemove: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
  input: {
    flex: 1,
    minWidth: 80,
    height: 32,
    padding: 0,
    outlineStyle: 'none',
    fontFamily: 'Quicksand_500Medium',
    fontSize: 16,
  },
  dropdown: {
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: '#e0e0e0',
    borderWidth: 1,
    borderRadius: 4,
    maxHeight: 300,
    zIndex: 1000,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0',
  },
  selectedItemContainer: {
    backgroundColor: '#f0f8ff',
  },
  itemTitle: {
    fontSize: 16,
    color: '#333',
  },
  selectedLabel: {
    fontSize: 18,
    color: '#1B62F8',
    fontWeight: 'bold',
  },
  loadingIndicator: {
    padding: 10,
  },
  noResults: {
    padding: 10,
    textAlign: 'center',
    color: 'gray',
  },
  blackOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'transparent',
  },
  selectedInput: {
    // ... styles for selectedInput ...
  },
  singleSelectRemove: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: [{ translateY: -10 }],
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#e0e0e0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleSelectRemoveText: {
    fontSize: 16,
    color: '#333',
    fontWeight: 'bold',
  },
});

export { Index };
