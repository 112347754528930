import React from 'react';
import { Picker, Surface, TextInput, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FormActionsBlock from '../components/FormActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: null,
  isSubmitting: false,
  onSubmit: () => {},
};

const AdvisorFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.name
  );
  const [type, setType] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.type
  );
  const validateForm = () => {
    const requiredFields = {
      name: 'Name',
      type: 'Type',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => !eval(key))
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) return true;
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Name */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Name *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setName(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={name}
                />
              </View>
            </View>
          </View>
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Type */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Type *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newPickerValue => {
                    try {
                      setType(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  mode={'native'}
                  options={Constants['ADVISOR_TYPE_OPTIONS']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={type}
                />
              </View>
            </View>
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(name, type);
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(AdvisorFormBlock);
