import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SplashScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  // import * as ScreenCapture from "expo-screen-capture";

  // const hasPermissions = async () => {
  //     const { status } = await ScreenCapture.requestPermissionsAsync();
  //     console.log("has perpm", status);
  //     return status === "granted";
  // };

  // useEffect(() => {
  //     let subscription;

  //     const addListenerAsync = async () => {
  //         if (await hasPermissions()) {
  //             await ScreenCapture.preventScreenCaptureAsync();
  //             console.log("add listner");
  //             subscription = ScreenCapture.addScreenshotListener(() => {
  //                 console.log("handling listener");
  //                 alert("Thanks for screenshotting my beautiful app 😊");
  //             });
  //         } else {
  //             console.error(
  //                 "Permissions needed to subscribe to screenshot events are missing!"
  //             );
  //         }
  //     };
  //     addListenerAsync();

  //     return () => {
  //         subscription?.remove();
  //     };
  // }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    console.log('Screen ON_SCREEN_FOCUS Start');
    let error = null;
    try {
      if (!isFocused) {
        return;
      }
      console.log('Start ON_SCREEN_FOCUS:0 IF');
      if (Constants['AUTH_HEADER']) {
        if (navigation.canGoBack()) {
          navigation.popToTop();
        }
        navigation.replace('EventNavigator', { screen: 'EventListingScreen' });
      } else {
        if (navigation.canGoBack()) {
          navigation.popToTop();
        }
        navigation.replace('LogInScreen');
      }
      console.log('Complete ON_SCREEN_FOCUS:0 IF');
    } catch (err) {
      console.error(err);
      error = err.message ?? err;
    }
    console.log(
      'Screen ON_SCREEN_FOCUS Complete',
      error ? { error } : 'no error'
    );
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors.background.brand,
          justifyContent: 'center',
        },
        dimensions.width
      )}
    />
  );
};

export default withTheme(SplashScreen);
