import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import EmptyListStateBlock from '../components/EmptyListStateBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const AdvisorListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [advisor, setAdvisor] = React.useState(false);
  const [advisorsList, setAdvisorsList] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupDeleteAdvisorDELETE = AdminGroupApi.useDeleteAdvisorDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setAdvisorsList([]);
      setNextPage(1);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('AdvisorNavigator', {
                screen: 'AdvisorCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD ADVISOR'}
          showAddButton={true}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={350}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 0.4,
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                />
                {/* Name (A-z) */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Name'}
                  </Text>
                </View>
                {/* Type */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Type'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View>
                <AdminGroupApi.FetchGetAdvisorsGET
                  handlers={{
                    on2xx: fetchData => {
                      try {
                        if (fetchData?.json?.items !== advisorsList) {
                          setAdvisorsList(fetchData?.json?.items);
                        } else {
                        }

                        setNextPage(fetchData?.json?.nextPage);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  page={1}
                >
                  {({ loading, error, data, refetchGetAdvisors }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <>
                          {!(advisorsList?.length === 0) ? null : (
                            <EmptyListStateBlock />
                          )}
                        </>
                        <>
                          {!(advisorsList?.length > 0) ? null : (
                            <SimpleStyleFlatList
                              data={advisorsList}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(listData)
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={'NIknt7Dp'}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReached={() => {
                                const handler = async () => {
                                  console.log('List ON_END_REACHED Start');
                                  let error = null;
                                  try {
                                    console.log(
                                      'Start ON_END_REACHED:0 CONDITIONAL_STOP'
                                    );
                                    if (nextPage === null) {
                                      return console.log(
                                        'Complete ON_END_REACHED:0 CONDITIONAL_STOP'
                                      );
                                    } else {
                                      console.log(
                                        'Skipped ON_END_REACHED:0 CONDITIONAL_STOP: condition not met'
                                      );
                                    }
                                    console.log(
                                      'Start ON_END_REACHED:1 FETCH_REQUEST'
                                    );
                                    const newData = (
                                      await AdminGroupApi.getAdvisorsGET(
                                        Constants,
                                        {
                                          keyword: keywordSearch,
                                          page: nextPage,
                                        }
                                      )
                                    )?.json;
                                    console.log(
                                      'Complete ON_END_REACHED:1 FETCH_REQUEST',
                                      { newData }
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:2 SET_VARIABLE'
                                    );
                                    setNextPage(newData?.nextPage);
                                    console.log(
                                      'Complete ON_END_REACHED:2 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                    setLastPage(newData?.pageTotal);
                                    console.log(
                                      'Complete ON_END_REACHED:3 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_END_REACHED:4 CONDITIONAL_STOP'
                                    );
                                    if (
                                      fetchData?.items ===
                                      (0 || advisorsList !== fetchData?.items)
                                    ) {
                                      return console.log(
                                        'Complete ON_END_REACHED:4 CONDITIONAL_STOP'
                                      );
                                    } else {
                                      console.log(
                                        'Skipped ON_END_REACHED:4 CONDITIONAL_STOP: condition not met'
                                      );
                                    }
                                    console.log(
                                      'Start ON_END_REACHED:5 SET_VARIABLE'
                                    );
                                    setAdvisorsList(
                                      advisorsList.concat(newData?.items)
                                    );
                                    console.log(
                                      'Complete ON_END_REACHED:5 SET_VARIABLE'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                    error = err.message ?? err;
                                  }
                                  console.log(
                                    'List ON_END_REACHED Complete',
                                    error ? { error } : 'no error'
                                  );
                                };
                                handler();
                              }}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'auto',
                                        },
                                        backgroundColor: getColorByIndex(index),
                                        borderColor: palettes.App.Outline,
                                        borderLeftWidth: 1,
                                        flexDirection: 'row',
                                        height: 40,
                                        width: {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 1200,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Actions */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 0.4,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0.4,
                                            },
                                          ],
                                          flexDirection: 'row',
                                          height: '100%',
                                          justifyContent: 'center',
                                          maxWidth: 150,
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <TableActionsBlock
                                        onPressDelete={() => {
                                          try {
                                            setDeletingItem(listData);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPressEdit={() => {
                                          try {
                                            navigation.navigate(
                                              'AdvisorNavigator',
                                              {
                                                screen: 'AdvisorEditScreen',
                                                params: { id: listData?.id },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        border={false}
                                      />
                                    </View>
                                    {/* Name (A-z) */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 1,
                                            },
                                          ],
                                          height: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name}
                                      </Text>
                                    </View>
                                    {/* Type */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: palettes.App.Outline,
                                          borderRightWidth: 1,
                                          flex: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 1,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 1,
                                            },
                                          ],
                                          maxHeight: '100%',
                                          padding: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].props}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.type}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop
                                        ? 0
                                        : undefined,
                                  },
                                  maxHeight: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: dimensions.height - 280,
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: dimensions.height - 250,
                                    },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </>
                    );
                  }}
                </AdminGroupApi.FetchGetAdvisorsGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                (
                  await adminGroupDeleteAdvisorDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                setDeletingItem(null);
                toastMessage('Delete advisor successfully', 'success');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'advisor'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(AdvisorListingScreen);
