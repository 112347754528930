import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as GlobalVariables from './config/GlobalVariableContext';
import convertToOptions from './global-functions/convertToOptions';
import AdvisorCreateScreen from './screens/AdvisorCreateScreen';
import AdvisorEditScreen from './screens/AdvisorEditScreen';
import AdvisorListingScreen from './screens/AdvisorListingScreen';
import CFSCreateScreen from './screens/CFSCreateScreen';
import CFSEditScreen from './screens/CFSEditScreen';
import CFSListingScreen from './screens/CFSListingScreen';
import CrawlerKeywordListingScreen from './screens/CrawlerKeywordListingScreen';
import CrawlerResultListingScreen from './screens/CrawlerResultListingScreen';
import CrawlerSourceCreateScreen from './screens/CrawlerSourceCreateScreen';
import CrawlerSourceEditScreen from './screens/CrawlerSourceEditScreen';
import CrawlerSourceListingScreen from './screens/CrawlerSourceListingScreen';
import EventCreateScreen from './screens/EventCreateScreen';
import EventEditScreen from './screens/EventEditScreen';
import EventListingScreen from './screens/EventListingScreen';
import FundCreateScreen from './screens/FundCreateScreen';
import FundEditScreen from './screens/FundEditScreen';
import FundListingScreen from './screens/FundListingScreen';
import InvestorCreateScreen from './screens/InvestorCreateScreen';
import InvestorEditScreen from './screens/InvestorEditScreen';
import InvestorListingScreen from './screens/InvestorListingScreen';
import LogInScreen from './screens/LogInScreen';
import NewsletterCreateScreen from './screens/NewsletterCreateScreen';
import NewsletterEditScreen from './screens/NewsletterEditScreen';
import NewsletterListingScreen from './screens/NewsletterListingScreen';
import PEPFCreateScreen from './screens/PEPFCreateScreen';
import PEPFEditScreen from './screens/PEPFEditScreen';
import PEPFListingScreen from './screens/PEPFListingScreen';
import POTDCreateScreen from './screens/POTDCreateScreen';
import POTDEditScreen from './screens/POTDEditScreen';
import POTDListingScreen from './screens/POTDListingScreen';
import PeerGroupCreateScreen from './screens/PeerGroupCreateScreen';
import PeerGroupEditScreen from './screens/PeerGroupEditScreen';
import PeerGroupListingScreen from './screens/PeerGroupListingScreen';
import PropCaseListingScreen from './screens/PropCaseListingScreen';
import PropScheduleListingScreen from './screens/PropScheduleListingScreen';
import SplashScreen from './screens/SplashScreen';
import TrendingTopicListingScreen from './screens/TrendingTopicListingScreen';
import UsersClientsCreateScreen from './screens/UsersClientsCreateScreen';
import UsersClientsEditScreen from './screens/UsersClientsEditScreen';
import UsersClientsListingScreen from './screens/UsersClientsListingScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function AdvisorNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="AdvisorListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="AdvisorListingScreen"
        component={AdvisorListingScreen}
        options={({ navigation }) => ({
          title: 'Advisor - Listing',
        })}
      />
      <Stack.Screen
        name="AdvisorCreateScreen"
        component={AdvisorCreateScreen}
        options={({ navigation }) => ({
          title: 'Advisor - Create',
        })}
      />
      <Stack.Screen
        name="AdvisorEditScreen"
        component={AdvisorEditScreen}
        options={({ navigation }) => ({
          title: 'Advisor - Edit ',
        })}
      />
    </Stack.Navigator>
  );
}

function CFSNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="CFSListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="CFSListingScreen"
        component={CFSListingScreen}
        options={({ navigation }) => ({
          title: 'CFS - Listing',
        })}
      />
      <Stack.Screen
        name="CFSCreateScreen"
        component={CFSCreateScreen}
        options={({ navigation }) => ({
          title: 'CFS - Create',
        })}
      />
      <Stack.Screen
        name="CFSEditScreen"
        component={CFSEditScreen}
        options={({ navigation }) => ({
          title: 'CFS - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function CrawlerKeywordNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="CrawlerKeywordListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="CrawlerKeywordListingScreen"
        component={CrawlerKeywordListingScreen}
        options={({ navigation }) => ({
          title: 'Crawler Keyword - Listing',
        })}
      />
    </Stack.Navigator>
  );
}

function CrawlerResultNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="CrawlerResultListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="CrawlerResultListingScreen"
        component={CrawlerResultListingScreen}
        options={({ navigation }) => ({
          title: 'Crawler Result - Listing',
        })}
      />
    </Stack.Navigator>
  );
}

function CrawlerSourceNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="CrawlerSourceListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="CrawlerSourceListingScreen"
        component={CrawlerSourceListingScreen}
        options={({ navigation }) => ({
          title: 'Crawler Source - Listing',
        })}
      />
      <Stack.Screen
        name="CrawlerSourceCreateScreen"
        component={CrawlerSourceCreateScreen}
        options={({ navigation }) => ({
          title: 'Crawler Source - Create',
        })}
      />
      <Stack.Screen
        name="CrawlerSourceEditScreen"
        component={CrawlerSourceEditScreen}
        options={({ navigation }) => ({
          title: 'Crawler Source - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function EventNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="EventListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="EventListingScreen"
        component={EventListingScreen}
        options={({ navigation }) => ({
          title: 'Event - Listing',
        })}
      />
      <Stack.Screen
        name="EventCreateScreen"
        component={EventCreateScreen}
        options={({ navigation }) => ({
          title: 'Event - Create',
        })}
      />
      <Stack.Screen
        name="EventEditScreen"
        component={EventEditScreen}
        options={({ navigation }) => ({
          title: 'Event - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function FundNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="FundListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="FundListingScreen"
        component={FundListingScreen}
        options={({ navigation }) => ({
          title: 'Fund - Listing',
        })}
      />
      <Stack.Screen
        name="FundCreateScreen"
        component={FundCreateScreen}
        options={({ navigation }) => ({
          title: 'Fund - Create',
        })}
      />
      <Stack.Screen
        name="FundEditScreen"
        component={FundEditScreen}
        options={({ navigation }) => ({
          title: 'Fund - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function InvestorNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="InvestorListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="InvestorListingScreen"
        component={InvestorListingScreen}
        options={({ navigation }) => ({
          title: 'Investor - Listing',
        })}
      />
      <Stack.Screen
        name="InvestorCreateScreen"
        component={InvestorCreateScreen}
        options={({ navigation }) => ({
          title: 'Investor - Create',
        })}
      />
      <Stack.Screen
        name="InvestorEditScreen"
        component={InvestorEditScreen}
        options={({ navigation }) => ({
          title: 'Investor - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function NewsletterNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="NewsletterListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="NewsletterListingScreen"
        component={NewsletterListingScreen}
        options={({ navigation }) => ({
          title: 'Newsletter - Listing',
        })}
      />
      <Stack.Screen
        name="NewsletterEditScreen"
        component={NewsletterEditScreen}
        options={({ navigation }) => ({
          title: 'Newsletter - Edit',
        })}
      />
      <Stack.Screen
        name="NewsletterCreateScreen"
        component={NewsletterCreateScreen}
        options={({ navigation }) => ({
          title: 'Newsletter - Create',
        })}
      />
    </Stack.Navigator>
  );
}

function PEPFNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="PEPFListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="PEPFListingScreen"
        component={PEPFListingScreen}
        options={({ navigation }) => ({
          title: 'PEPF - Listing',
        })}
      />
      <Stack.Screen
        name="PEPFCreateScreen"
        component={PEPFCreateScreen}
        options={({ navigation }) => ({
          title: 'PEPF - Create',
        })}
      />
      <Stack.Screen
        name="PEPFEditScreen"
        component={PEPFEditScreen}
        options={({ navigation }) => ({
          title: 'PEPF - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function POTDNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="POTDListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="POTDListingScreen"
        component={POTDListingScreen}
        options={({ navigation }) => ({
          title: 'POTD - Listing',
        })}
      />
      <Stack.Screen
        name="POTDCreateScreen"
        component={POTDCreateScreen}
        options={({ navigation }) => ({
          title: 'POTD - Create',
        })}
      />
      <Stack.Screen
        name="POTDEditScreen"
        component={POTDEditScreen}
        options={({ navigation }) => ({
          title: 'POTD - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function PeerGroupNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="PeerGroupListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="PeerGroupListingScreen"
        component={PeerGroupListingScreen}
        options={({ navigation }) => ({
          title: 'Peer Group - Listing',
        })}
      />
      <Stack.Screen
        name="PeerGroupCreateScreen"
        component={PeerGroupCreateScreen}
        options={({ navigation }) => ({
          title: 'Peer Group - Create',
        })}
      />
      <Stack.Screen
        name="PeerGroupEditScreen"
        component={PeerGroupEditScreen}
        options={({ navigation }) => ({
          title: 'Peer Group - Edit',
        })}
      />
    </Stack.Navigator>
  );
}

function PropCaseNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="PropCaseListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="PropCaseListingScreen"
        component={PropCaseListingScreen}
        options={({ navigation }) => ({
          title: 'Prop Case - Listing',
        })}
      />
    </Stack.Navigator>
  );
}

function PropScheduleNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="PropScheduleListingScreen"
        component={PropScheduleListingScreen}
        options={({ navigation }) => ({
          title: 'Prop Schedule - Listing',
        })}
      />
    </Stack.Navigator>
  );
}

function TrendingTopicNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="TrendingTopicListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="TrendingTopicListingScreen"
        component={TrendingTopicListingScreen}
        options={({ navigation }) => ({
          title: 'Trending Topic - Listing',
        })}
      />
    </Stack.Navigator>
  );
}

function UsersClientsNavigator() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="UsersClientsListingScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="UsersClientsListingScreen"
        component={UsersClientsListingScreen}
        options={({ navigation }) => ({
          title: 'Users Clients - Listing',
        })}
      />
      <Stack.Screen
        name="UsersClientsCreateScreen"
        component={UsersClientsCreateScreen}
        options={({ navigation }) => ({
          title: 'Users Clients - Create',
        })}
      />
      <Stack.Screen
        name="UsersClientsEditScreen"
        component={UsersClientsEditScreen}
        options={({ navigation }) => ({
          title: 'Users Clients - Edit ',
        })}
      />
    </Stack.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#ffffffff',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="SplashScreen"
        screenOptions={({ navigation }) => ({
          cardStyle: { flex: 1 },
          headerLeft: ({ tintColor, canGoBack }) =>
            canGoBack ? null : (
              <Touchable
                style={[styles.headerContainer, styles.headerContainerLeft]}
                onPress={() => {
                  try {
                    if (Constants['top_nav_pressed'] === true) {
                      setGlobalVariableValue({
                        key: 'top_nav_pressed',
                        value: false,
                      });
                    } else {
                      setGlobalVariableValue({
                        key: 'top_nav_pressed',
                        value: true,
                      });
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon
                  name="MaterialCommunityIcons/menu"
                  size={Platform.OS === 'ios' ? 21 : 24}
                  color={theme.colors.text.strong}
                  style={[styles.headerIcon, styles.headerIconLeft]}
                />
              </Touchable>
            ),
          headerRight: ({ tintColor }) => (
            <Touchable
              style={[styles.headerContainer, styles.headerContainerRight]}
              onPress={() => {
                console.log('Dummy Component ON_RIGHT_ICON_PRESS Start');
                let error = null;
                try {
                  console.log('Start ON_RIGHT_ICON_PRESS:0 NAVIGATE');
                  /* hidden 'Navigate' action */ console.log(
                    'Complete ON_RIGHT_ICON_PRESS:0 NAVIGATE'
                  );
                  console.log('Start ON_RIGHT_ICON_PRESS:1 SET_VARIABLE');
                  /* hidden 'Set Variable' action */ console.log(
                    'Complete ON_RIGHT_ICON_PRESS:1 SET_VARIABLE'
                  );
                } catch (err) {
                  console.error(err);
                  error = err.message ?? err;
                }
                console.log(
                  'Dummy Component ON_RIGHT_ICON_PRESS Complete',
                  error ? { error } : 'no error'
                );
              }}
            >
              <Icon
                name=""
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors.text.strong}
                style={[styles.headerIcon, styles.headerIconRight]}
              />
            </Touchable>
          ),
          headerShown: false,
          headerStyle: {
            backgroundColor: theme.colors.background.brand,
            borderBottomColor: theme.colors.border.brand,
          },
          headerTintColor: theme.colors.text.strong,
          headerTitleStyle: { fontFamily: 'Quicksand_600SemiBold' },
        })}
      >
        <Stack.Screen
          name="LogInScreen"
          component={LogInScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'LogIn',
          })}
        />
        <Stack.Screen
          name="SplashScreen"
          component={SplashScreen}
          options={({ navigation }) => ({
            headerShown: false,
            headerTitleStyle: { fontFamily: 'Quicksand_400Regular' },
            title: 'Splash Screen',
          })}
        />
        <Stack.Screen
          name="AdvisorNavigator"
          component={AdvisorNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Advisor Navigator',
          })}
        />
        <Stack.Screen
          name="CFSNavigator"
          component={CFSNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'CFS Navigator',
          })}
        />
        <Stack.Screen
          name="CrawlerKeywordNavigator"
          component={CrawlerKeywordNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Crawler Keyword Navigator',
          })}
        />
        <Stack.Screen
          name="CrawlerResultNavigator"
          component={CrawlerResultNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Crawler Result Navigator',
          })}
        />
        <Stack.Screen
          name="CrawlerSourceNavigator"
          component={CrawlerSourceNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Crawler Source Navigator',
          })}
        />
        <Stack.Screen
          name="EventNavigator"
          component={EventNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Event Navigator',
          })}
        />
        <Stack.Screen
          name="FundNavigator"
          component={FundNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Fund Navigator',
          })}
        />
        <Stack.Screen
          name="InvestorNavigator"
          component={InvestorNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Investor Navigator',
          })}
        />
        <Stack.Screen
          name="NewsletterNavigator"
          component={NewsletterNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Newsletter Navigator',
          })}
        />
        <Stack.Screen
          name="PEPFNavigator"
          component={PEPFNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'PEPF Navigator',
          })}
        />
        <Stack.Screen
          name="POTDNavigator"
          component={POTDNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'POTD Navigator',
          })}
        />
        <Stack.Screen
          name="PeerGroupNavigator"
          component={PeerGroupNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Peer Group Navigator',
          })}
        />
        <Stack.Screen
          name="PropCaseNavigator"
          component={PropCaseNavigator}
          options={({ navigation }) => ({
            title: 'Prop Case Navigator',
          })}
        />
        <Stack.Screen
          name="PropScheduleNavigator"
          component={PropScheduleNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Prop Schedule Navigator',
          })}
        />
        <Stack.Screen
          name="TrendingTopicNavigator"
          component={TrendingTopicNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Trending Topic Navigator',
          })}
        />
        <Stack.Screen
          name="UsersClientsNavigator"
          component={UsersClientsNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Users Clients Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
});
