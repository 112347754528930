import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addNoteToPropCasePATCH = async (
  Constants,
  { note, prop_case_id, timestamp, user_admin_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case/note/${encodeQueryParam(
    prop_case_id
  )}`;
  const options = {
    body: JSON.stringify({
      timestamp: timestamp,
      note: note,
      user_admin_id: user_admin_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddNoteToPropCasePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addNoteToPropCasePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_case', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_case');
        queryClient.invalidateQueries('prop_cases');
      },
    }
  );
};

export const createAdvisorPOST = async (
  Constants,
  { name, type },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor`;
  const options = {
    body: JSON.stringify({ name: name, type: type }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateAdvisorPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createAdvisorPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const FetchCreateAdvisorPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  name,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdvisorPOST(
    { name, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAdvisor: refetch });
};

export const createCFSPOST = async (
  Constants,
  {
    advisors_cf,
    advisors_legal,
    company,
    company_description,
    country,
    currency,
    ebitda,
    event,
    first_added,
    fy_end,
    gics,
    growth,
    link_financials,
    logo,
    note_financials,
    other_events,
    owners,
    peer_group,
    pepf,
    revenue,
    source_type,
    stage,
    timeline,
    transacted,
    website,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs`;
  const options = {
    body: JSON.stringify({
      first_added: first_added,
      company: company,
      owners: owners,
      country: country,
      gics: gics,
      event: event,
      other_events: other_events,
      company_description: company_description,
      website: website,
      logo_url: logo,
      stage: stage,
      Timeline: timeline,
      pepf: pepf,
      peer_group: peer_group,
      source_type: source_type,
      revenue: revenue,
      ebitda: ebitda,
      growth: growth,
      fy_end: fy_end,
      currency: currency,
      link_financials: link_financials,
      note_financials: note_financials,
      advisors_cf: advisors_cf,
      advisors_legal: advisors_legal,
      transacted: transacted,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateCFSPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createCFSPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const FetchCreateCFSPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  advisors_cf,
  advisors_legal,
  company,
  company_description,
  country,
  currency,
  ebitda,
  event,
  first_added,
  fy_end,
  gics,
  growth,
  link_financials,
  logo,
  note_financials,
  other_events,
  owners,
  peer_group,
  pepf,
  revenue,
  source_type,
  stage,
  timeline,
  transacted,
  website,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCFSPOST(
    {
      advisors_cf,
      advisors_legal,
      company,
      company_description,
      country,
      currency,
      ebitda,
      event,
      first_added,
      fy_end,
      gics,
      growth,
      link_financials,
      logo,
      note_financials,
      other_events,
      owners,
      peer_group,
      pepf,
      revenue,
      source_type,
      stage,
      timeline,
      transacted,
      website,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateCFS: refetch });
};

export const createCrawlerSourcePOST = async (
  Constants,
  {
    base_url,
    crawl_attachments,
    in_production,
    language,
    source_name,
    user_email,
    user_password,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source`;
  const options = {
    body: JSON.stringify({
      in_production: in_production,
      source_name: source_name,
      base_url: base_url,
      user_email: user_email,
      user_password: user_password,
      language: language,
      crawl_attachments: crawl_attachments,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateCrawlerSourcePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createCrawlerSourcePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_source', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_source');
        queryClient.invalidateQueries('crawler_sources');
      },
    }
  );
};

export const FetchCreateCrawlerSourcePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  base_url,
  crawl_attachments,
  in_production,
  language,
  source_name,
  user_email,
  user_password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCrawlerSourcePOST(
    {
      base_url,
      crawl_attachments,
      in_production,
      language,
      source_name,
      user_email,
      user_password,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateCrawlerSource: refetch,
  });
};

export const createEventPOST = async (
  Constants,
  {
    buyer,
    buyside_cf,
    buyside_legal,
    country,
    currency_ev,
    currency_financials,
    description,
    ebit_local,
    ebitda_local,
    ev,
    ev_source,
    event_type,
    fy_end,
    gics,
    gross_profit_local,
    headline,
    include_in_multiples_db,
    link_to_financials,
    message_to_admin,
    note_financials,
    pefirm_behind_buyer,
    pepf,
    published,
    related_previous_coverage,
    revenue_local,
    reviewed_by,
    seller,
    sellside_cf,
    sellside_legal,
    source,
    source_link,
    source_type,
    status,
    strat_or_invf_buyer,
    strat_or_invf_seller,
    target,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event`;
  const options = {
    body: JSON.stringify({
      published: published,
      headline: headline,
      description: description,
      source: source,
      source_type: source_type,
      message_to_admin: message_to_admin,
      source_link: source_link,
      related_previous_coverage: related_previous_coverage,
      country: country,
      gics: gics,
      reviewed_by: reviewed_by,
      event_type: event_type,
      target: target,
      buyer: buyer,
      strat_or_invf_buyer: strat_or_invf_buyer,
      seller: seller,
      strat_or_invf_seller: strat_or_invf_seller,
      currency_financials: currency_financials,
      revenue_local: revenue_local,
      gross_profit_local: gross_profit_local,
      ebitda_local: ebitda_local,
      ebit_local: ebit_local,
      fy_end: fy_end,
      note_financials: note_financials,
      currency_ev: currency_ev,
      ev: ev,
      ev_source: ev_source,
      sellside_cf: sellside_cf,
      sellside_legal: sellside_legal,
      buyside_cf: buyside_cf,
      buyside_legal: buyside_legal,
      pepf: pepf,
      pefirm_behind_buyer: pefirm_behind_buyer,
      include_in_multiples_db: include_in_multiples_db,
      link_to_financials: link_to_financials,
      status: status,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateEventPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createEventPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const FetchCreateEventPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  buyer,
  buyside_cf,
  buyside_legal,
  country,
  currency_ev,
  currency_financials,
  description,
  ebit_local,
  ebitda_local,
  ev,
  ev_source,
  event_type,
  fy_end,
  gics,
  gross_profit_local,
  headline,
  include_in_multiples_db,
  link_to_financials,
  message_to_admin,
  note_financials,
  pefirm_behind_buyer,
  pepf,
  published,
  related_previous_coverage,
  revenue_local,
  reviewed_by,
  seller,
  sellside_cf,
  sellside_legal,
  source,
  source_link,
  source_type,
  status,
  strat_or_invf_buyer,
  strat_or_invf_seller,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateEventPOST(
    {
      buyer,
      buyside_cf,
      buyside_legal,
      country,
      currency_ev,
      currency_financials,
      description,
      ebit_local,
      ebitda_local,
      ev,
      ev_source,
      event_type,
      fy_end,
      gics,
      gross_profit_local,
      headline,
      include_in_multiples_db,
      link_to_financials,
      message_to_admin,
      note_financials,
      pefirm_behind_buyer,
      pepf,
      published,
      related_previous_coverage,
      revenue_local,
      reviewed_by,
      seller,
      sellside_cf,
      sellside_legal,
      source,
      source_link,
      source_type,
      status,
      strat_or_invf_buyer,
      strat_or_invf_seller,
      target,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateEvent: refetch });
};

export const createFundPOST = async (
  Constants,
  {
    country_hq,
    description,
    fund_size_currency,
    fund_size_local,
    horizon,
    name,
    pe_firm,
    type,
    vintage_date,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund`;
  const options = {
    body: JSON.stringify({
      name: name,
      description: description,
      pe_firm: pe_firm,
      fund_size_local: fund_size_local,
      fund_size_currency: fund_size_currency,
      country_hq: country_hq,
      type: type,
      horizon: horizon,
      vintage_date: vintage_date,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateFundPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createFundPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const FetchCreateFundPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  country_hq,
  description,
  fund_size_currency,
  fund_size_local,
  horizon,
  name,
  pe_firm,
  type,
  vintage_date,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFundPOST(
    {
      country_hq,
      description,
      fund_size_currency,
      fund_size_local,
      horizon,
      name,
      pe_firm,
      type,
      vintage_date,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateFund: refetch });
};

export const createInvestorPOST = async (
  Constants,
  { country_hq, name, type },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor`;
  const options = {
    body: JSON.stringify({ name: name, type: type, country_hq: country_hq }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateInvestorPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createInvestorPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const FetchCreateInvestorPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  country_hq,
  name,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateInvestorPOST(
    { country_hq, name, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateInvestor: refetch });
};

export const createPEPFPOST = async (
  Constants,
  {
    Company,
    acquired_date,
    buyside_cf_entry,
    buyside_legal_entry,
    company_description,
    company_id,
    country,
    currency_financials,
    ebit_local,
    ebitda_local,
    exit_date,
    financial_year,
    financials_comment,
    fund_entity,
    gics,
    gross_profit_local,
    growth,
    investment_responsible,
    pe_firm,
    revenue_local,
    stake_type,
    website,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf`;
  const options = {
    body: JSON.stringify({
      acquired_date: acquired_date,
      Company: Company,
      company_id: company_id,
      country: country,
      gics: gics,
      pe_firm: pe_firm,
      fund_entity: fund_entity,
      investment_responsible: investment_responsible,
      company_description: company_description,
      website: website,
      buyside_cf_entry: buyside_cf_entry,
      buyside_legal_entry: buyside_legal_entry,
      exit_date: exit_date,
      revenue_local: revenue_local,
      gross_profit_local: gross_profit_local,
      ebitda_local: ebitda_local,
      ebit_local: ebit_local,
      growth: growth,
      currency_financials: currency_financials,
      financial_year: financial_year,
      financials_comment: financials_comment,
      stake_type: stake_type,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreatePEPFPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createPEPFPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const FetchCreatePEPFPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Company,
  acquired_date,
  buyside_cf_entry,
  buyside_legal_entry,
  company_description,
  company_id,
  country,
  currency_financials,
  ebit_local,
  ebitda_local,
  exit_date,
  financial_year,
  financials_comment,
  fund_entity,
  gics,
  gross_profit_local,
  growth,
  investment_responsible,
  pe_firm,
  revenue_local,
  stake_type,
  website,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePEPFPOST(
    {
      Company,
      acquired_date,
      buyside_cf_entry,
      buyside_legal_entry,
      company_description,
      company_id,
      country,
      currency_financials,
      ebit_local,
      ebitda_local,
      exit_date,
      financial_year,
      financials_comment,
      fund_entity,
      gics,
      gross_profit_local,
      growth,
      investment_responsible,
      pe_firm,
      revenue_local,
      stake_type,
      website,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePEPF: refetch });
};

export const createPOTDPOST = async (
  Constants,
  {
    advisor,
    created_at,
    financials,
    gics,
    headline,
    hq,
    peer_group,
    stage,
    story_company_profile,
    story_comps,
    story_opportunity,
    target,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd`;
  const options = {
    body: JSON.stringify({
      created_at: created_at,
      headline: headline,
      story_company_profile: story_company_profile,
      story_opportunity: story_opportunity,
      story_comps: story_comps,
      target: target,
      financials: financials,
      advisor: advisor,
      stage: stage,
      hq: hq,
      peer_group: peer_group,
      gics: gics,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreatePOTDPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createPOTDPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const FetchCreatePOTDPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  advisor,
  created_at,
  financials,
  gics,
  headline,
  hq,
  peer_group,
  stage,
  story_company_profile,
  story_comps,
  story_opportunity,
  target,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePOTDPOST(
    {
      advisor,
      created_at,
      financials,
      gics,
      headline,
      hq,
      peer_group,
      stage,
      story_company_profile,
      story_comps,
      story_opportunity,
      target,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePOTD: refetch });
};

export const createPeerGroupPOST = async (
  Constants,
  { access_type, created_by, description, title },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group`;
  const options = {
    body: JSON.stringify({
      title: title,
      description: description,
      access_type: access_type,
      created_by: created_by,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreatePeerGroupPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createPeerGroupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const FetchCreatePeerGroupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  access_type,
  created_by,
  description,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePeerGroupPOST(
    { access_type, created_by, description, title },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePeerGroup: refetch });
};

export const createUsersClientsPOST = async (
  Constants,
  {
    Initial_Login_Complete,
    accessible_regions,
    account_activated_on,
    company,
    company_type,
    country,
    email,
    email_notification_dach,
    email_notification_nordic,
    expo_token,
    google_oauth,
    job_title,
    knack_id,
    last_login_android_app,
    last_login_ios_app,
    last_login_web_app,
    name,
    nordic_nl_section_1,
    nordic_nl_section_2,
    nordic_nl_section_3,
    nordic_nl_section_4,
    paid_dach,
    paid_nordic,
    password,
    push_notification_nl_dach,
    push_notification_nl_nordic,
    region,
    screenshot_notification_shown,
    temp_login,
    trial_end,
    trial_start,
    user_status,
    welcome_reminder_sent_on,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients`;
  const options = {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      country: country,
      accessible_regions: accessible_regions,
      job_title: job_title,
      company: company,
      company_type: company_type,
      trial_start: trial_start,
      trial_end: trial_end,
      paid_dach: paid_dach,
      paid_nordic: paid_nordic,
      user_status: user_status,
      Initial_Login_Complete: Initial_Login_Complete,
      push_notification_nl_nordic: push_notification_nl_nordic,
      push_notification_nl_dach: push_notification_nl_dach,
      welcome_reminder_sent_on: welcome_reminder_sent_on,
      nordic_nl_section_1: nordic_nl_section_1,
      nordic_nl_section_2: nordic_nl_section_2,
      nordic_nl_section_3: nordic_nl_section_3,
      nordic_nl_section_4: nordic_nl_section_4,
      email_notification_nordic: email_notification_nordic,
      email_notification_dach: email_notification_dach,
      region: region,
      google_oauth: google_oauth,
      last_login_web_app: last_login_web_app,
      last_login_android_app: last_login_android_app,
      last_login_ios_app: last_login_ios_app,
      temp_login: temp_login,
      knack_id: knack_id,
      account_activated_on: account_activated_on,
      expo_token: expo_token,
      screenshot_notification_shown: screenshot_notification_shown,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateUsersClientsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createUsersClientsPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const FetchCreateUsersClientsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Initial_Login_Complete,
  accessible_regions,
  account_activated_on,
  company,
  company_type,
  country,
  email,
  email_notification_dach,
  email_notification_nordic,
  expo_token,
  google_oauth,
  job_title,
  knack_id,
  last_login_android_app,
  last_login_ios_app,
  last_login_web_app,
  name,
  nordic_nl_section_1,
  nordic_nl_section_2,
  nordic_nl_section_3,
  nordic_nl_section_4,
  paid_dach,
  paid_nordic,
  password,
  push_notification_nl_dach,
  push_notification_nl_nordic,
  region,
  screenshot_notification_shown,
  temp_login,
  trial_end,
  trial_start,
  user_status,
  welcome_reminder_sent_on,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateUsersClientsPOST(
    {
      Initial_Login_Complete,
      accessible_regions,
      account_activated_on,
      company,
      company_type,
      country,
      email,
      email_notification_dach,
      email_notification_nordic,
      expo_token,
      google_oauth,
      job_title,
      knack_id,
      last_login_android_app,
      last_login_ios_app,
      last_login_web_app,
      name,
      nordic_nl_section_1,
      nordic_nl_section_2,
      nordic_nl_section_3,
      nordic_nl_section_4,
      paid_dach,
      paid_nordic,
      password,
      push_notification_nl_dach,
      push_notification_nl_nordic,
      region,
      screenshot_notification_shown,
      temp_login,
      trial_end,
      trial_start,
      user_status,
      welcome_reminder_sent_on,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateUsersClients: refetch });
};

export const createNewsletterPOST = async (
  Constants,
  {
    date,
    events_dach_large_cap_ids,
    events_dach_mid_ids,
    events_dach_small_ids,
    events_dk_ids,
    events_fi_ids,
    events_no_ids,
    events_se_ids,
    potd,
    status,
    teaser,
    title,
    version,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter`;
  const options = {
    body: JSON.stringify({
      version: version,
      title: title,
      date: date,
      teaser: teaser,
      status: status,
      potd: potd,
      events_dk: events_dk_ids,
      events_se: events_se_ids,
      events_no: events_no_ids,
      events_fi: events_fi_ids,
      events_dach_large_cap: events_dach_large_cap_ids,
      events_dach_mid: events_dach_mid_ids,
      events_dach_small: events_dach_small_ids,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateNewsletterPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createNewsletterPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};

export const FetchCreateNewsletterPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  date,
  events_dach_large_cap_ids,
  events_dach_mid_ids,
  events_dach_small_ids,
  events_dk_ids,
  events_fi_ids,
  events_no_ids,
  events_se_ids,
  potd,
  status,
  teaser,
  title,
  version,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateNewsletterPOST(
    {
      date,
      events_dach_large_cap_ids,
      events_dach_mid_ids,
      events_dach_small_ids,
      events_dk_ids,
      events_fi_ids,
      events_no_ids,
      events_se_ids,
      potd,
      status,
      teaser,
      title,
      version,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateNewsletter: refetch });
};

export const deleteAdvisorDELETE = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAdvisorDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAdvisorDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const deleteCFSDELETE = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteCFSDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteCFSDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const deleteEventDELETE = async (
  Constants,
  { event_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    event_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteEventDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteEventDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const deleteFundDELETE = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteFundDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteFundDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const deleteInvestorDELETE = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteInvestorDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteInvestorDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const deleteNewsletterDELETE = async (
  Constants,
  { newsletter_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    newsletter_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteNewsletterDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteNewsletterDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};

export const deletePEPFDELETE = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePEPFDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deletePEPFDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const deletePOTDDELETE = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePOTDDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deletePOTDDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const deletePeerGroupDELETE = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePeerGroupDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePeerGroupDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const deletePropCaseDELETE = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePropCaseDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePropCaseDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_case', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_case');
        queryClient.invalidateQueries('prop_cases');
      },
    }
  );
};

export const deleteUsersClientsDELETE = async (
  Constants,
  { users_clients_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteUsersClientsDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUsersClientsDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const editEventPATCH = async (
  Constants,
  {
    buyer,
    buyside_cf,
    buyside_legal,
    country,
    currency_ev,
    currency_financials,
    description,
    ebit_local,
    ebitda_local,
    ev,
    ev_source,
    event_type,
    fy_end,
    gics,
    gross_profit_local,
    headline,
    id,
    include_in_multiples_db,
    link_to_financials,
    note_financials,
    pefirm_behind_buyer,
    pepf,
    published,
    revenue_local,
    reviewed_by,
    seller,
    sellside_cf,
    sellside_legal,
    source,
    source_link,
    source_type,
    status,
    strat_or_invf_buyer,
    strat_or_invf_seller,
    target,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    id
  )}`;
  const options = {
    body: JSON.stringify({
      published: published,
      headline: headline,
      description: description,
      source: source,
      source_type: source_type,
      source_link: source_link,
      country: country,
      gics: gics,
      reviewed_by: reviewed_by,
      event_type: event_type,
      target: target,
      buyer: buyer,
      strat_or_invf_buyer: strat_or_invf_buyer,
      seller: seller,
      strat_or_invf_seller: strat_or_invf_seller,
      currency_financials: currency_financials,
      revenue_local: revenue_local,
      gross_profit_local: gross_profit_local,
      ebitda_local: ebitda_local,
      ebit_local: ebit_local,
      fy_end: fy_end,
      note_financials: note_financials,
      currency_ev: currency_ev,
      ev: ev,
      ev_source: ev_source,
      sellside_cf: sellside_cf,
      sellside_legal: sellside_legal,
      buyside_cf: buyside_cf,
      buyside_legal: buyside_legal,
      pepf: pepf,
      pefirm_behind_buyer: pefirm_behind_buyer,
      include_in_multiples_db: include_in_multiples_db,
      link_to_financials: link_to_financials,
      status: status,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useEditEventPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editEventPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('event', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('event');
        queryClient.invalidateQueries('events');
      },
    }
  );
};

export const getAdvisorGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAdvisorGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['advisor', args],
    () => getAdvisorGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['advisors']),
    }
  );
};

export const FetchGetAdvisorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdvisorGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdvisor: refetch });
};

export const getAdvisorsGET = async (
  Constants,
  { keyword, page, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAdvisorsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['advisors', args],
    () => getAdvisorsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAdvisorsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdvisorsGET(
    { keyword, page, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdvisors: refetch });
};

export const getCFSGET = async (
  Constants,
  { keyword_search, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCFSGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['cfs', args], () => getCFSGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetCFSGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCFSGET(
    { keyword_search, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCFS: refetch });
};

export const getCFSDetailGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCFSDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['cf', args],
    () => getCFSDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['cfs']),
    }
  );
};

export const FetchGetCFSDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCFSDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCFSDetail: refetch });
};

export const getCrawlerKeywordGET = async (
  Constants,
  { keyword_search, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_keyword${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCrawlerKeywordGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_keywords', args],
    () => getCrawlerKeywordGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCrawlerKeywordGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerKeywordGET(
    { keyword_search, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerKeyword: refetch });
};

export const getCrawlerResultGET = async (
  Constants,
  { keyword_search, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_result${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCrawlerResultGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_results', args],
    () => getCrawlerResultGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCrawlerResultGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerResultGET(
    { keyword_search, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerResult: refetch });
};

export const getCrawlerResultDetailGET = async (
  Constants,
  { crawler_result_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_result/${encodeQueryParam(
    crawler_result_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCrawlerResultDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['crawler_result', args],
    () => getCrawlerResultDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['crawler_results']),
    }
  );
};

export const FetchGetCrawlerResultDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  crawler_result_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerResultDetailGET(
    { crawler_result_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetCrawlerResultDetail: refetch,
  });
};

export const getCrawlerSourceGET = async (
  Constants,
  { page },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCrawlerSourceGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['crawler_sources', args],
    () => getCrawlerSourceGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCrawlerSourceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerSourceGET(
    { page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCrawlerSource: refetch });
};

export const getCrawlerSourceDetailGET = async (
  Constants,
  { crawler_source_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source/${encodeQueryParam(
    crawler_source_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCrawlerSourceDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['crawler_source', args],
    () => getCrawlerSourceDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['crawler_sources']),
    }
  );
};

export const FetchGetCrawlerSourceDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  crawler_source_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCrawlerSourceDetailGET(
    { crawler_source_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetCrawlerSourceDetail: refetch,
  });
};

export const getEventGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetEventGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['event', args],
    () => getEventGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['events']),
    }
  );
};

export const FetchGetEventGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEventGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetEvent: refetch });
};

export const getEventsGET = async (
  Constants,
  {
    country,
    date_range,
    gics_sub_industry,
    keyword,
    page,
    per_page,
    region,
    status,
  },
  handlers = {}
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (status !== undefined) {
    paramsDict['status'] = status;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  if (region !== undefined) {
    paramsDict['region'] = region;
  }
  if (gics_sub_industry !== undefined) {
    paramsDict['gics_sub_industry'] = gics_sub_industry;
  }
  if (date_range !== undefined) {
    paramsDict['date_range'] = date_range;
  }
  if (region !== undefined) {
    paramsDict['region'] = region;
  }
  if (country !== undefined) {
    paramsDict['country'] = country;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/event${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetEventsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['events', args],
    () => getEventsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetEventsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  country,
  date_range,
  gics_sub_industry,
  keyword,
  page,
  per_page,
  region,
  status,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEventsGET(
    {
      country,
      date_range,
      gics_sub_industry,
      keyword,
      page,
      per_page,
      region,
      status,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetEvents: refetch });
};

export const getFundGET = async (
  Constants,
  { keyword, page, pe_firm, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (pe_firm !== undefined) {
    paramsDict['pe_firm'] = pe_firm;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetFundGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['funds', args],
    () => getFundGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFundGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  page,
  pe_firm,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFundGET(
    { keyword, page, pe_firm, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFund: refetch });
};

export const getFundDetailGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetFundDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['fund', args],
    () => getFundDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['funds']),
    }
  );
};

export const FetchGetFundDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFundDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFundDetail: refetch });
};

export const getGicsSubIndustryGET = async (
  Constants,
  { keyword },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/gics_sub_industry${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetGicsSubIndustryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['gics_sub_industries', args],
    () => getGicsSubIndustryGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetGicsSubIndustryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetGicsSubIndustryGET(
    { keyword },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetGicsSubIndustry: refetch });
};

export const getInvestorGET = async (
  Constants,
  { investor_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    investor_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetInvestorGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['investor', args],
    () => getInvestorGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['investors']),
    }
  );
};

export const FetchGetInvestorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  investor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInvestorGET(
    { investor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInvestor: refetch });
};

export const getInvestorsGET = async (
  Constants,
  { country_hq, keyword, page, per_page, type },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (type !== undefined) {
    paramsDict['type'] = type;
  }
  if (country_hq !== undefined) {
    paramsDict['country_hq'] = country_hq;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetInvestorsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['investors', args],
    () => getInvestorsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetInvestorsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  country_hq,
  keyword,
  page,
  per_page,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInvestorsGET(
    { country_hq, keyword, page, per_page, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInvestors: refetch });
};

export const getMeGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/auth/me`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['me', args], () => getMeGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['us']),
  });
};

export const FetchGetMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetMe: refetch });
};

export const getNewsletterDetailGET = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetNewsletterDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newletter', args],
    () => getNewsletterDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['newletters']),
    }
  );
};

export const FetchGetNewsletterDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNewsletterDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetNewsletterDetail: refetch,
  });
};

export const getNewslettersGET = async (
  Constants,
  { dach, keyword, newsletter, nordic, page, weekly_report },
  handlers = {}
) => {
  const paramsDict = {};
  if (nordic !== undefined) {
    paramsDict['nordic'] = nordic;
  }
  if (dach !== undefined) {
    paramsDict['dach'] = dach;
  }
  if (newsletter !== undefined) {
    paramsDict['newsletter'] = newsletter;
  }
  if (weekly_report !== undefined) {
    paramsDict['weekly_report'] = weekly_report;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetNewslettersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['newletters', args],
    () => getNewslettersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetNewslettersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dach,
  keyword,
  newsletter,
  nordic,
  page,
  weekly_report,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNewslettersGET(
    { dach, keyword, newsletter, nordic, page, weekly_report },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNewsletters: refetch });
};

export const getPEPFDetailGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPEPFDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['pepf', args],
    () => getPEPFDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['pepfs']),
    }
  );
};

export const FetchGetPEPFDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPEPFDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPEPFDetail: refetch });
};

export const getPOTDGET = async (
  Constants,
  { keyword, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPOTDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['potds', args],
    () => getPOTDGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPOTDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPOTDGET(
    { keyword, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPOTD: refetch });
};

export const getPOTDDetailGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPOTDDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['potd', args],
    () => getPOTDDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['potds']),
    }
  );
};

export const FetchGetPOTDDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPOTDDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPOTDDetail: refetch });
};

export const getPeerGroupGET = async (
  Constants,
  { created_by, keyword, page, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (created_by !== undefined) {
    paramsDict['created_by'] = created_by;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPeerGroupGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['peer_groups', args],
    () => getPeerGroupGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPeerGroupGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  created_by,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPeerGroupGET(
    { created_by, keyword, page, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPeerGroup: refetch });
};

export const getPeerGroupDetailGET = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPeerGroupDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['peer_group', args],
    () => getPeerGroupDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['peer_groups']),
    }
  );
};

export const FetchGetPeerGroupDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPeerGroupDetailGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPeerGroupDetail: refetch });
};

export const getPepfGET = async (
  Constants,
  { keyword, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPepfGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['pepfs', args],
    () => getPepfGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPepfGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPepfGET(
    { keyword, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPepf: refetch });
};

export const getPropCaseGET = async (
  Constants,
  { keyword, page, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_case${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPropCaseGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['prop_cases', args],
    () => getPropCaseGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPropCaseGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPropCaseGET(
    { keyword, page, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPropCase: refetch });
};

export const getPropScheduleGET = async (
  Constants,
  { keyword_search, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_schedule${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPropScheduleGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['prop_schedules', args],
    () => getPropScheduleGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPropScheduleGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPropScheduleGET(
    { keyword_search, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPropSchedule: refetch });
};

export const getTrendingTopicGET = async (
  Constants,
  { keyword_search, page },
  handlers = {}
) => {
  const paramsDict = {};
  if (keyword_search !== undefined) {
    paramsDict['keyword_search'] = keyword_search;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/trending_topic${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTrendingTopicGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['trending_topics', args],
    () => getTrendingTopicGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetTrendingTopicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword_search,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTrendingTopicGET(
    { keyword_search, page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTrendingTopic: refetch });
};

export const getUserAdminGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/user_admin`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUserAdminGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['user_admins', args],
    () => getUserAdminGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserAdminGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserAdminGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserAdmin: refetch });
};

export const getUsersClientsGET = async (
  Constants,
  { keyword, only_nkp, page, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (only_nkp !== undefined) {
    paramsDict['only_nkp'] = only_nkp;
  }
  if (per_page !== undefined) {
    paramsDict['per_page'] = per_page;
  }
  if (keyword !== undefined) {
    paramsDict['keyword'] = keyword;
  }
  if (page !== undefined) {
    paramsDict['page'] = page;
  }
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUsersClientsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['users_clients', args],
    () => getUsersClientsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUsersClientsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  only_nkp,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersClientsGET(
    { keyword, only_nkp, page, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUsersClients: refetch });
};

export const getUsersClientsDetailGET = async (
  Constants,
  { users_clients_id },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUsersClientsDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['users_client', args],
    () => getUsersClientsDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['users_clients']),
    }
  );
};

export const FetchGetUsersClientsDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  users_clients_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersClientsDetailGET(
    { users_clients_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUsersClientsDetail: refetch,
  });
};

export const loginAdminPOST = async (
  Constants,
  { email, password },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/auth/login`;
  const options = {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginAdminPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user', args],
    () => loginAdminPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['users']),
    }
  );
};

export const FetchLoginAdminPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginAdminPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginAdmin: refetch });
};

export const sendNotificationForScreenshotPOST = async (
  Constants,
  { details, email, name, ts },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/screenshot_notification`;
  const options = {
    body: JSON.stringify({
      user_name: name,
      user_email: email,
      taken_at: ts,
      screenshot_details: details,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendNotificationForScreenshotPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      sendNotificationForScreenshotPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('notification', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('notification');
        queryClient.invalidateQueries('notifications');
      },
    }
  );
};

export const FetchSendNotificationForScreenshotPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  details,
  email,
  name,
  ts,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendNotificationForScreenshotPOST(
    { details, email, name, ts },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendNotificationForScreenshot: refetch,
  });
};

export const updateAdvisorPATCH = async (
  Constants,
  { advisor_id, name, type },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/advisor/${encodeQueryParam(
    advisor_id
  )}`;
  const options = {
    body: JSON.stringify({ name: name, type: type }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateAdvisorPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateAdvisorPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('advisor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('advisor');
        queryClient.invalidateQueries('advisors');
      },
    }
  );
};

export const updateCFSPATCH = async (
  Constants,
  {
    advisors_cf,
    advisors_legal,
    company,
    company_description,
    country,
    currency,
    ebitda,
    event,
    first_added,
    fy_end,
    gics,
    growth,
    id,
    link_financials,
    logo,
    note_financials,
    other_events,
    owners,
    peer_group,
    pepf,
    revenue,
    source_type,
    stage,
    timeline,
    transacted,
    website,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/cfs/${encodeQueryParam(
    id
  )}`;
  const options = {
    body: JSON.stringify({
      first_added: first_added,
      company: company,
      owners: owners,
      country: country,
      gics: gics,
      event: event,
      other_events: other_events,
      company_description: company_description,
      website: website,
      logo: logo,
      stage: stage,
      Timeline: timeline,
      pepf: pepf,
      peer_group: peer_group,
      source_type: source_type,
      revenue: revenue,
      ebitda: ebitda,
      growth: growth,
      fy_end: fy_end,
      currency: currency,
      link_financials: link_financials,
      note_financials: note_financials,
      advisors_cf: advisors_cf,
      advisors_legal: advisors_legal,
      transacted: transacted,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateCFSPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateCFSPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('cfs', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('cf');
        queryClient.invalidateQueries('cfs');
      },
    }
  );
};

export const updateCrawlerKeywordPATCH = async (
  Constants,
  { crawler_keyword_id, language, search_pdfs, string_ },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_keyword/${encodeQueryParam(
    crawler_keyword_id
  )}`;
  const options = {
    body: JSON.stringify({
      string: string_,
      language: language,
      search_pdfs: search_pdfs,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateCrawlerKeywordPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateCrawlerKeywordPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_keyword', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_keyword');
        queryClient.invalidateQueries('crawler_keywords');
      },
    }
  );
};

export const updateCrawlerSourcePATCH = async (
  Constants,
  {
    base_url,
    crawl_attachments,
    crawler_source_id,
    in_production,
    language,
    source_name,
    user_email,
    user_password,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/crawler_source/${encodeQueryParam(
    crawler_source_id
  )}`;
  const options = {
    body: JSON.stringify({
      in_production: in_production,
      source_name: source_name,
      base_url: base_url,
      user_email: user_email,
      user_password: user_password,
      language: language,
      crawl_attachments: crawl_attachments,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateCrawlerSourcePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateCrawlerSourcePATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('crawler_source', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('crawler_source');
        queryClient.invalidateQueries('crawler_sources');
      },
    }
  );
};

export const updateFundPATCH = async (
  Constants,
  {
    country_hq,
    description,
    fund_id,
    fund_size_currency,
    fund_size_local,
    horizon,
    name,
    pe_firm,
    type,
    vintage_date,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/fund/${encodeQueryParam(
    fund_id
  )}`;
  const options = {
    body: JSON.stringify({
      name: name,
      description: description,
      pe_firm: pe_firm,
      fund_size_local: fund_size_local,
      fund_size_currency: fund_size_currency,
      country_hq: country_hq,
      type: type,
      horizon: horizon,
      vintage_date: vintage_date,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateFundPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateFundPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('fund', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('fund');
        queryClient.invalidateQueries('funds');
      },
    }
  );
};

export const updateInvestorPATCH = async (
  Constants,
  { country_hq, investor_id, name, type },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/investor/${encodeQueryParam(
    investor_id
  )}`;
  const options = {
    body: JSON.stringify({ name: name, type: type, country_hq: country_hq }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateInvestorPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateInvestorPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('investor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('investor');
        queryClient.invalidateQueries('investors');
      },
    }
  );
};

export const updatePEPFPATCH = async (
  Constants,
  {
    Company,
    acquired_date,
    buyside_cf_entry,
    buyside_legal_entry,
    company_description,
    company_id,
    country,
    currency_financials,
    ebit_local,
    ebitda_local,
    exit_date,
    financial_year,
    financials_comment,
    fund_entity,
    gics,
    gross_profit_local,
    growth,
    id,
    investment_responsible,
    pe_firm,
    revenue_local,
    stake_type,
    website,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/pepf/${encodeQueryParam(
    id
  )}`;
  const options = {
    body: JSON.stringify({
      acquired_date: acquired_date,
      Company: Company,
      company_id: company_id,
      country: country,
      gics: gics,
      pe_firm: pe_firm,
      fund_entity: fund_entity,
      investment_responsible: investment_responsible,
      company_description: company_description,
      website: website,
      buyside_cf_entry: buyside_cf_entry,
      buyside_legal_entry: buyside_legal_entry,
      exit_date: exit_date,
      revenue_local: revenue_local,
      gross_profit_local: gross_profit_local,
      ebitda_local: ebitda_local,
      ebit_local: ebit_local,
      growth: growth,
      currency_financials: currency_financials,
      financial_year: financial_year,
      financials_comment: financials_comment,
      stake_type: stake_type,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePEPFPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updatePEPFPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('pepf', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('pepf');
        queryClient.invalidateQueries('pepfs');
      },
    }
  );
};

export const updatePOTDPATCH = async (
  Constants,
  {
    advisor,
    created_at,
    financials,
    gics,
    headline,
    hq,
    id,
    peer_group,
    stage,
    story_company_profile,
    story_comps,
    story_opportunity,
    target,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/potd/${encodeQueryParam(
    id
  )}`;
  const options = {
    body: JSON.stringify({
      created_at: created_at,
      headline: headline,
      story_company_profile: story_company_profile,
      story_opportunity: story_opportunity,
      story_comps: story_comps,
      target: target,
      financials: financials,
      advisor: advisor,
      stage: stage,
      hq: hq,
      peer_group: peer_group,
      gics: gics,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePOTDPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updatePOTDPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('potd', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('potd');
        queryClient.invalidateQueries('potds');
      },
    }
  );
};

export const updatePeerGroupPATCH = async (
  Constants,
  { access_type, created_by, description, peer_group_id, title },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/peer_group/${encodeQueryParam(
    peer_group_id
  )}`;
  const options = {
    body: JSON.stringify({
      title: title,
      description: description,
      created_by: created_by,
      access_type: access_type,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePeerGroupPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePeerGroupPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('peer_group', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('peer_group');
        queryClient.invalidateQueries('peer_groups');
      },
    }
  );
};

export const updatePropSchedulePATCH = async (
  Constants,
  { note, potd_dach, potd_nordic, prop_schedule_id, reg_dach, reg_nordic },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/prop_schedule/${encodeQueryParam(
    prop_schedule_id
  )}`;
  const options = {
    body: JSON.stringify({
      note: note,
      potd_nordic: potd_nordic,
      reg_nordic: reg_nordic,
      potd_dach: potd_dach,
      reg_dach: reg_dach,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePropSchedulePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePropSchedulePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('prop_schedule', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('prop_schedule');
        queryClient.invalidateQueries('prop_schedules');
      },
    }
  );
};

export const updateUsersClientsPATCH = async (
  Constants,
  {
    Initial_Login_Complete,
    accessible_regions,
    account_activated_on,
    company,
    company_type,
    country,
    email,
    email_notification_dach,
    email_notification_nordic,
    expo_token,
    google_oauth,
    job_title,
    knack_id,
    last_login_android_app,
    last_login_ios_app,
    last_login_web_app,
    name,
    nordic_nl_section_1,
    nordic_nl_section_2,
    nordic_nl_section_3,
    nordic_nl_section_4,
    paid_dach,
    paid_nordic,
    password,
    push_notification_nl_dach,
    push_notification_nl_nordic,
    region,
    screenshot_notification_shown,
    temp_login,
    trial_end,
    trial_start,
    user_status,
    users_clients_id,
    welcome_reminder_sent_on,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/users_clients/${encodeQueryParam(
    users_clients_id
  )}`;
  const options = {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      country: country,
      accessible_regions: accessible_regions,
      job_title: job_title,
      company: company,
      company_type: company_type,
      trial_start: trial_start,
      trial_end: trial_end,
      paid_dach: paid_dach,
      paid_nordic: paid_nordic,
      user_status: user_status,
      Initial_Login_Complete: Initial_Login_Complete,
      push_notification_nl_nordic: push_notification_nl_nordic,
      push_notification_nl_dach: push_notification_nl_dach,
      welcome_reminder_sent_on: welcome_reminder_sent_on,
      nordic_nl_section_1: nordic_nl_section_1,
      nordic_nl_section_2: nordic_nl_section_2,
      nordic_nl_section_3: nordic_nl_section_3,
      nordic_nl_section_4: nordic_nl_section_4,
      email_notification_nordic: email_notification_nordic,
      email_notification_dach: email_notification_dach,
      region: region,
      google_oauth: google_oauth,
      last_login_web_app: last_login_web_app,
      last_login_android_app: last_login_android_app,
      last_login_ios_app: last_login_ios_app,
      temp_login: temp_login,
      knack_id: knack_id,
      account_activated_on: account_activated_on,
      expo_token: expo_token,
      screenshot_notification_shown: screenshot_notification_shown,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateUsersClientsPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUsersClientsPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('users_clients', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('users_client');
        queryClient.invalidateQueries('users_clients');
      },
    }
  );
};

export const updateNewsletterPATCH = async (
  Constants,
  {
    date,
    events_dach_large_cap_ids,
    events_dach_mid_ids,
    events_dach_small_ids,
    events_dk_ids,
    events_fi_ids,
    events_no_ids,
    events_se_ids,
    id,
    potd,
    status,
    teaser,
    title,
    version,
  },
  handlers = {}
) => {
  const url = `https://xne3-pdiu-8ysm.f2.xano.io/api:Y4gKH4tE/newsletter/${encodeQueryParam(
    id
  )}`;
  const options = {
    body: JSON.stringify({
      version: version,
      title: title,
      date: date,
      teaser: teaser,
      status: status,
      potd: potd,
      events_dk: events_dk_ids,
      events_se: events_se_ids,
      events_no: events_no_ids,
      events_fi: events_fi_ids,
      events_dach_large_cap: events_dach_large_cap_ids,
      events_dach_mid: events_dach_mid_ids,
      events_dach_small: events_dach_small_ids,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
      'X-Data-Source': 'test',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateNewsletterPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateNewsletterPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('newletters', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('newletter');
        queryClient.invalidateQueries('newletters');
      },
    }
  );
};
