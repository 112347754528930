const getColorForStage = val => {
  if (val === 'Completed') {
    return '#15803DFF';
  } else if (val === 'Coming') {
    return '#919950FF';
  } else if (val === 'Paused') {
    return '#CA8A04FF';
  } else if (val === 'Live') {
    return '#AD0739FF';
  } else {
    return '#262B2FFF';
  }
};

export default getColorForStage;
