const customFormatDate = date => {
  if (date === null || date === undefined || date === '') {
    return null;
  }
  const dateData = new Date(date);
  console.log(dateData);
  return dateData;
};

export default customFormatDate;
