import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';

const Index = ({
  options,
  selectedOptions = [],
  onChange,
  minWidth = 300,
  maxWidth = 300,
  placeholder = 'Select options',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownLayout, setDropdownLayout] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const pickerRef = useRef(null);
  const [localSelectedOptions, setLocalSelectedOptions] = useState(
    options.filter(option => selectedOptions.includes(option.value))
  );

  useEffect(() => {
    setLocalSelectedOptions(
      options.filter(option => selectedOptions.includes(option.value))
    );
  }, [selectedOptions, options]);

  const toggleDropdown = useCallback(() => {
    if (!isOpen) {
      pickerRef.current.measure((x, y, width, height, pageX, pageY) => {
        setDropdownLayout({ width, height, pageX, pageY: pageY + height });
      });
    }
    setIsOpen(prev => !prev);
  }, [isOpen]);

  const handleOptionPress = option => {
    let newSelectedOptions;
    if (localSelectedOptions.some(item => item.value === option.value)) {
      newSelectedOptions = localSelectedOptions.filter(
        item => item.value !== option.value
      );
    } else {
      newSelectedOptions = [...localSelectedOptions, option];
    }
    setLocalSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions.map(item => item.value));
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderOption = ({ item }) => {
    const isSelected = localSelectedOptions.some(
      option => option.value === item.value
    );
    return (
      <TouchableOpacity
        style={styles.option}
        onPress={() => handleOptionPress(item)}
      >
        <Text style={styles.optionText}>{item.label}</Text>
        <Ionicons
          name="checkmark"
          size={24}
          color={isSelected ? 'green' : 'transparent'}
        />
      </TouchableOpacity>
    );
  };

  const handleOutsidePress = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleRemoveTag = option => {
    const newSelectedOptions = localSelectedOptions.filter(
      item => item.value !== option.value
    );
    setLocalSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions.map(item => item.value));
  };

  const handleReset = () => {
    setLocalSelectedOptions([]);
    onChange([]);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        ref={pickerRef}
        onPress={toggleDropdown}
        style={[styles.picker, minWidth ? { minWidth } : null]}
        activeOpacity={1}
      >
        <View style={styles.tagsContainer}>
          {localSelectedOptions.length > 0 ? (
            localSelectedOptions.map(option => (
              <TouchableOpacity
                key={option.value}
                style={styles.tag}
                onPress={() => handleRemoveTag(option)}
              >
                <Text style={styles.tagText}>{option.label}</Text>
                <MaterialIcons name="close" size={16} color="white" />
              </TouchableOpacity>
            ))
          ) : (
            <Text style={styles.placeholder}>{placeholder}</Text>
          )}
        </View>
        <View style={styles.pickerControls}>
          {localSelectedOptions.length > 0 && (
            <TouchableOpacity onPress={handleReset} style={styles.resetButton}>
              <MaterialIcons name="clear" size={20} color="gray" />
            </TouchableOpacity>
          )}
          <Ionicons
            name={isOpen ? 'chevron-up' : 'chevron-down'}
            size={24}
            color="gray"
          />
        </View>
      </TouchableOpacity>
      <Modal
        visible={isOpen}
        transparent={true}
        animationType="none"
        onRequestClose={() => setIsOpen(false)}
      >
        <TouchableWithoutFeedback onPress={handleOutsidePress}>
          <View style={styles.modalOverlay}>
            {dropdownLayout && (
              <TouchableWithoutFeedback>
                <View
                  style={[
                    styles.dropdown,
                    {
                      top: dropdownLayout.pageY,
                      left: dropdownLayout.pageX,
                      width: dropdownLayout.width,
                    },
                  ]}
                >
                  <TextInput
                    style={[styles.searchInput, styles.searchInputEnhanced]}
                    placeholder="Search..."
                    value={searchQuery}
                    onChangeText={setSearchQuery}
                  />
                  <FlatList
                    data={filteredOptions}
                    renderItem={renderOption}
                    keyExtractor={item => item.value}
                  />
                </View>
              </TouchableWithoutFeedback>
            )}
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1000,
  },
  picker: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 12,
    backgroundColor: 'white',
  },
  pickerText: {
    fontSize: 16,
    flex: 1,
    marginRight: 8,
  },
  dropdown: {
    position: 'absolute',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    backgroundColor: 'white',
    maxHeight: 300,
  },
  option: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#ccc',
  },
  optionText: {
    fontSize: 16,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  searchInput: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 16,
  },
  searchInputEnhanced: {
    marginHorizontal: 10,
    marginTop: 10,
    marginBottom: 5,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 6,
  },
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  tag: {
    backgroundColor: '#1B62F8',
    borderRadius: 16,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 4,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagText: {
    color: 'white',
    fontSize: 14,
    marginRight: 4,
    fontWeight: 'bold',
  },
  pickerControls: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  resetButton: {
    marginRight: 8,
  },
  placeholder: {
    color: '#999',
    fontSize: 16,
  },
});

export { Index };
