import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Modal,
  TouchableNativeFeedback,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useRoute } from '@react-navigation/native';
import * as GlobalVariables from '../config/GlobalVariableContext';

const Index = ({ theme }) => {
  const route = useRoute();
  const routePath = route?.path;
  const firstPath = routePath?.split('/')[1];
  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const options = Constants['PAGES_LINKING_OPTIONS'];
  const linkTo = useLinkTo();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownLayout, setDropdownLayout] = useState(null);
  const [maxLabelWidth, setMaxLabelWidth] = useState(0);
  const pickerRef = useRef(null);

  const selectedOption = options.find(
    option => option.value === Constants['selectedPage']
  );

  useEffect(() => {
    if (firstPath) {
      setGlobalVariableValue({
        key: 'selectedPage',
        value: firstPath,
      });
    }
  }, [firstPath]);

  const toggleDropdown = useCallback(() => {
    if (!isOpen) {
      pickerRef.current.measure((x, y, width, height, pageX, pageY) => {
        setDropdownLayout({ width, height, pageX, pageY: pageY + height });
      });
    }
    setIsOpen(prev => !prev);
  }, [isOpen]);

  const handleOptionPress = newPickerValue => {
    try {
      setGlobalVariableValue({
        key: 'selectedPage',
        value: newPickerValue,
      });
      linkTo(`/${newPickerValue}`);
    } catch (err) {
      console.error(err);
    }
  };

  const renderOption = ({ item }) => {
    return (
      <a
        key={item.value}
        href={item.value || '#'}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            return;
          }
          e.preventDefault();
          handleOptionPress(item.value);
        }}
      >
        <View
          style={[
            styles.option,
            { minWidth: maxLabelWidth, color: theme.colors.text.strong },
          ]}
        >
          <Text
            style={[styles.optionText, { color: theme.colors.text.strong }]}
          >
            {item.label}
          </Text>
        </View>
      </a>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        ref={pickerRef}
        onPress={toggleDropdown}
        style={[
          styles.picker,
          {
            borderColor: theme.colors.border.brand,
            backgroundColor: theme.colors.background.brand,
            minWidth: maxLabelWidth + 50,
            width: 250,
          },
        ]}
      >
        <Text
          style={[
            styles.pickerText,
            { color: theme.colors.text.strong, flex: 1 },
          ]}
          numberOfLines={1}
        >
          {selectedOption ? selectedOption.label : 'Select page'}
        </Text>
        <Ionicons
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          size={24}
          color={theme.colors.text.strong}
        />
      </TouchableOpacity>
      <Modal
        visible={isOpen}
        transparent={true}
        animationType="none"
        onRequestClose={() => setIsOpen(false)}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPress={() => setIsOpen(false)}
        >
          {dropdownLayout && (
            <View
              style={[
                styles.dropdown,
                {
                  top: dropdownLayout.pageY,
                  left: dropdownLayout.pageX,
                  minWidth: maxLabelWidth + 50,
                  borderColor: theme.colors.border.brand,
                  backgroundColor: theme.colors.background.brand,
                },
              ]}
            >
              <FlatList
                data={options}
                renderItem={renderOption}
                keyExtractor={item => item.value}
              />
            </View>
          )}
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1000,
    width: 250,
  },
  picker: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 8,
    padding: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  pickerText: {
    fontSize: 16,
    fontWeight: '500',
    marginRight: 8,
  },
  dropdown: {
    position: 'absolute',
    borderWidth: 1,
    borderRadius: 8,
    maxHeight: 400,
    width: 250,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 5,
  },
  option: {
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  optionText: {
    fontSize: 16,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

export { Index };
