const convertDateRangeToFilters = (startDate, endDate) => {
  const filters = [];
  if (startDate) {
    filters.push({
      timestamp: new Date(startDate).getTime(),
      filter_type: '>',
    });
  }
  if (endDate) {
    filters.push({
      timestamp: new Date(endDate).getTime(),
      filter_type: '<',
    });
  }
  return filters;
};

export default convertDateRangeToFilters;
