import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as MultiSelectPicker from '../custom-files/MultiSelectPicker';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  maxWidth: 300,
  minWidth: 300,
  onChange: () => {},
  options: null,
  placeholder: null,
  selectedOptions: [],
};

const CustomMultiSelectPickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        { flexWrap: { minWidth: Breakpoints.Laptop, value: 'wrap' } },
        dimensions.width
      )}
    >
      <Utils.CustomCodeErrorBoundary>
        <MultiSelectPicker.Index
          options={props?.options ?? []}
          selectedOptions={props?.selectedOptions ?? []}
          onChange={props?.onChange}
          placeholder={props?.placeholder}
          minWidth={props?.minWidth}
          maxWidth={props?.maxWidth}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(CustomMultiSelectPickerBlock);
