import * as CommonPackages from '../custom-files/CommonPackages';

const toastMessage = (message, type) => {
  const Toast = CommonPackages.Toast;
  let icon;
  switch (type) {
    case 'success':
      icon = '✅';
      break;
    case 'error':
      icon = '❌';
      break;
    default:
      icon = '';
  }
  Toast.show({
    type: type,
    // text1: 'Hello',
    text1: icon + ' ' + message,
  });
  // Toast(message, {
  //     duration: 4000,
  //     icon,
  //     styles: {
  //         view: {
  //             backgroundColor: '#262B2F',
  //             borderRadius: 10,
  //         },
  //         text: {
  //             color: 'white',
  //             fontWeight: 'bold'
  //         }
  //     },
  // });
};

export default toastMessage;
