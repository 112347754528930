const getIds = arr => {
  try {
    if (arr === null || arr === undefined) {
      return [];
    }
    if (arr?.length === 0) {
      return [];
    }
    return arr?.map(obj => obj?.id);
  } catch (error) {
    console.log('Error getIds : ', error);
  }
};

export default getIds;
