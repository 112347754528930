import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import FundFormBlock from '../components/FundFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id: 24 };

const FundEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupUpdateFundPATCH = AdminGroupApi.useUpdateFundPATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <AdminGroupApi.FetchGetFundDetailGET
            id={props.route?.params?.id ?? defaultProps.id}
          >
            {({ loading, error, data, refetchGetFundDetail }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <CurrentEditingSectionBlock editingLabel={fetchData?.name} />
                  <SimpleStyleKeyboardAwareScrollView
                    enableAutomaticScroll={false}
                    enableOnAndroid={false}
                    enableResetScrollToCoords={false}
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={true}
                    viewIsInsideTabBar={false}
                    style={StyleSheet.applyWidth(
                      {
                        paddingBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 200,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Fund Admin Form */}
                    <FundFormBlock
                      onSubmit={(
                        name,
                        description,
                        pe_firm,
                        fund_size_local,
                        fund_size_currency,
                        country_hq,
                        type,
                        horizon,
                        vintage_date
                      ) => {
                        const handler = async () => {
                          try {
                            setIsSubmitting(true);
                            const resultUpdateFund = (
                              await adminGroupUpdateFundPATCH.mutateAsync({
                                country_hq: country_hq,
                                description: description,
                                fund_id:
                                  props.route?.params?.id ?? defaultProps.id,
                                fund_size_currency: fund_size_currency,
                                fund_size_local: fund_size_local,
                                horizon: horizon,
                                name: name,
                                pe_firm: pe_firm?.id,
                                type: type,
                                vintage_date: vintage_date,
                              })
                            )?.json;
                            setIsSubmitting(false);
                            if (resultUpdateFund?.code) {
                              toastMessage(resultUpdateFund?.message, 'error');
                            } else {
                              toastMessage(
                                'Update fund successfully',
                                'success'
                              );
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      initialData={fetchData}
                      isSubmitting={isSubmitting}
                    />
                  </SimpleStyleKeyboardAwareScrollView>
                </>
              );
            }}
          </AdminGroupApi.FetchGetFundDetailGET>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(FundEditScreen);
