import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import NotePropCaseFormBlock from '../components/NotePropCaseFormBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import UnderlineButtonBlock from '../components/UnderlineButtonBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as HoverableView from '../custom-files/HoverableView';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const PropCaseListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [hoverDetail, setHoverDetail] = React.useState(null);
  const [isFocusModalDetail, setIsFocusModalDetail] = React.useState(false);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [propCase, setPropCase] = React.useState(false);
  const [propCaseDetail, setPropCaseDetail] = React.useState(null);
  const [propCaseList, setPropCaseList] = React.useState('');
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupDeletePropCaseDELETE =
    AdminGroupApi.useDeletePropCaseDELETE();
  const adminGroupAddNoteToPropCasePATCH =
    AdminGroupApi.useAddNoteToPropCasePATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNextPage(1);
      setPropCaseList([]);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('NewsletterNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'Add new'}
          showAddButton={false}
        />
        {/* Table */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              marginTop: 15,
              paddingLeft: 15,
              paddingRight: 15,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              { maxWidth: 1200, width: '100%' },
              dimensions.width
            )}
          >
            {/* Table Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color 7'],
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Hover Me */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        borderColor: palettes.App.Outline,
                        borderLeftWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        borderTopWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        flex: 0.25,
                        padding: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {' '}
                    </Text>
                  </View>
                )}
              </>
              {/* Actions */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        borderColor: palettes.App.Outline,
                        borderLeftWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        borderTopWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: 1,
                        },
                        flex: 0.25,
                        padding: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {' '}
                    </Text>
                  </View>
                )}
              </>
              {/* Add Note */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App.Outline,
                        borderWidth: 1,
                        flex: 0.25,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {' '}
                    </Text>
                  </View>
                )}
              </>
              {/* Lastest note */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App.Outline,
                    borderTopWidth: 1,
                    flex: 0.25,
                    flexDirection: 'row',
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Latest note'}
                </Text>
              </View>
              {/* Latest coverage */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App.Outline,
                        borderWidth: 1,
                        flex: 0.25,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Latest coverage\n'}
                    </Text>
                  </View>
                )}
              </>
              {/* Target */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App.Outline,
                        borderWidth: 1,
                        flex: 0.25,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Target'}
                    </Text>
                  </View>
                )}
              </>
              {/* Country */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.5 },
                      { minWidth: Breakpoints.Laptop, value: 0.5 },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Country'}
                </Text>
              </View>
              {/* Sector */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App.Outline,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.25 },
                          { minWidth: Breakpoints.Laptop, value: 0.5 },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Sector'}
                    </Text>
                  </View>
                )}
              </>
              {/* EBITDA (FY) */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App.Outline,
                        borderWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.25 },
                          { minWidth: Breakpoints.Laptop, value: 0.5 },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'EBITDA (FY)'}
                    </Text>
                  </View>
                )}
              </>
              {/* Revenue (FY) */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App.Outline,
                    borderRightWidth: {
                      minWidth: Breakpoints.Laptop,
                      value: 1,
                    },
                    borderTopWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.25 },
                      { minWidth: Breakpoints.Laptop, value: 0.5 },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Revenue (FY)'}
                </Text>
              </View>
              {/* Advisor */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App.Outline,
                    borderRightWidth: {
                      minWidth: Breakpoints.Laptop,
                      value: 1,
                    },
                    borderTopWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.25 },
                      { minWidth: Breakpoints.Laptop, value: 0.5 },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Advisor'}
                </Text>
              </View>
              {/* Timeline */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App.Outline,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.25 },
                      { minWidth: Breakpoints.Laptop, value: 0.25 },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Timeline'}
                </Text>
              </View>
            </View>
            {/* Table Content */}
            <View>
              <AdminGroupApi.FetchGetPropCaseGET
                handlers={{
                  on2xx: fetchData => {
                    try {
                      if (fetchData?.json?.items !== propCaseList) {
                        setPropCaseList(fetchData?.json?.items);
                      } else {
                      }

                      console.log(propCaseList);
                      setNextPage(fetchData?.json?.nextPage);
                      /* hidden 'Set Variable' action */
                      /* hidden 'If/Else' action */
                    } catch (err) {
                      console.error(err);
                    }
                  },
                  on401: fetchData => {
                    try {
                      /* hidden 'Show Alert' action */
                      /* hidden 'Set Variable' action */
                      /* hidden 'Set Variable' action */
                      /* hidden 'Navigate' action */
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                keyword={keywordSearch}
                page={1}
              >
                {({ loading, error, data, refetchGetPropCase }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <LoadingBlock />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={propCaseList}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'3wzSC5T1'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReached={() => {
                        const handler = async () => {
                          console.log('List ON_END_REACHED Start');
                          let error = null;
                          try {
                            console.log('Start ON_END_REACHED:0 CONSOLE_LOG');
                            console.log(nextPage);
                            console.log(
                              'Complete ON_END_REACHED:0 CONSOLE_LOG'
                            );
                            console.log(
                              'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                            );
                            if (nextPage === null) {
                              return console.log(
                                'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                              );
                            } else {
                              console.log(
                                'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                              );
                            }
                            console.log('Start ON_END_REACHED:2 FETCH_REQUEST');
                            const newData = (
                              await AdminGroupApi.getPropCaseGET(Constants, {
                                keyword: keywordSearch,
                                page: nextPage,
                              })
                            )?.json;
                            console.log(
                              'Complete ON_END_REACHED:2 FETCH_REQUEST',
                              { newData }
                            );
                            console.log('Start ON_END_REACHED:3 SET_VARIABLE');
                            setNextPage(newData?.nextPage);
                            console.log(
                              'Complete ON_END_REACHED:3 SET_VARIABLE'
                            );
                            console.log('Start ON_END_REACHED:4 SET_VARIABLE');
                            setLastPage(newData?.pageTotal);
                            console.log(
                              'Complete ON_END_REACHED:4 SET_VARIABLE'
                            );
                            console.log(
                              'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                            );
                            if (
                              fetchData?.items ===
                              (0 || propCaseList !== fetchData?.items)
                            ) {
                              return console.log(
                                'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                              );
                            } else {
                              console.log(
                                'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                              );
                            }
                            console.log('Start ON_END_REACHED:6 SET_VARIABLE');
                            setPropCaseList(
                              propCaseList.concat(newData?.items)
                            );
                            console.log(
                              'Complete ON_END_REACHED:6 SET_VARIABLE'
                            );
                          } catch (err) {
                            console.error(err);
                            error = err.message ?? err;
                          }
                          console.log(
                            'List ON_END_REACHED Complete',
                            error ? { error } : 'no error'
                          );
                        };
                        handler();
                      }}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'auto',
                                },
                                backgroundColor: getColorByIndex(index),
                                flexDirection: 'row',
                                width: {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 1200,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Hover Me */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.Outline,
                                  },
                                  borderWidth: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 1,
                                  },
                                  flex: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0.25,
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Utils.CustomCodeErrorBoundary>
                                <HoverableView.Index
                                  onMouseEnter={() => {
                                    if (listData?.id === hoverDetail?.id) {
                                      return;
                                    }

                                    if (
                                      listData?.notes === null ||
                                      listData?.notes === undefined ||
                                      listData?.notes?.length === 0 ||
                                      listData?.notes?.[0] === null
                                    ) {
                                      return;
                                    }
                                    setHoverDetail(listData);
                                  }}
                                  onMouseLeave={() => setHoverDetail(null)}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        alignItems: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        backgroundColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value: palettes.App['GetFit Orange'],
                                        },
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 40,
                                        },
                                        height: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 40,
                                        },
                                        justifyContent: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        width: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 40,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          {
                                            alignSelf: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                            color: {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                theme.colors.background.brand,
                                            },
                                            fontFamily: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'Quicksand_600SemiBold',
                                            },
                                            fontSize: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 12,
                                            },
                                            textAlign: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Notes'}
                                    </Text>
                                  </View>
                                </HoverableView.Index>
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                            {/* Edit */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.Outline,
                                  },
                                  borderWidth: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 1,
                                  },
                                  flex: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0.25,
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <TableActionsBlock
                                onPressDelete={() => {
                                  try {
                                    setDeletingItem(listData);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                showDelete={true}
                                showEdit={true}
                              />
                            </View>
                            {/* Add Note */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.Outline,
                                  },
                                  borderWidth: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 1,
                                  },
                                  flex: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0.25,
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <UnderlineButtonBlock
                                onPress={() => {
                                  try {
                                    setPropCaseDetail(listData);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                label={'ADD NOTE'}
                              />
                            </View>
                            {/* latest_note */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: 0.25,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {formatTimestampToCopenhagen(
                                      listData?.latest_note
                                    )}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* nkp_coverage_latest */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: 0.25,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {formatTimestampToCopenhagen(
                                      listData?.nkp_coverage_latest
                                    )}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* target */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: 0.25,
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.target}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* country */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.25,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.country}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* gics */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: palettes.App.Outline,
                                  borderWidth: 0.5,
                                  flex: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 0.5,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 0.5,
                                    },
                                  ],
                                  padding: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Table Row']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Table Row']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {listData?.gics}
                              </Text>
                            </View>
                            {/* ebitda */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.25,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.currency}
                                    {' \n'}
                                    {listData?.ebitda_local}
                                    {'m \n'}
                                    {listData?.financial_year}
                                    {'\n'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* revenue (fy) */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App.Outline,
                                      borderWidth: 0.5,
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 0.25,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0.5,
                                        },
                                      ],
                                      padding: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Table Row'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.currency}
                                    {' \n'}
                                    {listData?.financial_year}
                                    {'m\n'}
                                    {listData?.revenue_local}
                                    {'\n'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* cf_advisor */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: palettes.App.Outline,
                                  borderWidth: 0.5,
                                  flex: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 0.25,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 0.5,
                                    },
                                  ],
                                  padding: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Table Row']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Table Row']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {listData?.cf_advisor}
                              </Text>
                            </View>
                            {/* timeline */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: palettes.App.Outline,
                                  borderWidth: 0.5,
                                  flex: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 0.25,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 0.25,
                                    },
                                  ],
                                  padding: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Table Row']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Table Row']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {listData?.process_timeline}
                              </Text>
                            </View>
                          </View>
                        );
                      }}
                      onEndReachedThreshold={0.5}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: {
                            minWidth: Breakpoints.Laptop,
                            value:
                              dimensions.width >= Breakpoints.Laptop
                                ? 0
                                : undefined,
                          },
                          maxHeight: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: dimensions.height - 280,
                            },
                            {
                              minWidth: Breakpoints.Tablet,
                              value: dimensions.height - 250,
                            },
                          ],
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    />
                  );
                }}
              </AdminGroupApi.FetchGetPropCaseGET>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDelete = (
                  await adminGroupDeletePropCaseDELETE.mutateAsync({
                    id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDelete?.code) {
                  toastMessage(resultDelete?.message, 'error');
                } else {
                  toastMessage('Delete prop case successfully', 'success');
                }

                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'prop case'}
          visible={deletingItem !== null}
        />
        {/* Modal Hover Notes */}
        <>
          {!hoverDetail ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.White,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App['Custom Color_18'],
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  borderWidth: { minWidth: Breakpoints.Laptop, value: 2 },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 25 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 25 },
                  position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                  top: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height / 5,
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '42%' },
                },
                dimensions.width
              )}
            >
              <SimpleStyleFlatList
                data={hoverDetail?.notes}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'C2YtS6oF'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          gap: { minWidth: Breakpoints.Laptop, value: 6 },
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: index === 0 ? 0 : 25,
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        selectable={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_600SemiBold',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 16,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {formatTimestampToCopenhagen(listData?.timestamp)}
                        {' - ('}
                        {listData?._user_admin?.name}
                        {'):'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                        selectable={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Table Row'].style,
                            {
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 15,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {listData?.note}
                      </Text>
                    </View>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            </View>
          )}
        </>
        {/* Modal Add Note */}
        <>
          {!(propCaseDetail !== null) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.Overlay,
                    },
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    position: {
                      minWidth: Breakpoints.Laptop,
                      value: 'absolute',
                    },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  },
                  dimensions.width
                )}
              />
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Card */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.White,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color_18'],
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                      borderWidth: { minWidth: Breakpoints.Laptop, value: 2 },
                      paddingBottom: {
                        minWidth: Breakpoints.Laptop,
                        value: 25,
                      },
                      paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                      paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                      paddingTop: { minWidth: Breakpoints.Laptop, value: 25 },
                      width: { minWidth: Breakpoints.Laptop, value: '42%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Add Note Prop Case Form */}
                  <NotePropCaseFormBlock
                    onCancel={() => {
                      try {
                        setPropCaseDetail(null);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmit={(note, timestamp, user_admin_id) => {
                      const handler = async () => {
                        try {
                          setIsSubmitting(true);
                          const resultAddNote = (
                            await adminGroupAddNoteToPropCasePATCH.mutateAsync({
                              note: note,
                              prop_case_id: propCaseDetail?.id,
                              timestamp: timestamp,
                              user_admin_id: user_admin_id,
                            })
                          )?.json;
                          if (resultAddNote?.code) {
                            toastMessage(resultAddNote?.message, 'error');
                          } else {
                            toastMessage(
                              'Add note to prop case successfully',
                              'success'
                            );
                            setPropCaseDetail(null);
                            setIsSubmitting(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    initialData={propCaseDetail}
                    isSubmitting={isSubmitting}
                  />
                </View>
              </View>
            </View>
          )}
        </>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(PropCaseListingScreen);
