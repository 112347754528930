import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as MonthYearPicker from '../custom-files/MonthYearPicker';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { onChange: () => {}, value: null };

const MonthYearPickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View>
      <Utils.CustomCodeErrorBoundary>
        <MonthYearPicker.Index
          onChange={newMonthYearValue => {
            props?.onChange(newMonthYearValue);
          }}
          value={props?.value}
        />
      </Utils.CustomCodeErrorBoundary>
    </View>
  );
};

export default withTheme(MonthYearPickerBlock);
