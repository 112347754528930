import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import UsersClientsFormBlock from '../components/UsersClientsFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const UsersClientsCreateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupCreateUsersClientsPOST =
    AdminGroupApi.useCreateUsersClientsPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <CurrentEditingSectionBlock />
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 200 },
              },
              dimensions.width
            )}
          >
            <UsersClientsFormBlock
              onSubmit={(
                name,
                email,
                password,
                country,
                accessible_regions,
                job_title,
                company,
                company_type,
                trial_start,
                trial_end,
                paid_nordic,
                paid_dach,
                user_status,
                Initial_Login_Complete,
                paid_or_trial,
                push_notification_nl_nordic,
                push_notification_nl_dach,
                welcome_reminder_sent_on,
                nordic_nl_section_1,
                nordic_nl_section_2,
                nordic_nl_section_3,
                nordic_nl_section_4,
                email_notification_nordic,
                email_notification_dach,
                region,
                google_oauth,
                last_login_web_app,
                last_login_android_app,
                last_login_ios_app,
                temp_login,
                created_at,
                knack_id,
                account_activated_on,
                expo_token,
                screenshot_notification_shown
              ) => {
                const handler = async () => {
                  try {
                    setIsSubmitting(true);
                    const resultCreated = (
                      await adminGroupCreateUsersClientsPOST.mutateAsync({
                        Initial_Login_Complete: Initial_Login_Complete,
                        accessible_regions: accessible_regions,
                        account_activated_on: account_activated_on,
                        company: company,
                        company_type: company_type,
                        country: country,
                        email: email,
                        email_notification_dach: email_notification_dach,
                        email_notification_nordic: email_notification_nordic,
                        expo_token: expo_token,
                        google_oauth: google_oauth,
                        job_title: job_title,
                        knack_id: knack_id,
                        last_login_android_app: last_login_android_app,
                        last_login_ios_app: last_login_ios_app,
                        last_login_web_app: last_login_web_app,
                        name: name,
                        nordic_nl_section_1: nordic_nl_section_1,
                        nordic_nl_section_2: nordic_nl_section_2,
                        nordic_nl_section_3: nordic_nl_section_3,
                        nordic_nl_section_4: nordic_nl_section_4,
                        paid_dach: paid_dach,
                        paid_nordic: paid_nordic,
                        password: password,
                        push_notification_nl_dach: push_notification_nl_dach,
                        push_notification_nl_nordic:
                          push_notification_nl_nordic,
                        region: region,
                        screenshot_notification_shown:
                          screenshot_notification_shown,
                        temp_login: temp_login,
                        trial_end: trial_end,
                        trial_start: trial_start,
                        user_status: user_status,
                        welcome_reminder_sent_on: welcome_reminder_sent_on,
                      })
                    )?.json;
                    setIsSubmitting(false);
                    if (resultCreated?.code) {
                      toastMessage(resultCreated?.message, 'error');
                    } else {
                      toastMessage(
                        'Create user client successfully',
                        'success'
                      );
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              initialData={null}
              isSubmitting={isSubmitting}
            />
          </SimpleStyleKeyboardAwareScrollView>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(UsersClientsCreateScreen);
