import React from 'react';
import {
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CurrentEditingSectionBlock from '../components/CurrentEditingSectionBlock';
import EventFormBlock from '../components/EventFormBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getIds from '../global-functions/getIds';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id: null };

const EventEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const adminGroupEditEventPATCH = AdminGroupApi.useEditEventPATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock />
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Container'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Container'].style,
              { marginTop: { minWidth: Breakpoints.Laptop, value: 0 } }
            ),
            dimensions.width
          )}
        >
          <AdminGroupApi.FetchGetEventGET
            id={props.route?.params?.id ?? defaultProps.id}
          >
            {({ loading, error, data, refetchGetEvent }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <CurrentEditingSectionBlock
                    editingLabel={fetchData?.headline}
                  />
                  <SimpleStyleKeyboardAwareScrollView
                    enableAutomaticScroll={false}
                    enableOnAndroid={false}
                    enableResetScrollToCoords={false}
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={true}
                    viewIsInsideTabBar={false}
                    style={StyleSheet.applyWidth(
                      {
                        paddingBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 200,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <EventFormBlock
                      onSubmit={(
                        headline,
                        reviewed_by,
                        status,
                        source,
                        source_type,
                        source_link,
                        target,
                        event_type,
                        country,
                        gics,
                        buyer,
                        seller,
                        strat_or_invf_buyer,
                        strat_or_invf_seller,
                        pepf,
                        pefirm_behind_buyer,
                        revenue_local,
                        gross_profit_local,
                        ebitda_local,
                        ebit_local,
                        currency_financials,
                        fy_end,
                        note_financials,
                        ev,
                        currency_ev,
                        ev_source,
                        include_in_multiples_db,
                        buyside_cf,
                        buyside_legal,
                        sellside_cf,
                        sellside_legal,
                        published,
                        link_to_financials,
                        description
                      ) => {
                        const handler = async () => {
                          try {
                            setIsSubmitting(true);
                            const resultCreateEvent = (
                              await adminGroupEditEventPATCH.mutateAsync({
                                buyer: getIds(buyer),
                                buyside_cf: getIds(buyside_cf),
                                buyside_legal: getIds(buyside_legal),
                                country: country,
                                currency_ev: currency_ev,
                                currency_financials: currency_financials,
                                description: description,
                                ebit_local: ebit_local,
                                ebitda_local: ebitda_local,
                                ev: ev,
                                ev_source: ev_source,
                                event_type: event_type,
                                fy_end: fy_end,
                                gics: gics?.id,
                                gross_profit_local: gross_profit_local,
                                headline: headline,
                                id: props.route?.params?.id ?? defaultProps.id,
                                include_in_multiples_db:
                                  include_in_multiples_db,
                                link_to_financials: link_to_financials,
                                note_financials: note_financials,
                                pefirm_behind_buyer: pefirm_behind_buyer?.id,
                                pepf: pepf?.id,
                                published: published,
                                revenue_local: revenue_local,
                                reviewed_by: reviewed_by,
                                seller: getIds(seller),
                                sellside_cf: getIds(sellside_cf),
                                sellside_legal: getIds(sellside_legal),
                                source: source,
                                source_link: source_link,
                                source_type: source_type,
                                status: status,
                                strat_or_invf_buyer: strat_or_invf_buyer,
                                strat_or_invf_seller: strat_or_invf_seller,
                                target: target,
                              })
                            )?.json;
                            setIsSubmitting(false);
                            if (resultCreateEvent?.code) {
                              toastMessage(resultCreateEvent?.message, 'error');
                            } else {
                              toastMessage(
                                'Update event successfully',
                                'success'
                              );
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      initialData={fetchData}
                      isSubmitting={isSubmitting}
                    />
                  </SimpleStyleKeyboardAwareScrollView>
                </>
              );
            }}
          </AdminGroupApi.FetchGetEventGET>
        </Surface>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(EventEditScreen);
