// This is a cache of in-flight requests. Each request key maps to an
// array of Promises. When the request resolves, each promise in the
// array is pushed to.
var requests = {};

export function getRequestKey() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$url = _ref.url,
      url = _ref$url === undefined ? '' : _ref$url,
      _ref$method = _ref.method,
      method = _ref$method === undefined ? '' : _ref$method,
      _ref$responseType = _ref.responseType,
      responseType = _ref$responseType === undefined ? '' : _ref$responseType,
      _ref$body = _ref.body,
      body = _ref$body === undefined ? '' : _ref$body;

  return [url, method.toUpperCase(), responseType, body].join('||');
}

// Returns `true` if a request with `requestKey` is in flight,
// and `false` otherwise.
export function isRequestInFlight(requestKey) {
  return Boolean(requests[requestKey]);
}

export function clearRequestCache() {
  requests = {};
}

// This loops through all of the handlers for the request and either
// resolves or rejects them.
function resolveRequest(_ref2) {
  var requestKey = _ref2.requestKey,
      res = _ref2.res,
      err = _ref2.err;

  var handlers = requests[requestKey] || [];

  handlers.forEach(function (handler) {
    if (res) {
      handler.resolve(res);
    } else {
      handler.reject(err);
    }
  });

  // This list of handlers has been, well, handled. So we
  // clear the handlers for the next request.
  requests[requestKey] = null;
}

export function fetchDedupe(input) {
  var init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var dedupeOptions = arguments[2];

  var opts = void 0,
      initToUse = void 0;
  if (dedupeOptions) {
    opts = dedupeOptions;
    initToUse = init;
  } else if (init.responseType) {
    opts = init;
    initToUse = {};
  } else {
    opts = {};
    initToUse = init;
  }

  var _opts = opts,
      requestKey = _opts.requestKey,
      _opts$responseType = _opts.responseType,
      responseType = _opts$responseType === undefined ? '' : _opts$responseType,
      _opts$dedupe = _opts.dedupe,
      dedupe = _opts$dedupe === undefined ? true : _opts$dedupe;

  // Build the default request key if one is not passed

  var requestKeyToUse = requestKey || getRequestKey({
    // If `input` is a request, then we use that URL
    url: input.url || input,
    // We prefer values from `init` over request objects. With `fetch()`, init
    // takes priority over a passed-in request
    method: initToUse.method || input.method || '',
    body: initToUse.body || input.body || ''
  });

  var proxyReq = void 0;
  if (dedupe) {
    if (!requests[requestKeyToUse]) {
      requests[requestKeyToUse] = [];
    }

    var handlers = requests[requestKeyToUse];
    var requestInFlight = Boolean(handlers.length);
    var requestHandler = {};
    proxyReq = new Promise(function (resolve, reject) {
      requestHandler.resolve = resolve;
      requestHandler.reject = reject;
    });

    handlers.push(requestHandler);

    if (requestInFlight) {
      return proxyReq;
    }
  }

  var request = fetch(input, initToUse).then(function (res) {
    var responseTypeToUse = void 0;
    if (responseType instanceof Function) {
      responseTypeToUse = responseType(res);
    } else if (responseType) {
      responseTypeToUse = responseType;
    } else if (res.status === 204) {
      responseTypeToUse = 'text';
    } else {
      responseTypeToUse = 'json';
    }
    // The response body is a ReadableStream. ReadableStreams can only be read a single
    // time, so we must handle that in a central location, here, before resolving
    // the fetch.
    return res[responseTypeToUse]().then(function (data) {
      res.data = data;

      if (dedupe) {
        resolveRequest({ requestKey: requestKeyToUse, res: res });
      } else {
        return res;
      }
    }, function () {
      res.data = null;

      if (dedupe) {
        resolveRequest({ requestKey: requestKeyToUse, res: res });
      } else {
        return res;
      }
    });
  }, function (err) {
    if (dedupe) {
      resolveRequest({ requestKey: requestKeyToUse, err: err });
    } else {
      return Promise.reject(err);
    }
  });

  if (dedupe) {
    return proxyReq;
  } else {
    return request;
  }
}