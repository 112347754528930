import React from 'react';
import {
  DatePicker,
  Picker,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: {},
  isSubmitting: null,
  onSubmit: () => {},
};

const NewsletterFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [date, setDate] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.date
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [events_dach_large_cap, setEvents_dach_large_cap] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_dach_large_cap,
      'headline',
      'id',
      undefined
    )
  );
  const [events_dach_mid, setEvents_dach_mid] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_dach_mid,
      'headline',
      'id',
      undefined
    )
  );
  const [events_dach_small, setEvents_dach_small] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_dach_small,
      'headline',
      'id',
      undefined
    )
  );
  const [events_dk, setEvents_dk] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_dk,
      'headline',
      'id',
      undefined
    )
  );
  const [events_fi, setEvents_fi] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_fi,
      'headline',
      'id',
      undefined
    )
  );
  const [events_no, setEvents_no] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_no,
      'headline',
      'id',
      undefined
    )
  );
  const [events_se, setEvents_se] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_se,
      'headline',
      'id',
      undefined
    )
  );
  const [potd, setPotd] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._potd_single,
      'headline',
      'id',
      undefined
    )
  );
  const [status, setStatus] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.status
  );
  const [suggestionsDKEvents, setSuggestionsDKEvents] = React.useState([]);
  const [suggestionsFIEvents, setSuggestionsFIEvents] = React.useState([]);
  const [suggestionsLargeCapEvents, setSuggestionsLargeCapEvents] =
    React.useState(
      transformToSearchInputFormat(
        (props.initialData ?? defaultProps.initialData)?.events_dach_large_cap,
        'headline',
        'id',
        undefined
      )
    );
  const [suggestionsMidCapEvents, setSuggestionsMidCapEvents] = React.useState(
    transformToSearchInputFormat(
      (props.initialData ?? defaultProps.initialData)?.events_dach_mid,
      'headline',
      'id',
      undefined
    )
  );
  const [suggestionsNOEvents, setSuggestionsNOEvents] = React.useState([]);
  const [suggestionsPOTD, setSuggestionsPOTD] = React.useState([]);
  const [suggestionsSEEvents, setSuggestionsSEEvents] = React.useState([]);
  const [suggestionsSmallCapEvents, setSuggestionsSmallCapEvents] =
    React.useState(
      transformToSearchInputFormat(
        (props.initialData ?? defaultProps.initialData)?.events_dach_small,
        'headline',
        'id',
        undefined
      )
    );
  const [teaser, setTeaser] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.teaser
  );
  const [title, setTitle] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.title
  );
  const [version, setVersion] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.version
  );
  const validateForm = () => {
    const requiredFields = {
      version: 'Version',
      title: 'Title',
      date: 'Date',
      status: 'Status',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Version */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(teaser),
                    borderLeftWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Versions *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(teaser),
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      if (newPickerValue === 'Nordic') {
                        setEvents_dach_large_cap([]);
                        setEvents_dach_mid([]);
                        setEvents_dach_small([]);
                      } else {
                      }

                      if (newPickerValue === 'DACH') {
                        setEvents_se([]);
                        setEvents_dk([]);
                        setEvents_fi([]);
                        setEvents_no([]);
                      } else {
                      }

                      setVersion(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  options={Constants['REGION_OPTIONS']}
                  rightIconName={'Entypo/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={version}
                />
              </View>
            </View>
            {/* Title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(teaser),
                    borderLeftWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Title *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(dimensions),
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextAreaValue => {
                    try {
                      setTitle(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  multiline={true}
                  numberOfLines={4}
                  placeholder={'Input title here'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={title}
                />
              </View>
            </View>
            {/* Date */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(dimensions),
                    borderLeftWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Date *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    borderColor: borderColorSmallDevice(teaser),
                    borderRightWidth: [
                      { minWidth: Breakpoints.Mobile, value: 1 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    try {
                      setDate(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .props}
                  date={customFormatDate(date)}
                  label={'Date'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                      .style,
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Teaser */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Teaser '}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setTeaser(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  multiline={true}
                  placeholder={'Input teaser here'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={teaser}
                />
              </View>
            </View>
          </View>
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Status */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Status *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setStatus(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['app picker'].props}
                  options={Constants['STATUS_OPTIONS']}
                  rightIconName={'Entypo/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['app picker'].style,
                    dimensions.width
                  )}
                  value={status}
                />
              </View>
            </View>
            {/* POTD */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  flexDirection: rowOnlySmallDevice(dimensions),
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'POTD'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: borderColorSmallDevice(dimensions),
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultPotd = (
                          await AdminGroupApi.getPOTDGET(Constants, {
                            keyword: searchKeyword,
                            page: 1,
                          })
                        )?.json;
                        const potdOptions = transformToSearchInputFormat(
                          resultPotd?.items,
                          'headline',
                          'id',
                          undefined
                        );
                        setSuggestionsPOTD(potdOptions);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onSelect={selectedResult => {
                    try {
                      setPotd(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={transformToSearchInputObject(
                    (props.initialData ?? defaultProps.initialData)
                      ?._potd_single,
                    'headline',
                    'id',
                    undefined
                  )}
                  multiSelect={false}
                  selectedItem={potd}
                  suggestionsList={suggestionsPOTD}
                />
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 3 */}
          <>
            {!(version === Constants['VERSION_NORDIC']) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    flexDirection: columnOnlySmallDevice(dimensions),
                  },
                  dimensions.width
                )}
              >
                {/* DK Events (Nordic NL) */}
                <>
                  {!(version === Constants['VERSION_NORDIC']) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          flexDirection: rowOnlySmallDevice(dimensions),
                        },
                        dimensions.width
                      )}
                    >
                      {/* Field */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.3 },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: flexOnlySmallDevice(dimensions, 0.3),
                              },
                            ],
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Title']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Table Title'].style,
                            dimensions.width
                          )}
                        >
                          {'DK Events (Nordic NL)'}
                        </Text>
                      </View>
                      {/* Value */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderRightWidth: 1,
                            flex: 0.7,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <FuzzyInputWithDataBlock
                          getSuggestions={searchKeyword => {
                            const handler = async () => {
                              try {
                                const events = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    country: 'Denmark',
                                    keyword: searchKeyword,
                                    page: 1,
                                    per_page: 25,
                                    region: version,
                                  })
                                )?.json;
                                const eventOptions =
                                  transformToSearchInputFormat(
                                    events?.items,
                                    'headline',
                                    'id',
                                    undefined
                                  );
                                setSuggestionsDKEvents(eventOptions);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onSelect={selectedResult => {
                            try {
                              setEvents_dk(selectedResult);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          multiSelect={true}
                          selectedItem={null}
                          selectedItems={events_dk}
                          suggestionsList={suggestionsDKEvents}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* SE Events (Nordic NL) */}
                <>
                  {!(version === Constants['VERSION_NORDIC']) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          flexDirection: rowOnlySmallDevice(dimensions),
                        },
                        dimensions.width
                      )}
                    >
                      {/* Field */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.3 },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: flexOnlySmallDevice(dimensions, 0.3),
                              },
                            ],
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Title']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Table Title'].style,
                            dimensions.width
                          )}
                        >
                          {'SE Events (Nordic NL)\n'}
                        </Text>
                      </View>
                      {/* Value 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderRightWidth: 1,
                            flex: 0.7,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <FuzzyInputWithDataBlock
                          getSuggestions={searchKeyword => {
                            const handler = async () => {
                              try {
                                const events = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    country: 'Sweden',
                                    keyword: searchKeyword,
                                    page: 1,
                                    per_page: 25,
                                    region: version,
                                  })
                                )?.json;
                                const suggestions_se_events =
                                  transformToSearchInputFormat(
                                    events?.items,
                                    'headline',
                                    'id',
                                    undefined
                                  );
                                setSuggestionsSEEvents(suggestions_se_events);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onSelect={selectedResult => {
                            try {
                              setEvents_se(selectedResult);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          multiSelect={true}
                          selectedItem={null}
                          selectedItems={events_se}
                          suggestionsList={suggestionsSEEvents}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* NO Events (Nordic NL) */}
                <>
                  {!(version === Constants['VERSION_NORDIC']) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          flexDirection: rowOnlySmallDevice(dimensions),
                        },
                        dimensions.width
                      )}
                    >
                      {/* Field */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.3 },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: flexOnlySmallDevice(dimensions, 0.3),
                              },
                            ],
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Title']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Table Title'].style,
                            dimensions.width
                          )}
                        >
                          {'NO Events (Nordic NL)'}
                        </Text>
                      </View>
                      {/* Value */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderRightWidth: 1,
                            flex: 0.7,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <FuzzyInputWithDataBlock
                          getSuggestions={searchKeyword => {
                            const handler = async () => {
                              try {
                                const events = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    country: 'Norway',
                                    keyword: searchKeyword,
                                    page: 1,
                                    per_page: 25,
                                    region: version,
                                  })
                                )?.json;
                                const new_events_no =
                                  transformToSearchInputFormat(
                                    events?.items,
                                    'headline',
                                    'id',
                                    undefined
                                  );
                                setSuggestionsNOEvents(new_events_no);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onSelect={selectedResult => {
                            try {
                              setEvents_no(selectedResult);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          multiSelect={true}
                          selectedItem={null}
                          selectedItems={events_no}
                          suggestionsList={suggestionsNOEvents}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* FI Events (Nordic NL) */}
                <>
                  {!(version === Constants['VERSION_NORDIC']) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          flexDirection: rowOnlySmallDevice(dimensions),
                        },
                        dimensions.width
                      )}
                    >
                      {/* Field */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.3 },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: flexOnlySmallDevice(dimensions, 0.3),
                              },
                            ],
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Title']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Table Title'].style,
                            dimensions.width
                          )}
                        >
                          {'FI Events (Nordic NL)'}
                        </Text>
                      </View>
                      {/* Value */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderRightWidth: 1,
                            flex: 0.7,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <FuzzyInputWithDataBlock
                          getSuggestions={searchKeyword => {
                            const handler = async () => {
                              try {
                                const events = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    country: 'Finland',
                                    keyword: searchKeyword,
                                    page: 1,
                                    per_page: 25,
                                    region: version,
                                  })
                                )?.json;
                                const new_events = transformToSearchInputFormat(
                                  events?.items,
                                  'headline',
                                  'id',
                                  undefined
                                );
                                setSuggestionsFIEvents(new_events);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onSelect={selectedResult => {
                            try {
                              setEvents_fi(selectedResult);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          multiSelect={true}
                          selectedItem={null}
                          selectedItems={events_fi}
                          suggestionsList={suggestionsFIEvents}
                        />
                      </View>
                    </View>
                  )}
                </>
              </View>
            )}
          </>
          {/* Row 4 */}
          <>
            {!(version === Constants['VERSION_DACH']) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: columnOnlySmallDevice(dimensions) },
                  dimensions.width
                )}
              >
                {/* Large Cap events (DACH NL) */}
                <>
                  {!(version === Constants['VERSION_DACH']) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          flexDirection: rowOnlySmallDevice(dimensions),
                        },
                        dimensions.width
                      )}
                    >
                      {/* Field */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 0.3 },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: flexOnlySmallDevice(dimensions, 0.3),
                              },
                            ],
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Title']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Table Title'].style,
                            dimensions.width
                          )}
                        >
                          {'Large Cap events (DACH NL)'}
                        </Text>
                      </View>
                      {/* Value */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: borderColorSmallDevice(dimensions),
                            borderRightWidth: 1,
                            flex: 0.7,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <FuzzyInputWithDataBlock
                          getSuggestions={searchKeyword => {
                            const handler = async () => {
                              try {
                                const events = (
                                  await AdminGroupApi.getEventsGET(Constants, {
                                    keyword: searchKeyword,
                                    page: 1,
                                    per_page: 25,
                                    region: Constants['VERSION_DACH'],
                                  })
                                )?.json;
                                const eventOptions =
                                  transformToSearchInputFormat(
                                    events?.items,
                                    'headline',
                                    'id',
                                    undefined
                                  );
                                setSuggestionsLargeCapEvents(eventOptions);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onSelect={selectedResult => {
                            try {
                              setEvents_dach_large_cap(selectedResult);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          multiSelect={true}
                          selectedItem={null}
                          selectedItems={events_dach_large_cap}
                          suggestionsList={suggestionsLargeCapEvents}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Mid Cap events (DACH NL) */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: rowOnlySmallDevice(dimensions),
                    },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: borderColorSmallDevice(dimensions),
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Mid Cap events (DACH NL)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: borderColorSmallDevice(dimensions),
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <FuzzyInputWithDataBlock
                      getSuggestions={searchKeyword => {
                        const handler = async () => {
                          try {
                            const events = (
                              await AdminGroupApi.getEventsGET(Constants, {
                                keyword: searchKeyword,
                                page: 1,
                                per_page: 25,
                                region: Constants['VERSION_DACH'],
                              })
                            )?.json;
                            const eventOptions = transformToSearchInputFormat(
                              events?.items,
                              'headline',
                              'id',
                              undefined
                            );
                            setSuggestionsMidCapEvents(eventOptions);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      onSelect={selectedResult => {
                        try {
                          setEvents_dach_mid(selectedResult);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedItems={events_dach_mid}
                      suggestionsList={suggestionsMidCapEvents}
                    />
                  </View>
                </View>
                {/* Small Cap & Growth Equity (DACH NL) */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      flexDirection: rowOnlySmallDevice(dimensions),
                    },
                    dimensions.width
                  )}
                >
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: borderColorSmallDevice(dimensions),
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: 0.3 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: flexOnlySmallDevice(dimensions, 0.3),
                          },
                        ],
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Table Title'].style,
                        dimensions.width
                      )}
                    >
                      {'Small Cap & Growth Equity (DACH NL)'}
                    </Text>
                  </View>
                  {/* Value */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: borderColorSmallDevice(dimensions),
                        borderRightWidth: 1,
                        flex: 0.7,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <FuzzyInputWithDataBlock
                      getSuggestions={searchKeyword => {
                        const handler = async () => {
                          try {
                            const events = (
                              await AdminGroupApi.getEventsGET(Constants, {
                                keyword: searchKeyword,
                                page: 1,
                                per_page: 25,
                                region: Constants['VERSION_DACH'],
                              })
                            )?.json;
                            const eventOptions = transformToSearchInputFormat(
                              events?.items,
                              'headline',
                              'id',
                              undefined
                            );
                            setSuggestionsSmallCapEvents(eventOptions);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      onSelect={selectedResult => {
                        try {
                          setEvents_dach_small(selectedResult);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      multiSelect={true}
                      selectedItem={null}
                      selectedItems={events_dach_small}
                      suggestionsList={suggestionsSmallCapEvents}
                    />
                  </View>
                </View>
              </View>
            )}
          </>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                version,
                title,
                date,
                teaser,
                status,
                potd,
                events_dk,
                events_se,
                events_no,
                events_fi,
                events_dach_large_cap,
                events_dach_mid,
                events_dach_small
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(NewsletterFormBlock);
