import React from 'react';
import { withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  isLoadingConfirm: null,
  onCancel: () => {},
  onConfirmDelete: () => {},
  targetName: null,
  visible: null,
};

const OverlayBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [confirmationText, setConfirmationText] = React.useState('');

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.Overlay,
          height: '100%',
          position: 'absolute',
          width: '100%',
        },
        dimensions.width
      )}
    />
  );
};

export default withTheme(OverlayBlock);
