import React from 'react';
import {
  Checkbox,
  LinearGradient,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getColorByIndex from '../global-functions/getColorByIndex';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const CrawlerSourceListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [crawlerSource, setCrawlerSource] = React.useState(false);
  const [crawlerSourceList, setCrawlerSourceList] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [screenCode, setScreenCode] = React.useState('');
  const adminGroupDeleteNewsletterDELETE =
    AdminGroupApi.useDeleteNewsletterDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNextPage(1);
      setCrawlerSourceList([]);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('CrawlerSourceNavigator', {
                screen: 'CrawlerSourceCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'Add new'}
          showAddButton={true}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                { maxWidth: 1200, width: '100%' },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* In Prod */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.15 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'In Prod.'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 0.25,
                      flexDirection: 'row',
                      maxWidth: 150,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Edit'}
                  </Text>
                </View>
                {/* Source Name */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Source Name'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Base URL */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Base URL'}
                      </Text>
                    </View>
                  )}
                </>
                {/* User Email */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: [
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                        { minWidth: Breakpoints.Laptop, value: 0.5 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'User Email'}
                  </Text>
                </View>
                {/* User Password */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 0.25 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'User Password'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Language */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 0.25, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Language'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Crawl Attachments */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.25, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Crawl Attachments'}
                  </Text>
                </View>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { borderColor: palettes.App.Outline },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetCrawlerSourceGET
                  handlers={{
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  page={1}
                >
                  {({ loading, error, data, refetchGetCrawlerSource }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'HrydKygq'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReached={() => {
                          const handler = async () => {
                            console.log('List ON_END_REACHED Start');
                            let error = null;
                            try {
                              console.log('Start ON_END_REACHED:0 CONSOLE_LOG');
                              console.log(nextPage);
                              console.log(
                                'Complete ON_END_REACHED:0 CONSOLE_LOG'
                              );
                              console.log(
                                'Start ON_END_REACHED:1 CONDITIONAL_STOP'
                              );
                              if (nextPage === null) {
                                return console.log(
                                  'Complete ON_END_REACHED:1 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:1 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:2 FETCH_REQUEST'
                              );
                              const newData = (
                                await AdminGroupApi.getCrawlerSourceGET(
                                  Constants,
                                  { page: nextPage }
                                )
                              )?.json;
                              console.log(
                                'Complete ON_END_REACHED:2 FETCH_REQUEST',
                                { newData }
                              );
                              console.log(
                                'Start ON_END_REACHED:3 SET_VARIABLE'
                              );
                              setNextPage(newData?.nextPage);
                              console.log(
                                'Complete ON_END_REACHED:3 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:4 SET_VARIABLE'
                              );
                              setLastPage(newData?.pageTotal);
                              console.log(
                                'Complete ON_END_REACHED:4 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:5 CONDITIONAL_STOP'
                              );
                              if (
                                fetchData?.items ===
                                (0 || crawlerSourceList !== fetchData?.items)
                              ) {
                                return console.log(
                                  'Complete ON_END_REACHED:5 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:5 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:6 SET_VARIABLE'
                              );
                              setCrawlerSourceList(
                                crawlerSourceList.concat(newData?.items)
                              );
                              console.log(
                                'Complete ON_END_REACHED:6 SET_VARIABLE'
                              );
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'List ON_END_REACHED Complete',
                              error ? { error } : 'no error'
                            );
                          };
                          handler();
                        }}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'auto',
                                  },
                                  backgroundColor: getColorByIndex(index),
                                  flexDirection: 'row',
                                  width: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 1200,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* In Prod. */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        borderBottomWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderColor: palettes.App.Outline,
                                        borderLeftWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderRightWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.15,
                                          },
                                        ],
                                        height: '100%',
                                        justifyContent: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Checkbox
                                      color={StyleSheet.getWidthValue(
                                        [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: theme.colors.text.strong,
                                          },
                                        ],
                                        dimensions.width
                                      )}
                                      defaultValue={listData?.in_production}
                                      disabled={true}
                                      size={StyleSheet.getWidthValue(
                                        [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 20,
                                          },
                                        ],
                                        dimensions.width
                                      )}
                                      uncheckedColor={StyleSheet.getWidthValue(
                                        [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: theme.colors.text.strong,
                                          },
                                        ],
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Action */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    borderBottomWidth: 1,
                                    borderColor: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: palettes.App.Outline,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App.Outline,
                                      },
                                    ],
                                    borderRightWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0.25,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 0.25,
                                      },
                                    ],
                                    height: '100%',
                                    justifyContent: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <TableActionsBlock
                                  onPressEdit={() => {
                                    try {
                                      navigation.navigate(
                                        'CrawlerSourceNavigator',
                                        {
                                          screen: 'CrawlerSourceEditScreen',
                                          params: { id: listData?.id },
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  showDelete={false}
                                />
                              </View>
                              {/* Source Name */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flex: 0.25,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.source_name}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Base URL */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flex: 0.25,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.base_url}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* User Email */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 0.5,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0.5,
                                      },
                                    ],
                                    height: '100%',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Table Row'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Table Row']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.user_email}
                                </Text>
                              </View>
                              {/* User Password */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 0.25,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.user_password}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Language */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flex: 0.25,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.language}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Crawl Attachments */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    borderBottomWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                    flex: 0.25,
                                    height: '100%',
                                    justifyContent: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Checkbox
                                  color={StyleSheet.getWidthValue(
                                    [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors.text.strong,
                                      },
                                    ],
                                    dimensions.width
                                  )}
                                  defaultValue={listData?.crawl_attachments}
                                  disabled={true}
                                  size={StyleSheet.getWidthValue(
                                    [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    ],
                                    dimensions.width
                                  )}
                                  uncheckedColor={StyleSheet.getWidthValue(
                                    [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors.text.strong,
                                      },
                                    ],
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                          );
                        }}
                        onEndReachedThreshold={0.5}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value:
                                dimensions.width >= Breakpoints.Laptop
                                  ? 0
                                  : undefined,
                            },
                            maxHeight: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: dimensions.height - 280,
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: dimensions.height - 250,
                              },
                            ],
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    );
                  }}
                </AdminGroupApi.FetchGetCrawlerSourceGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                (
                  await adminGroupDeleteNewsletterDELETE.mutateAsync({
                    newsletter_id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'crawler source'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(CrawlerSourceListingScreen);
