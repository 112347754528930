import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  acc_pressed: false,
  activePage: {
    cfs: false,
    pepf: false,
    peers: false,
    events: false,
    stocks: false,
    advisors: false,
    newsletter: true,
  },
  AUTH_HEADER:
    'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.B6WI4KXwngjhTzAs855Oy0O8OrQoQqw7lieJaPAq7ptiqoROUmgbaof8DHP-mfgBYxjWvYokpTcca-9vRA1fB-0FN15NMrlQ.pFWL9NLIOBKm6bFPK9rHog.DqTmPSf7LIB1GNlMmqWfjEhYWwDI5eCmwW270QE_ru6NgrQS-ZRlFrW4oIdd-6quKz5Qkvq-O7BtnBM_RRyrtZvQHKQM4Oskge-7N0-Iwcg7oc6mIH17U-gxwkV6go2Eu-Oxg9Cb7cj1QQ_oHogzOiZuFMtln7SFyhpR_rqABKE.P5JHaSlRUvT0oDN-nJTzNB745UylOlkIUTrJ8juGzco',
  currentScreen: 'Newsletters',
  ME: null,
  originPage: '',
  pageName: 'M&A Insights',
  RESET_AUTH_HEADER: '',
  screenParamName: '',
  screenParamValue: '',
  selectedPage: '',
  subPage: false,
  top_nav_pressed: false,
  __env__: 'Development',
};
export const AppVariables = {
  ACCESSIBLE_REGIONS_OPTIONS: [
    { label: 'Nordic', value: 'Nordic' },
    { label: 'DACH', value: 'DACH' },
    { label: 'RoW', value: 'RoW' },
  ],
  ADVISOR_TYPE_OPTIONS: [
    { label: 'Corporate Finance', value: 'Corporate Finance' },
    { label: 'Legal Counsel', value: 'Legal Counsel' },
  ],
  ALL_INVESTORS_OPTIONS: [],
  CFS_STAGE_OPTIONS: [
    { label: 'Coming', value: 'Coming' },
    { label: 'Live', value: 'Live' },
    { label: 'Paused', value: 'Paused' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Not Relevant', value: 'Not Relevant' },
  ],
  COMPANY_TYPE_OPTIONS: [
    { label: 'Private Equity', value: 'Private Equity' },
    {
      label: 'Private Debt/Leveraged Finance',
      value: 'Private Debt/Leveraged Finance',
    },
    { label: 'Strategy Consulting', value: 'Strategy Consulting' },
    { label: 'Financial DD', value: 'Financial DD' },
    { label: 'Law Firm', value: 'Law Firm' },
    { label: 'M&A Insurance', value: 'M&A Insurance' },
    { label: 'Wealth Management', value: 'Wealth Management' },
    { label: 'Corporate Finance', value: 'Corporate Finance' },
    { label: 'Private Debt', value: 'Private Debt' },
  ],
  COUNTRY_OPTIONS: [
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Finland', value: 'Finland' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Austria', value: 'Austria' },
    { label: 'RoW', value: 'RoW' },
  ],
  CURRENCY_OPTIONS: [
    { label: 'DKK', value: 'DKK' },
    { label: 'SEK', value: 'SEK' },
    { label: 'NOK', value: 'NOK' },
    { label: 'EUR', value: 'EUR' },
    { label: 'CHF', value: 'CHF' },
    { label: 'USD', value: 'USD' },
    { label: 'GBP', value: 'GBP' },
    { label: 'n.m.', value: 'n.m.' },
  ],
  EVENT_STATUS_OPTIONS: [
    { label: 'Staging', value: 'Staging' },
    { label: 'Not Relevant', value: 'Not Relevant' },
    { label: 'Published', value: 'Published' },
    { label: 'Production', value: 'Production' },
  ],
  EVENT_TYPE_OPTIONS: [
    { label: 'Acquisition Agenda', value: 'Acquisition Agenda' },
    { label: 'Capital Raise', value: 'Capital Raise' },
    { label: 'Debt Issue', value: 'Debt Issue' },
    { label: 'Future Opportunity', value: 'Future Opportunity' },
    { label: 'IPO', value: 'IPO' },
    { label: 'Majority Sale', value: 'Majority Sale' },
    { label: 'Minority Sale', value: 'Minority Sale' },
    { label: 'Other', value: 'Other' },
    { label: 'Share Issue', value: 'Share Issue' },
  ],
  EV_SOURCE_OPTIONS: [
    { label: 'NKP Proprietary', value: 'NKP Proprietary' },
    { label: 'Publicly Confirmed', value: 'Publicly Confirmed' },
    { label: 'Media Intelligence', value: 'Media Intelligence' },
    { label: 'n.m.', value: 'n.m.' },
  ],
  FUND_TYPE_OPTIONS: [
    { label: 'Buyout Fund', value: 'Buyout Fund' },
    { label: 'Growth Equity Fund', value: 'Growth Equity Fund' },
    { label: 'Venture Capital Fund', value: 'Venture Capital Fund' },
  ],
  HORIZON_OPTIONS: [
    { label: 'Close-end', value: 'Close-end' },
    { label: 'Open-end', value: 'Open-end' },
  ],
  INVESTOR_TYPE_OPTIONS: [
    { label: 'Private Equity Firm', value: 'Private Equity Firm' },
    { label: 'Private Individual', value: 'Private Individual' },
    { label: 'Strategic', value: 'Strategic' },
    { label: 'Public Markets', value: 'Public Markets' },
    { label: 'Other', value: 'Other' },
    { label: 'Other Investment Firms', value: 'Other Investment Firms' },
    { label: 'Government', value: 'Government' },
  ],
  LANGUAGE_OPTIONS: [
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'English', value: 'English' },
    { label: 'French', value: 'French' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'German', value: 'German' },
  ],
  PAGES_LINKING_OPTIONS: [
    { label: 'Event', value: 'event' },
    { label: 'Newsletter', value: 'newsletter' },
    { label: 'Potd', value: 'potd' },
    { label: 'Peer Group', value: 'peer_group' },
    { label: 'CFS', value: 'cfs' },
    { label: 'PEPF', value: 'pepf' },
    { label: 'Fund', value: 'fund' },
    { label: 'Investor', value: 'investor' },
    { label: 'Advisors', value: 'advisor' },
    { label: 'Crawler_Source', value: 'crawler_source' },
    { label: 'Crawler_Keyword', value: 'crawler_keyword' },
    { label: 'Crawler_Result', value: 'crawler_result' },
    { label: 'Prop_Case', value: 'prop_case' },
    { label: 'Prop_Schedule', value: 'prop_schedule' },
    { label: 'Trending_Topic', value: 'trending_topic' },
    { label: 'Users clients', value: 'users_clients' },
  ],
  PAGES_OPTIONS: [
    { label: 'Event', value: 'EventListingScreen' },
    { label: 'Newsletter', value: 'NewsletterListingScreen' },
    { label: 'Potd', value: 'POTDListingScreen' },
    { label: 'Peer Groups', value: 'PeerGroupListingScreen' },
    { label: 'CFS', value: 'CFSListingScreen' },
    { label: 'PEPF', value: 'PEPFListingScreen' },
    { label: 'Fund', value: 'FundListingScreen' },
    { label: 'Investor', value: 'InvestorListingScreen' },
    { label: 'Advisors', value: 'AdvisorListingScreen' },
    { label: 'Crawler_Source', value: 'CrawlerSourceListingScreen' },
    { label: 'Crawler_Keyword', value: 'CrawlerKeywordListingScreen' },
    { label: 'Crawler_Result', value: 'CrawlerResultListingScreen' },
    { label: 'Prop_Case', value: 'PropCaseListingScreen' },
    { label: 'Prop_Schedule', value: 'PropScheduleListingScreen' },
    { label: 'Trending_Topic', value: 'TrendingTopicListingScreen' },
    { label: 'Users clients', value: 'UsersClientsListingScreen' },
  ],
  PEER_GROUP_ACCESS_TYPE_OPTIONS: [
    { label: 'NKP comps', value: 'NKP comps' },
    { label: 'Private', value: 'Private' },
  ],
  PEPF_STAKE_TYPE_OPTIONS: [
    { label: 'Majority', value: 'Majority' },
    { label: 'Minority', value: 'Minority' },
  ],
  REGION_OPTIONS: [
    { label: 'Nordic', value: 'Nordic' },
    { label: 'DACH', value: 'DACH' },
    { label: 'RoW', value: 'RoW' },
  ],
  SEND_SS_NOTIF: '',
  SOURCE_TYPE_OPTIONS: [
    { label: 'NKP Proprietary', value: 'NKP Proprietary' },
    { label: 'Publicly Confirmed', value: 'Publicly Confirmed' },
    { label: 'Media Intelligence', value: 'Media Intelligence' },
  ],
  SS_SCREEN_NAME: '',
  SS_SUBSCRIPTION: '',
  STATUS_OPTIONS: [
    { label: 'Draft', value: 'Draft' },
    { label: 'Published', value: 'Published' },
  ],
  TEST_SELECTED_ITEMS: [
    { id: 1, title: 'Item One' },
    { id: 2, title: 'Item Two' },
    { id: 3, title: 'Item Three' },
    { id: 4, title: 'Item Four' },
    { id: 5, title: 'Item Five' },
  ],
  USERS_CLIENTS_OPTIONS: [],
  USER_STATUS_OPTIONS: [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ],
  VERSION_DACH: 'DACH',
  VERSION_NORDIC: 'Nordic',
  WATCHED_EVENT_IDX: -1,
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
