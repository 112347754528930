import React from 'react';
import {
  Icon,
  LinearGradient,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import CommonHeaderBlock from '../components/CommonHeaderBlock';
import CustomMultiSelectPickerBlock from '../components/CustomMultiSelectPickerBlock';
import CustomRangeDatePickerBlock from '../components/CustomRangeDatePickerBlock';
import LoadingBlock from '../components/LoadingBlock';
import ModalDeleteConfirmationBlock from '../components/ModalDeleteConfirmationBlock';
import TableActionsBlock from '../components/TableActionsBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as ReadMoreText from '../custom-files/ReadMoreText';
import convertDateRangeToFilters from '../global-functions/convertDateRangeToFilters';
import convertToOptions from '../global-functions/convertToOptions';
import formatTimestampToCopenhagen from '../global-functions/formatTimestampToCopenhagen';
import getColorByIndex from '../global-functions/getColorByIndex';
import getColorForStatus from '../global-functions/getColorForStatus';
import toastMessage from '../global-functions/toastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const EventListingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [country_filter, setCountry_filter] = React.useState([]);
  const [dach, setDach] = React.useState(false);
  const [date_range_filter, setDate_range_filter] = React.useState([]);
  const [deletingItem, setDeletingItem] = React.useState(null);
  const [end_date_filter, setEnd_date_filter] = React.useState('');
  const [event, setEvent] = React.useState(false);
  const [eventsList, setEventsList] = React.useState([]);
  const [gics_filter, setGics_filter] = React.useState([]);
  const [gics_options, setGics_options] = React.useState([]);
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] =
    React.useState(false);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [keywordSearch_raw, setKeywordSearch_raw] = React.useState('');
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(0);
  const [nordic, setNordic] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageTotal, setPageTotal] = React.useState(0);
  const [region_filter, setRegion_filter] = React.useState([]);
  const [screenCode, setScreenCode] = React.useState('');
  const [start_date_filter, setStart_date_filter] = React.useState('');
  const [status_filter, setStatus_filter] = React.useState([]);
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const shouldShowResetFilter = () => {
    if (
      status_filter?.length > 0 ||
      country_filter?.length > 0 ||
      gics_filter?.length > 0 ||
      region_filter?.length > 0 ||
      status_filter?.length > 0 ||
      start_date_filter ||
      end_date_filter
    ) {
      return true;
    }
    return false;
  };
  const adminGroupDeleteEventDELETE = AdminGroupApi.useDeleteEventDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setNextPage(1);
        setEventsList([]);
        const result = (
          await AdminGroupApi.getGicsSubIndustryGET(Constants, {})
        )?.json;
        setGics_options(
          convertToOptions(result, 'GICS_Sector', 'id', 'GICS_Sub_Industry')
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.App['Custom Color 7'],
          },
        },
        dimensions.width
      )}
    >
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Background'].props}
        endX={0}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Background'].style,
          dimensions.width
        )}
      >
        <CommonHeaderBlock
          onPressAdd={() => {
            try {
              navigation.navigate('EventNavigator', {
                screen: 'EventCreateScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          addButtonLabel={'ADD EVENT'}
          showAddButton={true}
        />
        {/* Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: palettes.App.Peoplebit_White,
              },
              borderRadius: { minWidth: Breakpoints.Tablet, value: 20 },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              margin: { minWidth: Breakpoints.Tablet, value: 20 },
              marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              marginTop: { minWidth: Breakpoints.Tablet, value: 0 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Search Bar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 10 },
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  gap: { minWidth: Breakpoints.Laptop, value: 15 },
                  maxWidth: 1200,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Wrapper */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: 10,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  onChangeText={newTextInputValue => {
                    try {
                      if (!newTextInputValue) {
                        setKeywordSearch(newTextInputValue);
                      } else {
                      }

                      setKeywordSearch_raw(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newTextInputValue => {
                    try {
                      setKeywordSearch(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  autoCapitalize={'sentences'}
                  changeTextDelay={300}
                  clearButtonMode={'while-editing'}
                  placeholder={'Search...'}
                  returnKeyType={'search'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Common TextInput']
                        .style,
                      { backgroundColor: palettes.App['Custom Color 7'] }
                    ),
                    dimensions.width
                  )}
                  value={keywordSearch_raw}
                />
              </View>
              {/* Filter Row 1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* Status */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Status'}
                  </Text>
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setStatus_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['EVENT_STATUS_OPTIONS']}
                    placeholder={'Select status'}
                    selectedOptions={status_filter}
                  />
                </View>
                {/* Country */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Country'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setCountry_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['COUNTRY_OPTIONS']}
                    placeholder={'Select country'}
                    selectedOptions={country_filter}
                  />
                </View>
                {/* Region */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Region'}
                  </Text>
                  {/* Custom Multi Select Picker 2 */}
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setRegion_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['REGION_OPTIONS']}
                    placeholder={'Select region'}
                    selectedOptions={region_filter}
                  />
                </View>
              </View>
              {/* Filter Row 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                {/* Gics */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'GICS'}
                  </Text>
                  <CustomMultiSelectPickerBlock
                    onChange={selectedValues => {
                      try {
                        setGics_filter(selectedValues);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={gics_options}
                    placeholder={'Select gics'}
                    selectedOptions={gics_filter}
                  />
                </View>
                {/* Date */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Table Row'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_600SemiBold',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Date'}
                  </Text>
                  <CustomRangeDatePickerBlock
                    onChange={(startDate, endDate) => {
                      try {
                        setStart_date_filter(startDate);
                        setEnd_date_filter(endDate);
                        setNextPage(1);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    endDate={end_date_filter}
                    startDate={start_date_filter}
                  />
                </View>
                {/* Blank */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {!shouldShowResetFilter() ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Table Row'].style,
                              {
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_600SemiBold',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Reset filter'}
                        </Text>

                        <Pressable
                          onPress={() => {
                            try {
                              setStatus_filter([]);
                              setCountry_filter([]);
                              setRegion_filter([]);
                              setDate_range_filter([]);
                              setGics_filter([]);
                              setStart_date_filter(null);
                              setEnd_date_filter(null);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 8,
                                },
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 50,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 50,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={StyleSheet.getWidthValue(
                                [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.White,
                                  },
                                ],
                                dimensions.width
                              )}
                              name={'MaterialCommunityIcons/window-close'}
                              size={StyleSheet.getWidthValue(
                                [{ minWidth: Breakpoints.Laptop, value: 26 }],
                                dimensions.width
                              )}
                            />
                          </View>
                        </Pressable>
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                marginTop: 15,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 15 },
                  { minWidth: Breakpoints.Tablet, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Laptop, value: 1 },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Table Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color 7'],
                    borderColor: palettes.App.Outline,
                    borderWidth: 1,
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 0.4, flexDirection: 'row', padding: 10 },
                    dimensions.width
                  )}
                />
                {/* Created at */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Created at'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Published */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Published'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Target */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.7 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Target'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Headline */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, padding: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Table Title'].style,
                      dimensions.width
                    )}
                  >
                    {'Headline'}
                  </Text>
                </View>
                {/* Summary */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Summary'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Country */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Country'}
                      </Text>
                    </View>
                  )}
                </>
                {/* GICS */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'GICS'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Original Headline of Source */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Original headline of source'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Source Link */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 0.5 },
                          ],
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Source Link'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Status */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Table Title'].style,
                          dimensions.width
                        )}
                      >
                        {'Status'}
                      </Text>
                    </View>
                  )}
                </>
              </View>
              {/* Table Content */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: { minWidth: Breakpoints.Laptop, value: 1 } },
                  dimensions.width
                )}
              >
                <AdminGroupApi.FetchGetEventsGET
                  country={
                    country_filter?.length > 0 ? country_filter : undefined
                  }
                  date_range={
                    convertDateRangeToFilters(
                      start_date_filter,
                      end_date_filter
                    )?.length > 0
                      ? convertDateRangeToFilters(
                          start_date_filter,
                          end_date_filter
                        )
                      : undefined
                  }
                  gics_sub_industry={
                    gics_filter?.length > 0 ? gics_filter : undefined
                  }
                  handlers={{
                    on401: fetchData => {
                      try {
                        /* hidden 'Show Alert' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Navigate' action */
                      } catch (err) {
                        console.error(err);
                      }
                    },
                    onData: fetchData => {
                      try {
                        if (fetchData?.items !== eventsList) {
                          setEventsList(fetchData?.items);
                        } else {
                        }

                        setNextPage(fetchData?.nextPage);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  keyword={keywordSearch}
                  page={1}
                  per_page={25}
                  region={region_filter?.length > 0 ? region_filter : undefined}
                  status={status_filter?.length > 0 ? status_filter : undefined}
                >
                  {({ loading, error, data, refetchGetEvents }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <LoadingBlock />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={eventsList}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'IJDmWcLS'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReached={() => {
                          const handler = async () => {
                            console.log('List ON_END_REACHED Start');
                            let error = null;
                            try {
                              console.log(
                                'Start ON_END_REACHED:0 CONDITIONAL_STOP'
                              );
                              if (nextPage === null) {
                                return console.log(
                                  'Complete ON_END_REACHED:0 CONDITIONAL_STOP'
                                );
                              } else {
                                console.log(
                                  'Skipped ON_END_REACHED:0 CONDITIONAL_STOP: condition not met'
                                );
                              }
                              console.log(
                                'Start ON_END_REACHED:1 FETCH_REQUEST'
                              );
                              const newData = (
                                await AdminGroupApi.getEventsGET(Constants, {
                                  country:
                                    country_filter?.length > 0
                                      ? country_filter
                                      : undefined,
                                  date_range:
                                    convertDateRangeToFilters(
                                      start_date_filter,
                                      end_date_filter
                                    )?.length > 0
                                      ? convertDateRangeToFilters(
                                          start_date_filter,
                                          end_date_filter
                                        )
                                      : undefined,
                                  gics_sub_industry:
                                    gics_filter?.length > 0
                                      ? gics_filter
                                      : undefined,
                                  keyword: keywordSearch,
                                  page: nextPage,
                                  per_page: 25,
                                  region:
                                    region_filter?.length > 0
                                      ? region_filter
                                      : undefined,
                                  status:
                                    status_filter?.length > 0
                                      ? status_filter
                                      : undefined,
                                })
                              )?.json;
                              console.log(
                                'Complete ON_END_REACHED:1 FETCH_REQUEST',
                                { newData }
                              );
                              console.log(
                                'Start ON_END_REACHED:2 SET_VARIABLE'
                              );
                              setNextPage(newData?.nextPage);
                              console.log(
                                'Complete ON_END_REACHED:2 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:3 SET_VARIABLE'
                              );
                              setLastPage(newData?.lastPage);
                              console.log(
                                'Complete ON_END_REACHED:3 SET_VARIABLE'
                              );
                              console.log(
                                'Start ON_END_REACHED:4 SET_VARIABLE'
                              );
                              setEventsList(eventsList.concat(newData?.items));
                              console.log(
                                'Complete ON_END_REACHED:4 SET_VARIABLE'
                              );
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'List ON_END_REACHED Complete',
                              error ? { error } : 'no error'
                            );
                          };
                          handler();
                        }}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'auto',
                                  },
                                  backgroundColor: getColorByIndex(index),
                                  borderColor: palettes.App.Outline,
                                  borderLeftWidth: 1,
                                  flexDirection: 'row',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Actions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: 0.4,
                                    flexDirection: 'row',
                                    gap: 10,
                                    height: '100%',
                                    justifyContent: 'center',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <TableActionsBlock
                                  onPressDelete={() => {
                                    try {
                                      setDeletingItem(listData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  onPressEdit={() => {
                                    try {
                                      navigation.navigate('EventNavigator', {
                                        screen: 'EventEditScreen',
                                        params: { id: listData?.id },
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  border={false}
                                />
                              </View>
                              {/* Created at */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: 1,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {formatTimestampToCopenhagen(
                                        listData?.created_at
                                      )}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Published */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: 1,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {formatTimestampToCopenhagen(
                                        listData?.published
                                      )}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Target */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.7,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.target}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Headline */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: palettes.App.Outline,
                                    borderRightWidth: 1,
                                    flex: 1,
                                    height: '100%',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Utils.CustomCodeErrorBoundary>
                                  <ReadMoreText.Index>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.headline}
                                    </Text>
                                  </ReadMoreText.Index>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                              {/* Summary */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: 1,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Utils.CustomCodeErrorBoundary>
                                      <ReadMoreText.Index>
                                        {/* description */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.description}
                                        </Text>
                                      </ReadMoreText.Index>
                                    </Utils.CustomCodeErrorBoundary>
                                  </View>
                                )}
                              </>
                              {/* Country */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* country */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.country}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* GICS */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* gics */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Table Row'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        listData?._gics_sub_industry_single
                                          ?.GICS_Industry
                                      }
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Original headline */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Utils.CustomCodeErrorBoundary>
                                      <ReadMoreText.Index>
                                        {/* original headline */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Table Row'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.original_headline}
                                        </Text>
                                      </ReadMoreText.Index>
                                    </Utils.CustomCodeErrorBoundary>
                                  </View>
                                )}
                              </>
                              {/* Source Link */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0.5,
                                          },
                                        ],
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await WebBrowser.openBrowserAsync(
                                              `${listData?.source_link}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      selectable={false}
                                      {...GlobalStyles.LinkStyles(theme)['Link']
                                        .props}
                                      numberOfLines={5}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.LinkStyles(theme)['Link']
                                          .style,
                                        dimensions.width
                                      )}
                                      title={`${listData?.source_link}`}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Status */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.Outline,
                                        borderRightWidth: 1,
                                        flex: 1,
                                        height: '100%',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Table Row'
                                      ].props}
                                      ellipsizeMode={'tail'}
                                      numberOfLines={1}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Table Row'
                                          ].style,
                                          {
                                            color: getColorForStatus(
                                              listData?.status
                                            ),
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.status}
                                    </Text>
                                  </View>
                                )}
                              </>
                            </View>
                          );
                        }}
                        onEndReachedThreshold={0.01}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value:
                                dimensions.width >= Breakpoints.Laptop
                                  ? 0
                                  : undefined,
                            },
                            maxHeight: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: dimensions.height - 280,
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: dimensions.height - 250,
                              },
                            ],
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    );
                  }}
                </AdminGroupApi.FetchGetEventsGET>
              </View>
            </View>
          </View>
        </View>
        <ModalDeleteConfirmationBlock
          onCancel={() => {
            try {
              setDeletingItem(null);
            } catch (err) {
              console.error(err);
            }
          }}
          onConfirmDelete={() => {
            const handler = async () => {
              try {
                setIsLoadingConfirmDelete(true);
                const resultDeleteNewsletter = (
                  await adminGroupDeleteEventDELETE.mutateAsync({
                    event_id: deletingItem?.id,
                  })
                )?.json;
                setIsLoadingConfirmDelete(false);
                if (resultDeleteNewsletter?.error) {
                  toastMessage(resultDeleteNewsletter?.message, 'error');
                } else {
                  toastMessage('Delete event successfully', 'success');
                }

                setDeletingItem(null);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          isLoadingConfirm={isLoadingConfirmDelete}
          targetName={'event'}
          visible={deletingItem !== null}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(EventListingScreen);
