import React from 'react';
import { Button, TextInput, withTheme } from '@draftbit/ui';
import { Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  isLoadingConfirm: null,
  onCancel: () => {},
  onConfirmDelete: () => {},
  targetName: null,
  visible: null,
};

const ModalDeleteConfirmationBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [confirmationText, setConfirmationText] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <Modal
      supportedOrientations={['portrait', 'landscape']}
      animationType={'fade'}
      transparent={true}
      visible={props.visible ?? defaultProps.visible}
    >
      {/* Overlay */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Overlay,
            height: '100%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      />
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flex: 1, justifyContent: 'center' },
          dimensions.width
        )}
      >
        {/* Card */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.White,
              },
              borderRadius: 15,
              gap: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 30 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.Mobile, value: 320 },
                { minWidth: Breakpoints.Laptop, value: 400 },
              ],
              paddingBottom: [
                { minWidth: Breakpoints.Mobile, value: 40 },
                { minWidth: Breakpoints.Laptop, value: 40 },
              ],
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 40 },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 40 },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Mobile, value: 40 },
                { minWidth: Breakpoints.Laptop, value: 60 },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Common Strong Inverse'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Common Strong Inverse'].style,
                {
                  color: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors.text.strong,
                  },
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: 'Quicksand_600SemiBold',
                    },
                  ],
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                  ],
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Are you completely sure you want to delete this '}
            {props.targetName ?? defaultProps.targetName}
            {'? It cannot be restored. If so type '}
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Common Strong Inverse'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Common Strong Inverse'].style,
                  {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.Brand.Error,
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: 'Quicksand_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Quicksand_600SemiBold',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 18 },
                    ],
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'“DELETE” '}
              {/* Text 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Common Strong Inverse']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Common Strong Inverse']
                      .style,
                    {
                      color: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors.text.strong,
                      },
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Quicksand_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_600SemiBold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 18 },
                      ],
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'below.'}
              </Text>
            </Text>
          </Text>
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInputValue => {
              const textInputValue = newTextInputValue;
              try {
                setConfirmationText(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Common TextInput'].props}
            placeholder={'Type'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Common TextInput'].style,
                { backgroundColor: palettes.Brand.Strong_Inverse }
              ),
              dimensions.width
            )}
            value={confirmationText}
          />
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, flexDirection: 'row', gap: 20 },
              dimensions.width
            )}
          >
            {/* Delete Button */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  props.onConfirmDelete?.();
                  setConfirmationText('');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
              disabled={
                confirmationText !== 'DELETE' ||
                (props.isLoadingConfirm ?? defaultProps.isLoadingConfirm)
              }
              disabledOpacity={0.5}
              loading={props.isLoadingConfirm ?? defaultProps.isLoadingConfirm}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.Brand.Error,
                    },
                  }
                ),
                dimensions.width
              )}
              title={'Delete'}
            />
            {/* Cancel Button */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  setConfirmationText('');
                  props.onCancel?.();
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
                dimensions.width
              )}
              title={'Cancel'}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default withTheme(ModalDeleteConfirmationBlock);
