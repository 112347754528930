import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';

const OverlayContext = createContext();

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider = ({ children }) => {
  const [dropdown, setDropdown] = useState(null);
  const closeDropdownRef = useRef(null);

  const handleCloseDropdown = useCallback(() => {
    setDropdown(null);
    if (closeDropdownRef.current) {
      closeDropdownRef.current();
    }
  }, []);

  const setDropdownWithClose = useCallback((dropdownComponent, onClose) => {
    closeDropdownRef.current = onClose;
    setDropdown(dropdownComponent);
  }, []);

  return (
    <OverlayContext.Provider
      value={{
        setDropdown: setDropdownWithClose,
        closeDropdown: handleCloseDropdown,
      }}
    >
      {children}
      {dropdown && (
        <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
          <TouchableWithoutFeedback onPress={handleCloseDropdown}>
            <View style={StyleSheet.absoluteFill} />
          </TouchableWithoutFeedback>
          <View pointerEvents="box-none" style={StyleSheet.absoluteFill}>
            {dropdown}
          </View>
        </View>
      )}
    </OverlayContext.Provider>
  );
};

const styles = StyleSheet.create({
  blackOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
