import React from 'react';
import { DatePicker, Surface, TextInput, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import FormActionsBlock from '../components/FormActionsBlock';
import FuzzyInputWithDataBlock from '../components/FuzzyInputWithDataBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import borderColorSmallDevice from '../global-functions/borderColorSmallDevice';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import customFormatDate from '../global-functions/customFormatDate';
import flexOnlySmallDevice from '../global-functions/flexOnlySmallDevice';
import rowOnlySmallDevice from '../global-functions/rowOnlySmallDevice';
import transformToSearchInputFormat from '../global-functions/transformToSearchInputFormat';
import transformToSearchInputObject from '../global-functions/transformToSearchInputObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  initialData: { id: 150, created_at: 1727883175504 },
  isSubmitting: false,
  onSubmit: () => {},
};

const POTDFormBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [advisor, setAdvisor] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.advisor
  );
  const [createdAt, setCreatedAt] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.created_at
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [financials, setFinancials] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.financials
  );
  const [gics, setGics] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.gics
  );
  const [headline, setHeadline] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.headline
  );
  const [hq, setHq] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.hq
  );
  const [peer_group, setPeer_group] = React.useState(
    transformToSearchInputObject(
      (props.initialData ?? defaultProps.initialData)?._peer_group_single,
      'title',
      'id',
      undefined
    )
  );
  const [stage, setStage] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.stage
  );
  const [story_company_profile, setStory_company_profile] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.story_company_profile
  );
  const [story_comps, setStory_comps] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.story_comps
  );
  const [story_opportunity, setStory_opportunity] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.story_opportunity
  );
  const [suggestionPeerGroups, setSuggestionPeerGroups] = React.useState([]);
  const [target, setTarget] = React.useState(
    (props.initialData ?? defaultProps.initialData)?.target
  );
  const [date, setDate] = React.useState(new Date());
  const [textInputValue, setTextInputValue] = React.useState('');
  const validateForm = () => {
    const requiredFields = {
      createdAt: 'Created At',
      headline: 'Headline',
      target: 'Target',
      financials: 'Financials',
      advisor: 'Advisor',
      stage: 'Deal Stage',
      hq: 'Headquarter',
      gics: 'GICS Sub Industry',
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key, _]) => {
        const value = eval(key);
        return Array.isArray(value) ? value.length === 0 : !value;
      })
      .map(([_, friendlyName]) => friendlyName);

    if (missingFields.length === 0) {
      setErrorMessage('');
      return true;
    }
    const errorMessage = `Please complete the following field${
      missingFields.length > 1 ? 's' : ''
    }: ${missingFields.join(', ')}`;
    setErrorMessage(errorMessage);
    return false;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', margin: 15 },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { maxWidth: 1200, width: '100%' },
          dimensions.width
        )}
      >
        {/* Table Header */}
        <>
          {dimensions.width >= Breakpoints.Tablet ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: palettes.App.Outline,
                  borderWidth: 1,
                  flexDirection: 'row',
                },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Field'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.7, padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Value'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 1 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Tablet,
                  value: palettes.App.Outline,
                },
                borderTopWidth: [
                  { minWidth: Breakpoints.Tablet, value: 1 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                flexDirection: columnOnlySmallDevice(dimensions),
              },
              dimensions.width
            )}
          >
            {/* Created at */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Created at *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    const date = newDatePickerValue;
                    try {
                      setCreatedAt(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                    .props}
                  date={customFormatDate(createdAt)}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['defaultDatePicker']
                      .style,
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Headline */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Headline *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setHeadline(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  placeholder={'Input headline'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={headline}
                />
              </View>
            </View>
            {/* Company Profile */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Company Profile '}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setStory_company_profile(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  placeholder={'Input company profile'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={story_company_profile}
                />
              </View>
            </View>
          </View>
          {/* Row 2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Opportunity */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Opportunity '}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    const textInputValue = newTextAreaValue;
                    try {
                      setStory_opportunity(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  placeholder={'Input oppotunity'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={story_opportunity}
                />
              </View>
            </View>
            {/* Comps & Precedents */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Comps & Precedents '}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setStory_comps(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextArea']
                    .props}
                  placeholder={'Input comps & precedents'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextArea']
                      .style,
                    dimensions.width
                  )}
                  value={story_comps}
                />
              </View>
            </View>
          </View>
        </Surface>
        {/* Surface 2 */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={2}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* Row 3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Target */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Target *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setTarget(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={target}
                />
              </View>
            </View>
            {/* Financials */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Financials *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setFinancials(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={financials}
                />
              </View>
            </View>
            {/* Advisor */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Advisor *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setAdvisor(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={advisor}
                />
              </View>
            </View>
            {/* Deal Stage */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Deal Stage *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setStage(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={stage}
                />
              </View>
            </View>
          </View>
          {/* Row 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: columnOnlySmallDevice(dimensions) },
              dimensions.width
            )}
          >
            {/* Headquarters */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Headquarters *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setHq(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={hq}
                />
              </View>
            </View>
            {/* Peer Group */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'Peer Group '}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <FuzzyInputWithDataBlock
                  getSuggestions={searchKeyword => {
                    const handler = async () => {
                      try {
                        const resultPeerGroups = (
                          await AdminGroupApi.getPeerGroupGET(Constants, {
                            keyword: searchKeyword,
                            page: 1,
                            per_page: 25,
                          })
                        )?.json;
                        const suggestionPeerGroups =
                          transformToSearchInputFormat(
                            resultPeerGroups?.items,
                            'title',
                            'id',
                            undefined
                          );
                        setSuggestionPeerGroups(suggestionPeerGroups);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onSelect={selectedResult => {
                    try {
                      setPeer_group(selectedResult);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={peer_group}
                  multiSelect={false}
                  selectedItem={peer_group}
                  suggestionsList={suggestionPeerGroups}
                />
              </View>
            </View>
            {/* GICS Sub Industry */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: rowOnlySmallDevice(dimensions) },
                dimensions.width
              )}
            >
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderTopWidth: 0,
                    borderWidth: 1,
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.3 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: flexOnlySmallDevice(dimensions, 0.3),
                      },
                    ],
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Table Title'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Table Title'].style,
                    dimensions.width
                  )}
                >
                  {'GICS Sub Industry *'}
                </Text>
              </View>
              {/* Value */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Outline,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: borderColorSmallDevice(dimensions),
                      },
                    ],
                    borderRightWidth: 1,
                    flex: 0.7,
                    padding: 10,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setGics(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Common TextInput']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Common TextInput']
                      .style,
                    dimensions.width
                  )}
                  value={gics}
                />
              </View>
            </View>
          </View>
        </Surface>
      </View>
      {/* Error message */}
      <>
        {!errorMessage ? null : (
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Table Row'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Table Row'].style,
                {
                  color: theme.colors.background.danger,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: 15,
                  paddingBottom: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 15 },
                  paddingTop: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
                }
              ),
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
        )}
      </>
      <FormActionsBlock
        onCancel={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={() => {
          try {
            const validateFormResult = validateForm();
            if (validateFormResult === true) {
              props.onSubmit?.(
                createdAt,
                headline,
                story_company_profile,
                story_opportunity,
                story_comps,
                target,
                financials,
                advisor,
                stage,
                hq,
                peer_group,
                gics
              );
            } else {
            }
          } catch (err) {
            console.error(err);
          }
        }}
        isSubmitting={props.isSubmitting ?? defaultProps.isSubmitting}
      />
    </View>
  );
};

export default withTheme(POTDFormBlock);
