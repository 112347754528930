import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const PickerStyles = theme =>
  StyleSheet.create({
    'app picker': {
      style: {
        backgroundColor: palettes.App.Peoplebit_White,
        borderColor: palettes.App['Custom Color 3'],
        borderRadius: 8,
        borderWidth: 1,
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: 16,
        height: 60,
        paddingBottom: 5,
        paddingTop: 5,
        textAlign: 'left',
      },
      props: { iconSize: 22, placeholderTextColor: theme.colors.text.light },
    },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    Container: {
      style: {
        alignSelf: 'auto',
        backgroundColor: palettes.App.Peoplebit_White,
        borderRadius: [
          { minWidth: Breakpoints.Mobile, value: 10 },
          { minWidth: Breakpoints.Tablet, value: 20 },
        ],
        borderStyle: 'solid',
        flex: { minWidth: Breakpoints.Laptop, value: 1 },
        margin: { minWidth: Breakpoints.Tablet, value: 20 },
        marginBottom: { minWidth: Breakpoints.Tablet, value: 40 },
        paddingBottom: { minWidth: Breakpoints.Tablet, value: 20 },
        paddingLeft: { minWidth: Breakpoints.Tablet, value: 5 },
        paddingRight: { minWidth: Breakpoints.Tablet, value: 5 },
        paddingTop: { minWidth: Breakpoints.Tablet, value: 20 },
      },
      props: {},
    },
    Surface: {
      style: {
        alignContent: 'center',
        alignSelf: 'auto',
        backgroundColor: palettes.Brand.Surface,
        borderRadius: 10,
        borderStyle: 'solid',
        margin: 15,
      },
      props: {},
    },
    'Surface 2': { style: {}, props: {} },
  });

export const ScreenContainerStyles = theme =>
  StyleSheet.create({ main: { style: {}, props: {} } });

export const TextStyles = theme =>
  StyleSheet.create({
    'Common Strong': {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 14 },
        ],
      },
      props: {},
    },
    'Common Strong Inverse': {
      style: {
        color: palettes.Brand.Strong_Inverse,
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 14 },
        ],
      },
      props: {},
    },
    'Table Row': {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 14 },
        ],
      },
      props: {},
    },
    'Table Title': {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 14 },
        ],
      },
      props: {},
    },
    listed_header_title: {
      style: { fontFamily: 'Quicksand_700Bold', fontSize: 12 },
      props: {},
    },
    screen_title: { style: { color: theme.colors.text.strong }, props: {} },
    screen_title_stock: {
      style: {
        color: palettes.Brand['Strong Inverse'],
        fontFamily: 'Quicksand_400Regular',
        fontSize: 14,
        textAlign: 'left',
      },
      props: {},
    },
    screen_title_stockH: {
      style: {
        color: palettes.Brand['Strong Inverse'],
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 14 },
        ],
      },
      props: {},
    },
    selected: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: theme.colors.branding.primary,
        },
      },
      props: {},
    },
    'watermark text': {
      style: {
        color: palettes.App.Studily_Dark_Primary,
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Laptop, value: 35 },
          { minWidth: Breakpoints.Tablet, value: 30 },
        ],
        opacity: 0.39,
      },
      props: {},
    },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: { style: { color: theme.colors.branding.primary }, props: {} },
  });

export const HStackStyles = theme =>
  StyleSheet.create({
    'H Stack': {
      style: { alignItems: 'center', flexDirection: 'row' },
      props: {},
    },
  });

export const BottomSheetStyles = theme =>
  StyleSheet.create({
    'Bottom Sheet': {
      style: {
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
      },
      props: {},
    },
  });

export const VStackStyles = theme =>
  StyleSheet.create({
    'V Stack': { style: { flexDirection: 'column' }, props: {} },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const SquareStyles = theme =>
  StyleSheet.create({
    Square: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.primary,
        justifyContent: 'center',
      },
      props: {},
    },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const H2Styles = theme =>
  StyleSheet.create({
    H2: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 24,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const ZStackStyles = theme =>
  StyleSheet.create({
    'Z Stack': { style: { flexDirection: 'row' }, props: {} },
  });

export const H3Styles = theme =>
  StyleSheet.create({
    H3: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 18.72,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const H4Styles = theme =>
  StyleSheet.create({
    H4: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 16,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const H5Styles = theme =>
  StyleSheet.create({
    H5: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 13.28,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const ActionSheetItemStyles = theme =>
  StyleSheet.create({
    'Action Sheet Item': { style: { textAlign: 'center' }, props: {} },
  });

export const TableStyles = theme =>
  StyleSheet.create({ Table: { style: { flex: 1 }, props: {} } });

export const TableCellStyles = theme =>
  StyleSheet.create({
    'Table Cell': { style: { flex: 1, flexDirection: 'row' }, props: {} },
  });

export const IconStyles = theme =>
  StyleSheet.create({
    Selected: { style: {}, props: { color: theme.colors.branding.primary } },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({
    'Checkbox Row': {
      style: { minHeight: 50, paddingLeft: 20, paddingRight: 20 },
      props: {},
    },
  });

export const H6Styles = theme =>
  StyleSheet.create({
    H6: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 10.72,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'HTML View': { style: { flex: 1 }, props: {} } });

export const H1Styles = theme =>
  StyleSheet.create({
    H1: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 32,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    side_menu_modal: { style: { minWidth: 300 }, props: {} },
    split_options: {
      style: {
        alignItems: 'flex-start',
        flex: 0,
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8,
        justifyContent: 'flex-start',
      },
      props: {},
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
    'Header menu': {
      style: {
        backgroundColor: [
          {
            minWidth: Breakpoints.Mobile,
            value: theme.colors.branding.primary,
          },
          { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
        ],
        borderColor: {
          minWidth: Breakpoints.Laptop,
          value: theme.colors.branding.primary,
        },
        borderRadius: 8,
        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
        color: {
          minWidth: Breakpoints.Laptop,
          value: theme.colors.branding.primary,
        },
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'System' },
          { minWidth: Breakpoints.Laptop, value: 'Quicksand_400Regular' },
        ],
        fontWeight: { minWidth: Breakpoints.Mobile, value: '700' },
        textAlign: 'center',
        textTransform: { minWidth: Breakpoints.Laptop, value: 'uppercase' },
      },
      props: {},
    },
    'Strong Button': {
      style: {
        backgroundColor: theme.colors.text.strong,
        borderRadius: 8,
        fontFamily: 'Quicksand_600SemiBold',
        textAlign: 'center',
      },
      props: {},
    },
  });

export const SliderStyles = theme =>
  StyleSheet.create({
    Slider: { style: { marginLeft: 12, marginRight: 12 }, props: {} },
  });

export const AudioPlayerStyles = theme =>
  StyleSheet.create({
    'Audio Player': {
      style: {
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        flexDirection: 'row',
        padding: 8,
      },
      props: {},
    },
  });

export const AccordionGroupStyles = theme =>
  StyleSheet.create({
    Accordion: {
      style: {
        fontSize: 16,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const LinearGradientStyles = theme =>
  StyleSheet.create({
    Background: {
      style: { flex: 1 },
      props: {
        color1: theme.colors.branding.primary,
        color2: palettes.App['Dark Blue'],
        color3: palettes.App['Dark Cobalt'],
      },
    },
    'Linear Gradient': {
      style: {
        alignContent: 'flex-start',
        borderColor: theme.colors.text.light,
        borderRadius: 10,
        borderWidth: 1,
        flex: 1,
        margin: 5,
        opacity: 1,
      },
      props: { color1: palettes.Brand.Surface, color3: palettes.Brand.Surface },
    },
    SectionName: {
      style: { borderRadius: 0, borderWidth: 0, padding: 10 },
      props: {
        color1: theme.colors.text.strong,
        color2: theme.colors.branding.primary,
        color3: null,
      },
    },
  });

export const ShadowStyles = theme =>
  StyleSheet.create({
    bot_nav: {
      style: {
        left: { minWidth: Breakpoints.Laptop, value: 0 },
        position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
        right: { minWidth: Breakpoints.Laptop, value: 0 },
        width: '100%',
        zIndex: { minWidth: Breakpoints.Desktop, value: 1000 },
      },
      props: { paintInside: false },
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Common TextArea': {
      style: {
        backgroundColor: [
          { minWidth: Breakpoints.Laptop, value: palettes.App.White },
          { minWidth: Breakpoints.Mobile, value: palettes.App.Peoplebit_White },
        ],
        borderBottomWidth: 1,
        borderColor: [
          {
            minWidth: Breakpoints.Mobile,
            value: palettes.App['Custom Color 3'],
          },
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color 3'],
          },
        ],
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 15 },
        ],
        height: 150,
        paddingBottom: 8,
        paddingLeft: [
          { minWidth: Breakpoints.Mobile, value: 8 },
          { minWidth: Breakpoints.Laptop, value: 16 },
        ],
        paddingRight: 8,
        paddingTop: [
          { minWidth: Breakpoints.Mobile, value: 8 },
          { minWidth: Breakpoints.Laptop, value: 12 },
        ],
      },
      props: { placeholderTextColor: theme.colors.text.light },
    },
    'Common TextInput': {
      style: {
        backgroundColor: [
          { minWidth: Breakpoints.Mobile, value: palettes.App.Peoplebit_White },
          { minWidth: Breakpoints.Tablet, value: palettes.App.Peoplebit_White },
        ],
        borderColor: palettes.App['Custom Color 3'],
        borderRadius: 8,
        borderWidth: 1,
        color: theme.colors.text.strong,
        flex: 1,
        fontFamily: 'Quicksand_500Medium',
        fontSize: 16,
        height: [
          { minWidth: Breakpoints.Laptop, value: 60 },
          { minWidth: Breakpoints.Mobile, value: 60 },
        ],
        maxHeight: 60,
        minHeight: [
          { minWidth: Breakpoints.Laptop, value: 60 },
          { minWidth: Breakpoints.Mobile, value: 60 },
        ],
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 8,
        paddingTop: 8,
        width: '100%',
      },
      props: {
        placeholderTextColor: theme.colors.foreground.brand,
        selectionColor: 'rgba(0, 0, 0, 0)',
      },
    },
  });

export const DatePickerStyles = theme =>
  StyleSheet.create({
    defaultDatePicker: {
      style: {
        backgroundColor: palettes.App.Peoplebit_White,
        borderColor: palettes.App['Custom Color 3'],
        borderRadius: 8,
        borderWidth: 1,
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: 16,
        height: 60,
        paddingBottom: 4,
        paddingTop: 20,
      },
      props: {
        borderColorActive: theme.colors.text.medium,
        hideLabel: null,
        labelColor: palettes.Brand.Light,
      },
    },
  });

export const MultiSelectPickerStyles = theme =>
  StyleSheet.create({
    'Default Multi Picker': {
      style: {
        backgroundColor: palettes.App.Peoplebit_White,
        borderColor: palettes.App['Custom Color 3'],
        borderRadius: 8,
        borderWidth: 1,
        color: theme.colors.text.strong,
        fontFamily: 'Quicksand_500Medium',
        fontSize: 16,
        height: 60,
      },
      props: { placeholderTextColor: theme.colors.text.light },
    },
  });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.primary,
        justifyContent: 'center',
      },
      props: {},
    },
  });
