const getColorForStatus = val => {
  if (val === 'Published') {
    return '#15803DFF';
  } else if (val === 'Staging') {
    return '#CA8A04FF';
  } else {
    return '#262B2FFF';
  }
};

export default getColorForStatus;
