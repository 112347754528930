import { Platform } from 'react-native';
import { osVersion } from 'expo-device';
import * as qs from 'qs';

/*
 * IOS 17 introduces new automatic encoding rules that leads to double encoding
 * when encoding is done on the JS level, this skips encoding for devices with
 * IOS 17 or higher.
 * https://forums.developer.apple.com/forums/thread/738432
 * https://github.com/axios/axios/issues/6102
 * https://github.com/facebook/react-native/issues/39793
 */

let isIos17OrNewer = false;
if (Platform.OS === 'ios') {
  const splitVersion = osVersion.split('.');
  if (splitVersion.length > 0) {
    const majorVersion = Number(splitVersion[0]);
    isIos17OrNewer = typeof majorVersion === 'number' && majorVersion >= 17;
  }
}

const isUrlEncoded = s => {
  if (typeof s !== 'string' || !s.match(/%[0-9A-F][0-9A-F]/)) {
    return false;
  }
  try {
    const decoded = decodeURIComponent(s);
    return decoded !== s;
  } catch (_e) {
    return false;
  }
};

export const encodeQueryParam = param => {
  if (isIos17OrNewer || isUrlEncoded(param)) {
    return param;
  } else {
    return encodeURIComponent(param);
  }
};

export const renderParam = value =>
  typeof value === 'string' || Array.isArray(value)
    ? value
    : JSON.stringify(value);


export const renderQueryString = (paramsDict) => {
  console.log("renderQueryString : ", paramsDict);
  const queries = Object.entries(paramsDict)
    .filter(([_, value]) => value !== undefined)
    .flatMap(([key, value]) => {
      const encodedKey = encodeURIComponent(key);

      if (value === '[]') {
        return `${encodedKey}=`;
      }

      if (Array.isArray(value)) {
        return value.map((item, index) => {
          if (typeof item === 'object') {
            return Object.entries(item).map(([subKey, subValue]) => 
              `${encodedKey}[${index}][${encodeURIComponent(subKey)}]=${encodeURIComponent(subValue)}`
            ).join('&');
          }
          return `${encodedKey}[]=${encodeURIComponent(item)}`;
        });
      }

      if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        try {
          const arr = JSON.parse(value);
          if (Array.isArray(arr)) {
            return arr.map((item, index) => {
              if (typeof item === 'object') {
                return Object.entries(item).map(([subKey, subValue]) => 
                  `${encodedKey}[${index}][${encodeURIComponent(subKey)}]=${encodeURIComponent(subValue)}`
                ).join('&');
              }
              return `${encodedKey}[]=${encodeURIComponent(item)}`;
            });
          }
        } catch (e) {
          // If parsing fails, treat it as a regular string
        }
      }

      return `${encodedKey}=${encodeURIComponent(value)}`;
    });

  return queries.length ? `?${queries.join('&')}` : '';
};

export default encodeQueryParam;
