import React from 'react';
import { Button, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import columnOnlySmallDevice from '../global-functions/columnOnlySmallDevice';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { editingLabel: null, onPressBack: () => {} };

const CurrentEditingSectionBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const customBack = () => {
    if (props.onPressBack) {
      props.onPressBack?.();
    } else {
      navigation.goBack();
    }
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
          paddingLeft: [
            { minWidth: Breakpoints.Mobile, value: 15 },
            { minWidth: Breakpoints.Laptop, value: 25 },
          ],
          paddingRight: 15,
          paddingTop: 10,
        },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              {
                minWidth: Breakpoints.Mobile,
                value:
                  dimensions.width >= Breakpoints.Laptop
                    ? 'center'
                    : 'flex-start',
              },
            ],
            flexDirection: [
              { minWidth: Breakpoints.Mobile, value: 'row' },
              {
                minWidth: Breakpoints.Mobile,
                value: columnOnlySmallDevice(dimensions),
              },
            ],
            gap: 10,
            maxWidth: 1200,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              customBack();
              if (true) {
                return;
              }
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Strong Button'].props}
          disabledOpacity={0.5}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Strong Button'].style,
              { height: 40, maxHeight: 40, minHeight: 40, width: 80 }
            ),
            dimensions.width
          )}
          title={'BACK'}
        />
        <>
          {!(props.editingLabel ?? defaultProps.editingLabel) ? null : (
            <View>
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Table Row'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Table Row'].style,
                    { fontFamily: 'Quicksand_700Bold', fontSize: 18 }
                  ),
                  dimensions.width
                )}
              >
                {'Currently editing: '}
                {props.editingLabel ?? defaultProps.editingLabel}
                {'\n'}
              </Text>
            </View>
          )}
        </>
      </View>
    </View>
  );
};

export default withTheme(CurrentEditingSectionBlock);
