import React from 'react';
import {
  Button,
  KeyboardAvoidingView,
  ScreenContainer,
  Surface,
  TextInput,
  VStack,
  withTheme,
} from '@draftbit/ui';
import { Image, Keyboard, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AdminGroupApi from '../apis/AdminGroupApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { email: null, message: null };

const LogInScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const loginFormValidator = () => {
    var emailPattern = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;
    // var passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/;

    if (!email.match(emailPattern)) {
      setErrorMessage('*Invalid email address!');
      return false;
    }

    // if (!passwordVarl.match(passwordPattern)) {
    //     setErrorMessage('*Password must be at least 6 characters, and contain at least one lowercase letter, one uppercase letter, and one digit.');
    //     return false;
    // }

    // console.log('Inputs are valid!');  // All checks passed
    // setErrorMessage('');  // Clear any previous error message
    return true;
  };
  const passwordhUXyGfAORef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={true}
      hasRightSafeArea={true}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: '"rgb(0, 0, 0)"', justifyContent: 'center' },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            maxWidth: { minWidth: Breakpoints.Tablet, value: 380 },
            width: [
              { minWidth: Breakpoints.Laptop, value: '50%' },
              { minWidth: Breakpoints.Tablet, value: '100%' },
            ],
          },
          dimensions.width
        )}
      >
        <VStack
          {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.VStackStyles(theme)['V Stack'].style,
              { gap: 5 }
            ),
            dimensions.width
          )}
        >
          {/* NKP Logo */}
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['mainsightslogonew'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { width: 300 }
              ),
              dimensions.width
            )}
          />
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['screen_title'].props}
            disabled={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['screen_title'].style,
                {
                  color: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: palettes.App.Orange,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.Orange,
                    },
                  ],
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Poppins_900Black',
                    },
                    { minWidth: Breakpoints.Mobile, value: 'Poppins_900Black' },
                  ],
                  fontSize: 30,
                  lineHeight: 30,
                  paddingBottom: { minWidth: Breakpoints.Desktop, value: 0 },
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
            suppressHighlighting={true}
          >
            {dimensions.width >= Breakpoints.Laptop
              ? 'M&A INSIGHTS'
              : 'M&A\nINSIGHTS'}
          </Text>
        </VStack>
      </View>

      <KeyboardAvoidingView
        keyboardVerticalOffset={0}
        behavior={'position'}
        enabled={true}
      >
        {/* Login Window */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              width: [
                { minWidth: Breakpoints.Laptop, value: '50%' },
                { minWidth: Breakpoints.Tablet, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={2}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  margin: { minWidth: Breakpoints.Laptop, value: null },
                  maxWidth: { minWidth: Breakpoints.Tablet, value: 380 },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }
              ),
              dimensions.width
            )}
          >
            <View>
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['screen_title'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['screen_title'].style,
                    {
                      alignSelf: 'center',
                      fontFamily: 'Quicksand_700Bold',
                      fontSize: 25,
                      margin: 10,
                      padding: 10,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Login'}
              </Text>
              {/* Email */}
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={500}
                onChangeText={newEmailValue => {
                  try {
                    setEmail(newEmailValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onSubmitEditing={() => {
                  try {
                    passwordhUXyGfAORef.current.focus();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoComplete={'email'}
                autoCorrect={false}
                autoFocus={false}
                clearButtonMode={'while-editing'}
                keyboardType={'email-address'}
                numberOfLines={1}
                placeholder={'Enter email...'}
                placeholderTextColor={theme.colors.text.medium}
                returnKeyType={'next'}
                selectionColor={theme.colors.text.strong}
                spellcheck={true}
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors.text.strong,
                    borderStyle: 'solid',
                    fontFamily: 'Quicksand_400Regular',
                    margin: 10,
                    padding: 10,
                  },
                  dimensions.width
                )}
                value={email}
              />
              {/* Password */}
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={500}
                onBlur={() => {
                  try {
                    /* hidden 'Dismiss Keyboard' action */
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeText={newPasswordValue => {
                  try {
                    setPassword(newPasswordValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onSubmitEditing={() => {
                  const handler = async () => {
                    try {
                      Keyboard.dismiss();
                      if (email === '' || password === '') {
                        return;
                      }
                      if (!loginFormValidator()) {
                        return;
                      }
                      setIsLoading(true);
                      const Xano_Auth = (
                        await AdminGroupApi.loginAdminPOST(Constants, {
                          email: email,
                          password: password,
                        })
                      )?.json;
                      setIsLoading(false);
                      const savedToken = Xano_Auth?.authToken;
                      const message = Xano_Auth?.message;
                      setErrorMessage(message);
                      if (!savedToken) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'AUTH_HEADER',
                        value: 'Bearer ' + savedToken,
                      });
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('POTDNavigator');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                webShowOutline={true}
                autoComplete={'current-password'}
                autoCorrect={false}
                enablesReturnKeyAutomatically={true}
                placeholder={'Enter password...'}
                placeholderTextColor={theme.colors.text.medium}
                ref={passwordhUXyGfAORef}
                returnKeyLabel={'Login'}
                returnKeyType={'go'}
                secureTextEntry={true}
                spellcheck={true}
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors.text.strong,
                    fontFamily: 'Quicksand_400Regular',
                    margin: 10,
                    padding: 10,
                  },
                  dimensions.width
                )}
                value={password}
              />
              {/* err message */}
              <>
                {!errorMessage ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['screen_title'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['screen_title'].style,
                        {
                          color: theme.colors.background.danger,
                          fontFamily: 'Quicksand_700Bold',
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                )}
              </>
              {/* Login */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (!loginFormValidator()) {
                        setErrorMessage('Please input fields');
                      }
                      if (!loginFormValidator()) {
                        return;
                      }
                      setIsLoading(true);
                      const Xano_Auth = (
                        await AdminGroupApi.loginAdminPOST(Constants, {
                          email: email,
                          password: password,
                        })
                      )?.json;
                      console.log(Xano_Auth);
                      setIsLoading(false);
                      const savedToken = Xano_Auth?.authToken;
                      const message = Xano_Auth?.message;
                      setErrorMessage(message);
                      if (!savedToken) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'AUTH_HEADER',
                        value: 'Bearer ' + savedToken,
                      });
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('EventNavigator', {
                        screen: 'EventListingScreen',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                disabled={email === '' || password === ''}
                disabledOpacity={0.5}
                loading={isLoading}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      fontFamily: 'Quicksand_600SemiBold',
                      marginBottom: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 10,
                    }
                  ),
                  dimensions.width
                )}
                title={'Log In'}
              />
            </View>
          </Surface>
        </View>
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

export default withTheme(LogInScreen);
